import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import Button from '../../../../partials/Button/Button';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Planning.module.scss';
import StepModale from './StepModale/StepModale';
import TimeLine from '../../../../partials/Chart/TimeLine/TimeLine';

export default function Planning({ study , id , setActiveSection , isPreview }) {

  const contentRef = useRef(null);
  const context = useGlobalContext()[0];
  const planningOptions = context.studies.optionsLists?.planning


  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ editStep , setEditStep ] = useState();

  useEffect(() => {
    if (!modaleIsActive) setEditStep();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editStep) setModaleIsActive(true);
  }, [editStep]);

  return (
    <>
      <section id={id}  onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
            
        <div className="header">
          <div className="title">
            <h3>Planning</h3>
            {study.steps.length > 0 &&
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter une étape
            </Button>
          }
          </div>
        </div>
        {study.steps.length > 0 &&
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.planning}>
              {study?.steps && <TimeLine
                planningOptions={planningOptions}
                onClick={(step) => isPreview ? null : setEditStep(step)}
                steps={study.steps.sort((a , b) => new Date(a.startDate) - new Date(b.startDate))} />}
            </div>
          </div>
        }
      

      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <StepModale
          editStep={editStep}
          studyId={study._id}
          close={() => setModaleIsActive(false)}
          steps={study.steps ? study.steps : []}
          />}
      </Modale>
    </>
  )
}
