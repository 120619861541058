import React , { useEffect , useCallback , useState } from 'react';
import { useForm } from "react-hook-form";

import { useGlobalContext } from '../../../../../../context/GlobalContext';
import { postFileAction , deleteFileAction , putStudyAction , getFileAction } from '../../../../../../actions/studiesActions';

import InputFile from '../../../../../partials/Form/InputFile/InputFile';

import styles from './Card.module.scss';

export default function Card({ type , study }) {

  const { errors } = useForm();

  const [ context,  dispatch ] = useGlobalContext();
  const editDoctor = study?.investigators?.find( d => d.investigator._id === context.auth?.userAuth?._id );
  const doctors = study?.investigators

  const { file , error } = context.studies;

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch]);

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        dispatch({
          type : 'ERROR_STUDIES',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  useEffect(()=> {
    if (!file?._id) return null
    if (uploadedFile) {
      putDoctor(uploadedFile , file._id )
      document.getElementById(uploadedFile).value = "";
    }
    setUploadedFile();
    setFileError();
    // eslint-disable-next-line
  },[file]);

  console.log(editDoctor , doctors)

  function putDoctor(key , val) {
    let updatedDoctor = Object.assign({} , editDoctor);
    let index = doctors.findIndex(d => d?.investigator._id === editDoctor?.investigator._id)
    let doctorsList = doctors;
    updatedDoctor[key] = val
    doctorsList[index] = updatedDoctor
    putStudyAction(dispatch , study._id , { investigators: doctorsList } );
  }

  function handleChangeFile(type , fileData ) {
    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });

    if(editDoctor[type]) deleteFileAction(dispatch , editDoctor[type])

    setUploadedFile(type);
    setFileError();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `${type} ${editDoctor.investigator.profile.lastName}`);
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }

  return (
    <>
      {type === "cv" &&
        <div className={styles.card}>
          <label className="bold">Mon CV</label>
          {editDoctor?.cv &&
            <>
              <button
                type={"button"}
                onClick={() => getFileAction(dispatch , editDoctor.cv)} 
                className={styles["btn"]}
                >
                <p>Voir le fichier</p>
              </button>
            </>
          }
          <InputFile
            name="cv"
            label=""
            type={"doctor-card"}
            isLoading={uploadedFile === "cv"}
            onChange={(files) => handleChangeFile("cv" , files[0])}
            withErrors={false}
            defaultValue={null}
            error={fileError}
            />
        </div>
      }
      {editDoctor?.bpc && type === "bpc" &&
        <div className={styles.card}>
          <label className="bold">Formation BPC</label>
          <button
            type={"button"}
            onClick={() => getFileAction(dispatch , editDoctor.bpc)} 
            className={styles["btn"]}
            >
            <p>Voir le fichier</p>
          </button>
        </div>
      }
    </>
  )
}
