import {
  ERROR_STUDIES,
  MESSAGE_STUDIES,
  GET_DRUGS_LIST,
} from "../actions/types";

const initialState = {
  drugsList: [],
  error: null,
  message: null,
};

export default function drugsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DRUGS_LIST:
      return {...state , drugsList: action.payload}

    case ERROR_STUDIES:
      return {...state , error: action.payload}

    case MESSAGE_STUDIES:
      return {...state , message: action.payload}
    default:
      return {...state};
  }
}