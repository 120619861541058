import React , { useEffect , useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiLogOut , FiUser } from "react-icons/fi";

import { useGlobalContext } from "../../../context/GlobalContext";
import { getUserAuthAction } from "../../../actions/authActions";

import logo from '../../../assets/images/logo.svg'

import './nav.scss';

export default function Nav({ activePage , logout }) {

  const [ context , dispatch ] = useGlobalContext();

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if (context.auth.userAuth && activePage === "login") return null;
    return getUser();
    // eslint-disable-next-line
  }, [activePage])

  return (
    <nav className={`${activePage === "login" ? "" : "isActive"}`}>
      <div className="content">

        <Link to="/study">
          <div className="logo">
            <img src={logo} alt=""/>
          </div>
        </Link>

        <div className="links">
          <Link to="/study" className={`${activePage === "studies" || activePage === "study" ? "isActive" : ""}`}>LISTE DES ÉTUDES</Link>
          <Link to="/status" className={`${activePage === "status" ? "isActive" : ""}`}>SUIVI DE STATUT</Link>
          <Link to="/centers" className={`${activePage === "centers" ? "isActive" : ""}`}>CENTRES</Link>
        </div>
        
        <div className="icons">

          <Link to="/account">
            <FiUser size={22} color={"#192243"}/>
          </Link>

          <button className="logout" onClick={() => logout()}>
            <FiLogOut size={22} color={"#192243"}/>
          </button>

        </div>

      </div>

    </nav>
  )
}
