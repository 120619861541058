import { postData , getData , putData , deleteData } from './index';

import {
  ERROR_CENTERS,
  PUT_CENTER,
  GET_CENTER, 
  POST_CENTER,
  DELETE_CENTER,
  GET_CENTERS_LIST,
} from './types';


export async function getCentersListAction(dispatch) {
  let url = "/center";
  var list;

  await getData(ERROR_CENTERS, url, dispatch , true ).then((response)=>{
    list = response.centers
  });

  dispatch({
    type : GET_CENTERS_LIST,
    payload : list
  });

};

export async function getCenterAction(dispatch , id ) {
  let url = `/center/${id}`;
  var study;

  await getData(ERROR_CENTERS, url, dispatch , true ).then((response)=>{
    study = response.center
  });

  dispatch({
    type : GET_CENTER,
    payload : study
  });

};

export async function postCenterAction(dispatch , obj){
  let url = "/center";
  var centerCreated;

  await postData(POST_CENTER , ERROR_CENTERS , url, dispatch , obj, true ).then((response)=>{
    centerCreated = response
  });

  dispatch({
    type:'SET_TOAST',
    payload: {
      message: "Le centre a été crée",
      type: "success"
    }
  });

  dispatch({
    type : POST_CENTER,
    payload : centerCreated
  });
};

export async function putCenterAction(dispatch , id , obj){
  let url = `/center/${id}`;
  var centerUpdated;

  await putData(PUT_CENTER , ERROR_CENTERS , url, dispatch , obj, true ).then((response)=>{
    centerUpdated = response
  });

  dispatch({
    type : PUT_CENTER,
    payload : centerUpdated
  });

  dispatch({
    type:'SET_TOAST',
    payload: {
      message: "Le centre a été mise à jour",
      type: "success"
    }
  });

};

export async function deleteCenterAction(dispatch , id ){
  let url = `/center/${id}`,
      deleteCenter;

  await deleteData(ERROR_CENTERS, url, dispatch  ).then((response)=>{
    deleteCenter = response.message
  });

  dispatch({
    type : DELETE_CENTER,
    payload : {
      message: deleteCenter,
      _id: id
    }
  });

  dispatch({
    type:'SET_TOAST',
    payload: {
      message: "Le centre a été supprimé",
      type: "success"
    }
  });

};