import {
  ERROR_STUDIES,
  MESSAGE_STUDIES,
  GET_OPTIONS_LIST,
  POST_STUDY ,
  GET_STUDIES_LIST,
  GET_STUDY,
  PUT_STUDY,
  POST_FILE,
  ERROR_UPLOAD,
  DELETE_FILE,
  FILTERED_STUDIES,
  DELETE_STUDY
} from "../actions/types";

const initialState = {
  studiesList: [],
  filteredStudies: [],
  optionsLists: null,
  error: null,
  message: null,
  study: null,
  file: null,
};

export default function studiesReducer(state = initialState, action) {
  var list = state.studiesList;
  switch (action.type) {
    case DELETE_STUDY :
      return {...state ,
        message: action.payload.message,
        studiesList: list.filter(s => s._id !== action.payload._id)
      }

    case PUT_STUDY: 
      list = list.filter(study => study._id !== action.payload.study._id );
      list.unshift(action.payload.study);
      return {...state , studiesList: list , study: action.payload.study , message: action.payload.message }

    case POST_FILE:
      return {...state , file: action.payload }
    
    case DELETE_FILE:
      return {...state , file: action.payload.file , message: action.payload.message }

    case POST_STUDY:
      list = [action.payload.study, ...list];
      return {...state , studiesList: list , study: action.payload.study, message: action.payload.message }

    case GET_STUDY:
      return {...state , study: action.payload}
      
    case GET_STUDIES_LIST:
      return {...state , studiesList: action.payload }
      
    case FILTERED_STUDIES:
      return {...state , filteredStudies: action.payload }

    case GET_OPTIONS_LIST:
      return {...state , optionsLists: action.payload}
    
    case ERROR_UPLOAD:
      return {...state , error: action.payload}

    case ERROR_STUDIES:
      return {...state , error: action.payload}

    case MESSAGE_STUDIES:
      return {...state , message: action.payload}
    default:
      return {...state};
  }
}