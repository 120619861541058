import React , { useEffect , useState , useCallback } from 'react';

import { useGlobalContext } from '../../../context/GlobalContext';
import useDimensions from 'react-use-dimensions';

import martiniqueMap from './Map/geojson/communes-martinique.json';
import guyaneMap from './Map/geojson/communes-guyane.json';
import guadeloupeMap from './Map/geojson/communes-guadeloupe.json';
import franceMap from './Map/geojson/france.json';

import iconFrance from '../../../assets/images/france.svg';
import iconMartinique from '../../../assets/images/martinique.svg';
import iconGuyane from '../../../assets/images/guyane.svg';
import iconGuadeloupe from '../../../assets/images/guadeloupe.svg';
import { hexToRgba } from '../../../utils/utils';

import { getOptionsListsAction , getStudiesListsAction } from '../../../actions/studiesActions';

import Map from './Map/Map';
import Panel from '../../partials/Panel/Panel';
import StudyView from '../../pages/studies/partials/StudyView/StudyView';

import styles from './Centers.module.scss';
import { getCentersListAction } from '../../../actions/centersActions';
import CentersList from './CentersList/CentersList';
import CenterPanel from './CenterPanel/CenterPanel';
import Button from '../../partials/Button/Button';

export default function CentersPage({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const { isDoctor } = context.auth;
  const { centersList , filteredCenters , center } = context.centers;
  const { filteredStudies , study } = context.studies;
  const phasesOctapharma  = context.studies.optionsLists?.phasesOctapharma ;

  const mapOptions = [
    { 
      label: "France",
      geojson: franceMap,
      icon: iconFrance,
      scale: 2300
    },
    { 
      label: "Martinique",
      geojson: martiniqueMap,
      icon: iconMartinique,
      scale: 55000
    },
    { 
      label: "Guyane",
      geojson: guyaneMap,
      icon: iconGuyane,
      scale: 7300
    },
    { 
      label: "Guadeloupe",
      geojson: guadeloupeMap,
      icon: iconGuadeloupe,
      scale: 45300
    },
  ];

  const [ geographyRef, geographySize ] = useDimensions();
  const [ selectedMap , setSelectedMap ] = useState(mapOptions[0]);
  const [ panelIsOpen , setPanelIsOpen ] = useState(false);
  const [ displayLegend , setDisplayLegend ] = useState(false);
  const [ mapData , setMapData ] = useState();
  const isList = false;

  const initData = useCallback(() => {
    setActivePage('centers');
    getCentersListAction(dispatch);
    getStudiesListsAction(dispatch);
    getOptionsListsAction(dispatch);
    dispatch({
      type : 'GET_STUDY',
      payload : null
    });
  }, [setActivePage ,  dispatch ]);

  useEffect(() => {
    initData();
    return () => setMapData();
  }, [initData]);

  useEffect(() => {
    if (study) {
      return setPanelIsOpen(true);
    } else if (center) {
      return setPanelIsOpen(true);
    }
  },[study , center ]);

  useEffect(() => {
    if (!study && centersList.length > 0 && filteredStudies.length && phasesOctapharma) {
      setMapData();
      setDisplayLegend(false);
      let data = [];
      const studies = [...filteredStudies]
      centersList.forEach((c) => {
        const center = { ...c }
        let centerStudies = [];
        studies.forEach(s => {
          let phase = s.centers.find(c => !c.service && c.center && center._id && c.center._id === center._id )?.phase;
          phase = phasesOctapharma.find(p => p.value === phase)
          if (phase && s._id ) {
            centerStudies.push({ id: s._id , study: s , name: s.name , type: 'study', size: 6, color: phase.color , phase: phase.label })
          }
        });

        let servicesList = [];
        let serviceData = null;

        center?.services.forEach(service => {
          let serviceStudies = [];
          studies.forEach(s => {
            let serviceFound = s.centers.find(c => c.service === service._id );
            let phase = phasesOctapharma.find(p => p.value === serviceFound?.phase);
            if (serviceFound && phase && s._id  && !serviceStudies.find(study => study.id === s._id)) {
              serviceStudies.push({ id: s._id , study: s , name: s.name , type: 'study', size: 6, color: phase.color , phase: phase.label });
            }
          });
          serviceData = {
            id : service._id,
            size: 6,
            color: '#A490D7',
            name: service.name,
            studies: serviceStudies,
            type: 'service',
          };
          if (serviceStudies.length > 0) {
            servicesList.push(serviceData);
          }
        });

        if(center.location.city && center.location.city.toLowerCase().startsWith('paris')){
          center.location.city = 'Paris'
        }
        if(center.location.city && center.location.city.toLowerCase().startsWith('lyon')){
            center.location.city = 'Lyon'
        }
        if(center.location.city && center.location.city.toLowerCase().startsWith('marseille')){
            center.location.city = 'Marseille'
        }
        if(center.location.city && center.location.city.toLowerCase().startsWith('toulouse')){
            center.location.city = 'Toulouse'
        }

        let centerData = {
          id : center._id,
          lat: center.location.lat,
          lon: center.location.lon,
          city: center.location.city,
          name: center.name,
          size: 6,
          color: '#4F23B1',
          services: servicesList,
          studies: centerStudies,
          type: 'center',
        }

        if (filteredCenters.find(c => c === centerData.id ) || !filteredCenters.length) {
          if (centerData.lat && centerData.lon && centerData.studies.length > 0 && !data.find((d) => d.id === centerData.id )) {
            data.push(centerData);
          }
        }

      });
      setTimeout(() => setMapData(data), 20);
    }
    // eslint-disable-next-line
  },[centersList , filteredCenters , selectedMap , isList, filteredStudies, phasesOctapharma]);

  useEffect(() => {
    closePanel();
    setDisplayLegend(false);
    // eslint-disable-next-line
  },[isList])

  function closePanel() {
    setPanelIsOpen(false)
    dispatch({
      type : 'GET_CENTER',
      payload : null
    });
    dispatch({
      type : 'GET_STUDY',
      payload : null
    });
  }

  function renderMap() {
    return (
      <>
        {mapData && selectedMap && selectedMap.geojson &&
          <div ref={geographyRef} className={`${styles.map} ${study ? styles['panel-open']: ""}`} > 
            {geographySize.width && <Map
                data={mapData}
                closePanel={closePanel}
                setDisplayLegend={setDisplayLegend}
                mapScale={selectedMap.scale}
                mapKey={selectedMap.label}
                geojson={selectedMap.geojson}
                mapWidth={geographySize.width}
                mapHeight={geographySize.height} />}
          </div>
        }
        <div className={`${styles.legend}`}>
          <h4 className="text-grey">Légende</h4>
          {displayLegend
            ? <>
              <div className={styles.phase} >
                <div className={styles.puce} style={{ backgroundColor: hexToRgba('#4F21B1' , 0.5 ) }}></div>
                <p>Service</p>
              </div>
              {phasesOctapharma?.map((p , i) => {
                return (
                  <div key={'legend' + i} className={styles.phase} >
                    <div className={styles.puce} style={{ backgroundColor: p.color }}></div>
                    <p>{p.label}</p>
                  </div>
                )
              })}
            </>
            : <>
              <div className={styles.phase} >
                <div className={styles.puce} style={{ backgroundColor: '#4F21B1' }}></div>
                <p>Centre</p>
              </div>
              <div className={styles.phase} >
                <div className={styles.puce} style={{ backgroundColor: '#192243' }}></div>
                <p>Ville</p>
              </div>
            </>
          }
        </div>
        <div className={styles["select-map"]}>
          <div className={styles["btn-container"]}>
            {mapOptions.map((map , i ) => {
              return (
                <button
                  key={'map' + i}
                  className={selectedMap.label !== map.label ? styles['not-active'] : ""}
                  onClick={() => setSelectedMap(map)}>
                  <img src={map.icon} className={styles[map.label]} alt={map.label}/>
                  <p>{map.label}</p>
                </button>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={`page-container ${styles.container}`}>
      {!isDoctor &&
        <header>
          {/* <div className={styles['btn-container']}> 
            <button
              className={`${!isList ? styles['is-active'] : ''}`}
              onClick={() => setIsList(false)}
              >Géographie
            </button>
            <button
              className={`${isList ? styles['is-active'] : ''}`}
              onClick={() => setIsList(true)}
              >Liste
            </button>
          </div> */}
          {isList
            && <Button
              className={styles.create}
              onClick={() => 
                dispatch({
                  type : 'GET_CENTER',
                  payload : {}
                })
              }
              primary
              >
              Nouveau centre
            </Button>
          }
        </header>
      }
      {isList
        ? <CentersList
            centers={centersList.filter(c => {
            if (filteredCenters.length) {
              if(filteredCenters.find(id => id === c._id )) return c;
              else return null;  
            }
            return c;
            })}
          />
        : renderMap()
      }
      <Panel
        isOpen={panelIsOpen}
        setIsOpen={closePanel}
        closeClassName={styles['close-panel-button']}
        >
        {study && <StudyView study={study}/>}
        {center && <CenterPanel />}
      </Panel>
    </div>
  )
}
