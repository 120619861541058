import React, { useState , useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';

export default function LineChart({ data }) {
	const [localData, setLocalData] = useState([])
	const [ count , setCount ] = useState(1)
	useEffect(() => {
		setCount(c => c + 1);
		setLocalData(data.map(d => ({ ...d , id: d.id + count , label : d.id })))
		// setLocalData(data)
		// eslint-disable-next-line
	}, [data])

	return (
    <ResponsiveLine
			data={localData}
			animate={false}
			margin={{ top: 20, right: 55, bottom: 40, left: 55 }}
			xScale={{ type: 'point' }}
			yScale={{ type: 'linear', min: 0 , max: 'auto', stacked: false, reverse: false }}
			axisTop={null}
			axisRight={null}
			tooltip={({ point }) => (
				<p style={{ backgroundColor: '#FFFFFF' , transform: "translateY(20px)" , padding: "3px 10px" , borderRadius: 3 , boxShadow: "0px 10px 10px rgba(0, 0, 0 , 0.2)"}}>
					<span style={{ color: point.serieColor }}>{point.data.y} Patients</span>
				</p>
			)}
			axisBottom={{
				orient: 'bottom',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: -50,
			}}
			axisLeft={{
				orient: 'left',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			enableGridX={false}
			colors={{ datum: 'color' }}
			enablePoints={false}
			pointSize={10}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={2}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={0}
			enableArea={true}
			areaBaselineValue={0}
			areaOpacity={0.3}
			useMesh={true}
			legends={[
				{	
					data: localData.map((d , i) => {
						return {
							id: d.id,
							label: d.label,
							color: d.color
						}
					}).reverse(),
					anchor: 'top-left',
					direction: 'row',
					justify: false,
					translateX: 1,
					translateY: -20,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 150,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1
							}
						}
					]
				}
			]}
    />
  )
}
