import React , { useEffect , useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useGlobalContext } from '../../../context/GlobalContext';
import { getUserAuthAction } from '../../../actions/authActions';
import { TOOL_ID } from '../../../actions';

export default function SwitchPage() {

  const [ context , dispatch ] = useGlobalContext();

  const history = useHistory();

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getUser();
    return () => {
      dispatch({
        type: 'ERROR_AUTH',
        payload: null,
      });
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if(context.auth.userAuth) {
      if (context.auth.userAuth.tools.find(item => item.tool=== TOOL_ID)) {
        history.push("/study")
      } else {
        history.push("/login")
      }
    }
  // eslint-disable-next-line 
  }, [context.auth.userAuth]);

  return (
    <div className="page-container">
    </div>
  )
}