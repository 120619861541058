import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";

import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './TherapeuticAreaModale.module.scss';

export default function TherapeuticAreaModale({ therapeuticAreaOptions , therapeuticArea , submit }) {

  const { handleSubmit, register, errors , setValue , control } = useForm();

  useEffect(() => {
    register("name");
  }, [register]);

  function addTherapeuticArea(values) {
    submit([...therapeuticArea.map(d => d._id) , values.name ])
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>Ajouter une aire thérapeutique</h2>
        <form onSubmit={handleSubmit(addTherapeuticArea)}>
          <Controller
            name="name"
            rules={{ required: "l'aire thérapeutique est requis" }}
            control={control}
            render={() =>
              <CustomSelect
                label={"Aire thérapeutique"}
                defaultValue={null}
                handleChange={(opt) => setValue('name' , opt.value)}
                options={therapeuticAreaOptions}
                withErrors={true}
                error={errors.name && errors.name.message}
                />
            }
            defaultValue={null}
          />

          <div className="modale-actions center">
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
          </div>
        </form>
    </div>
  )
}
