import React , { useEffect ,  useState  , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../../../../context/GlobalContext';
import { postFileAction , deleteFileAction } from '../../../../../../actions/studiesActions';

import InputFile from '../../../../../partials/Form/InputFile/InputFile';
import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './DoctorModale.module.scss';

export default function DoctorModale({ doctors , editDoctor , deleteDoctor , doctorsOptions , setModaleIsActive, submitDoctors }) {

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const [ context,  dispatch ] = useGlobalContext();

  const { file , error } = context.studies;

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch]);

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    register("investigator");
  }, [register]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        dispatch({
          type : 'ERROR_STUDIES',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  useEffect(()=> {
    if (!file?._id) return null
    if (uploadedFile) {
      putDoctor(uploadedFile , file._id )
      document.getElementById(uploadedFile).value = "";
    }
    setUploadedFile();
    setFileError();
    // eslint-disable-next-line
  },[file]);

  function addDoctor(values) {
    submitDoctors([...doctors , values])
  }

  function putDoctor(key , val) {
    let updatedDoctor = Object.assign({} , editDoctor);
    let index = doctors.findIndex(d => d._id === editDoctor._id)
    let doctorsList = doctors;
    updatedDoctor[key] = val
    doctorsList[index] = updatedDoctor
    submitDoctors(doctorsList)
  }

  function handleChangeFile(type , fileData ) {
    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });

    if(editDoctor[type]) deleteFileAction(dispatch , editDoctor[type])

    setUploadedFile(type);
    setFileError();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `${type} ${editDoctor.investigator.profile.lastName}`);
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }

  function deleteFile(type , id ) {
    putDoctor(type ,  null );
    deleteFileAction(dispatch , id )
  }

  return (
    <div className={`modale-content ${styles.container}`}>

      {!editDoctor ?
        <>
          <h2>Ajouter un médecin</h2>
          <form onSubmit={handleSubmit(addDoctor)}>
            <Controller
              name="investigator"
              rules={{ required: "le médécin est requis" }}
              control={control}
              render={(props) =>
                <CustomSelect
                  label={"Nom du médecin"}
                  defaultValue={null}
                  handleChange={(opt) => setValue('investigator' , opt.value)}
                  options={doctorsOptions}
                  withErrors={true}
                  error={errors.investigator && errors.investigator.message}
                  />
              }
              defaultValue={null}
            />

            <div className="modale-actions center">
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
            </div>
          </form>
        </>
        :
        <>
          <h2>{editDoctor.investigator.profile.firstName} {editDoctor.investigator.profile.lastName}</h2>

            <div className={styles.row}> 
              <div className={styles['col-2']}>
                {editDoctor.investigator.profile &&
                  <div>
                    <p><span className="text-grey">Nombre de patients : </span>{editDoctor.patientsCount}</p>
                    <p><span className="text-grey">Structure</span><br/>{editDoctor.investigator.profile.structure}</p>
                    <p><span className="text-grey">Loclisation</span><br/>{editDoctor.investigator.profile.city} - {editDoctor.investigator.profile.dept}</p>
                  </div>
                }
              </div>
              
              <div className={styles['col-2']}>

                <div className={styles.info}>
                  {editDoctor.cv ?
                    <>
                      <label>CV</label>
                      <button
                        type={"button"}
                        onClick={() => deleteFile("cv" ,editDoctor.cv)}
                        className={styles["btn-delete"]}
                        >
                        <AiFillDelete size={20} /> <p>Supprimer le fichier</p>
                      </button>
                    </>
                    :
                    <InputFile
                      name="cv"
                      label="cv"
                      isLoading={uploadedFile === "cv"}
                      onChange={(files) =>handleChangeFile("cv" , files[0])}
                      withErrors={true}
                      defaultValue={null}
                      error={fileError}
                      />
                  } 
                </div>

                <div className={styles.info}>
                  {editDoctor.bpc ?
                    <>
                      <label>formation BPC</label>
                      <button
                        type={"button"}
                        onClick={() => deleteFile("bpc" , editDoctor.bpc)}
                        className={styles["btn-delete"]}
                        >
                        <AiFillDelete size={20} /> <p>Supprimer le fichier </p>
                      </button>
                    </>
                    :
                    <InputFile
                      name="bpc"
                      label="formation BPC"
                      isLoading={uploadedFile === "bpc"}
                      onChange={(files) =>handleChangeFile("bpc" , files[0])}
                      withErrors={true}
                      defaultValue={null}
                      error={fileError}
                      />
                  }
                </div>

              </div>
            </div>
          <div>
            <div className="modale-actions center">
              <button
                type={"button"}
                onClick={() => deleteDoctor(editDoctor.investigator._id)}
                className={"btn-delete"}
                >
                <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
              </button>
            </div>
          </div>
        </>
        }
    </div>
  )
}
