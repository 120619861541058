import React , { useEffect , useCallback , useState  } from 'react';
import { Link } from  'react-router-dom';
import { useForm } from "react-hook-form";

import { useGlobalContext } from '../../../context/GlobalContext';
import { putUserAuthAction , getUserAuthAction } from '../../../actions/authActions';

import Input from '../../partials/Form/Input/Input';
import Button from '../../partials/Button/Button';

import illu from '../../../assets/images/account.svg';
import styles from './Account.module.scss';

export default function Account({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const { handleSubmit, register, errors } = useForm();
  const { userAuth , message } = context.auth

  const [ changePassword , setChangePassword ] = useState(false);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    setActivePage("account");
    getUser();
  }, [setActivePage , getUser]);

  useEffect(() => {
    if (message && message === "profile updated") {
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Votre compte a été modifié",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_AUTH',
        payload: null
      });
    }
  }, [message , dispatch])

  const submitProfile = (values) => {
    setChangePassword(false);
    putUserAuthAction(dispatch , values);
  }

  return (
    <div className={`page-container ${styles.container}`}>

      <div className="content" >

        <div className={styles.row}>

          <form className={styles.col} onSubmit={handleSubmit(submitProfile)}>

              <h2 className="bold">MON COMPTE</h2>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Nom"}
                    name={"lastName"}
                    placehloder={"Nom"}
                    defaultValue={userAuth?.lastName}
                    register={register({
                      required: "le nom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.lastName?.message}
                  />
                </div>

                <div className={styles['info-col']}>
                  <Input
                    label={"Prénom"}
                    name={"firstName"}
                    placehloder={"Prénom"}
                    defaultValue={userAuth?.firstName}
                    register={register({
                      required: "le prénom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.firstName?.message}
                  />
                </div>
              
              </div>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Mail"}
                    name={"email"}
                    placehloder={"mail@mail.fr"}
                    defaultValue={userAuth?.email}
                    register={register({
                      required: "le mail est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.email?.message}
                  />
                </div>

                <div className={styles['info-col']}>
      
                </div>

              </div>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  {changePassword ?
                    <Button
                      primary
                      type="button"
                      className={`${styles.btn} ${styles.password}`}
                      onClick={() => setChangePassword(false)}
                      >
                      <p>Conserver le mot de passe</p>
                    </Button>
                    :
                    <Button
                      primary
                      type="button"
                      onClick={() => setChangePassword(true)}
                      className={`${styles.btn} ${styles.password}`}
                      >
                      <p>Changer le mot de passe</p>
                    </Button>
                  }
                </div>

                <div className={styles['info-col']}>
                  {changePassword &&
                    <Input
                      label={"Nouveau mot de passe"}
                      name="password"
                      type="password"
                      placehloder={"Mot de passe"}
                      autoComplete="new-password"
                      register={register({
                        required: "Compléter le mot de passe",
                        pattern: {
                          value: /^(?=.*[0-9])(?=.{6,})/i,
                          message: "Au moins un chiffre et minimum 6 caracteres"
                        }
                      })}
                      withErrors={true}
                      error={errors?.password?.message}
                    />
                  }
                </div>

              </div>

              <div className={styles['btn-container']}>
                <Button
                  primary
                  type="submit"
                  className={`${styles.btn}`}
                  >
                  <p>VALIDER</p>
                </Button>
              </div>
          </form>

          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>

        </div>


      </div>

      <div className={styles.links}>
        <Link to="/legal" className="link">Mentions légales</Link>
        <Link to="/data-policy" className="link">Politique de confidentialités</Link>
        <Link to="/transparency" className="link">Transparence des liens d’intérêts</Link>
      </div>

    </div>
  )
}
