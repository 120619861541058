import React , { useEffect } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useForm  , Controller } from "react-hook-form";
import { useGlobalContext } from '../../../../../../context/GlobalContext';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';
import DatePicker from '../../../../../partials/DatePicker/DatePicker';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import { putStudyAction } from '../../../../../../actions/studiesActions';

import styles from './StepModale.module.scss';

export default function StepModale({ editStep , close, studyId ,  steps }) {

  const [ context , dispatch ] = useGlobalContext();

  const planningOptions = context.studies.optionsLists?.planning

  const { handleSubmit, register, errors , setValue , watch , control } = useForm();

  useEffect(() => {
    register("startDate");
    register("endDate");
    register("name");
  }, [register]);

  function submitStep(values) {
    if (!editStep) { 
      putStudyAction(dispatch , studyId , { steps: [...steps , values]});
    } else {
      let updatedList = steps.filter(d => d._id !== editStep._id);
      let step = {...editStep , ...values}
      putStudyAction(dispatch , studyId , { steps: [...updatedList , step]});
    }

  }

  function deleteStep() {
    putStudyAction(dispatch , studyId , { steps: steps.filter((s) => s._id !== editStep._id)});
    close();
  }

  return (
    <div className={`modale-content ${styles.container}`}>

        <h2>{editStep ? "Modifier une étape" : "Ajouter une étape"}</h2>

        <form onSubmit={handleSubmit(submitStep)}>

           <Controller
              name="name"
              rules={{ required: "Séléctionner un étape" }}
              control={control}
              render={(props) =>
                <CustomSelect
                  label={"Nom de l'étape"}
                  defaultValue={editStep?.name && planningOptions.find(d =>d.value === editStep.name )? planningOptions.find(d =>d.value === editStep.name ) : null }
                  handleChange={(opt) => setValue('name' , opt.value)}
                  options={planningOptions}
                  withErrors={true}
                  error={errors.name && errors.name.message}
                  />
              }
              defaultValue={editStep?.name && planningOptions.find(d =>d.value === editStep.name )? planningOptions.find(d =>d.value === editStep.name ).value : null }
            />

          <Input
            label={"Type"}
            placehloder={"type"}
            name={"type"}
            defaultValue={editStep && editStep.type ? editStep.type : ""}
            register={register({
              required: "Compléter la ville",
            })}
            withErrors={true}
            error={errors.type && errors.city && errors.type.message}
          />

          <div className="row">

            <div className={styles['col-2']}>
              <Controller
                name="startDate"
                rules={{
                  required: "la date de départ est requise",
                  validate: (value) => value <= watch('endDate') || "la date n'est pas antérieure à la date de fin"
                }}
                control={control}
                defaultValue={editStep && editStep.startDate ? new Date(editStep.startDate) : new Date()}
                render={(props) =>
                  <DatePicker
                    label={"Date de départ"}
                    handleChange={(val) => setValue("startDate" , val)}
                    withErrors={true}
                    date={editStep && editStep.startDate ? new Date(editStep.startDate) : new Date()}
                    error={errors.startDate && errors.startDate.message}
                    />
                }
              />
            </div>
            
            <div className={styles['col-2']}>
              <Controller
                name="endDate"
                rules={{
                  required: "la date de fin est requise",
                  validate: (value) => value >= watch('startDate') || "la date est antérieure à la date de départ"
                }}
                control={control}
                defaultValue={editStep && editStep.endDate ? new Date(editStep.endDate) : new Date()}
                render={(props) =>
                  <DatePicker
                    label={"Date de fin"}
                    handleChange={(val) => setValue("endDate" , val)}
                    withErrors={true}
                    date={editStep && editStep.endDate ? new Date(editStep.endDate) : new Date()}
                    error={errors.endDate && errors.endDate.message}
                    />
                }
              />
            </div>

          </div>

          <div className="modale-actions start">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          {editStep?._id &&
            <button
              type={"button"}
              onClick={() => deleteStep()}
              className={"btn-delete"}
              >
              <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
            </button>
          }
        </div>
    
        </form>
    </div>
  )
}
