import React , { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../../../context/GlobalContext';

import Button from '../../../../partials/Button/Button';
import ResumeModale from './ResumeModale/ResumeModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Resume.module.scss';

export default function Resume({ study , id , setActiveSection , isPreview , isPanel }) {

  const [ modaleIsActive , setModaleIsActive ] = useState(false);

  const context  = useGlobalContext()[0];

  const { optionsLists }  = context.studies
  const { isDoctor , userAuth } = context.auth;
  const isCro = userAuth?.division?.find(d => d === 'CRO' ) ? true : false ; 
  return (
    <>
      <section id={id}  onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
        
        <div className={styles.title}>
          <h1>{study.name}</h1>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Editer
            </Button>
          }
          {isPanel && !isDoctor &&
            <Link to={`/edit-study/${study._id}`}>
              <Button primary>
                Editer l’étude
              </Button>
            </Link>
          }
        </div>
        
        {isPreview ?
          <div className={`${styles.infos} ${styles.preview}`}>
            <div className={styles.col}>
              {study.type &&
                <div className={styles.info}>
                  <p className="text-grey">Type de l’étude</p>
                  <p> {study.type}</p>
                </div>
              }
              {study.patients && 
                <div className={styles.info}>
                  <p className="text-grey">Recrutement</p>
                  <p> {study.patients.length} patients</p>
                </div>
              }
            </div>
            <div className={styles.col}>
              {study.company && optionsLists?.companies && 
                <div className={styles.info}>
                  <p className="text-grey">Laboratoire</p>
                  <p>{optionsLists?.companies?.find((c) => c.value === study.company)?.label}</p>
                </div>
              }
              {optionsLists && 
                <div className={styles.info}>
                  <p className="text-grey">Coordinateur</p>
                  <p>
                    {study?.coordinator?.profile?.firstName && study?.coordinator?.profile?.firstName }
                    {study?.coordinator?.profile?.lastName && ` ${study?.coordinator?.profile?.lastName}` }
                  </p>
                </div>
              }
              {study.budget && !isDoctor && !isCro &&
                <div className={styles.info}>
                  <p className="text-grey">Budget</p>
                  <p>{study.budget} {study.budjectCurrency && study.budjectCurrency}</p>
                </div>
              }
            </div>
          </div>
        :
          <div className={styles.infos}>
            {study.type &&
              <div className={styles.info}>
                <p className="text-grey">Type de l’étude</p>
                <p> {study.type}</p>
              </div>
            }
            {study.patients && 
              <div className={styles.info}>
                <p className="text-grey">Recrutement</p>
                <p> {study.patients.length} patients</p>
              </div>
            }
            {study.budget && !isDoctor && !isCro  && 
              <div className={styles.info}>
                <p className="text-grey">Budget</p>
                <p>{study.budget} {study.budjectCurrency && study.budjectCurrency}</p>
              </div>
            }
          </div>
        }

        {study.company && optionsLists?.companies && 
          <div className={styles.description}>
            <p className={`text-grey ${styles.label}`}>Laboratoire</p>
            <p>{optionsLists?.companies?.find((c) => c.value === study.company)?.label}</p>
          </div>
        }

        {!isPreview &&
          <div className={styles.description}>
            <p className={`text-grey ${styles.label}`}>Coordinateur</p>
            <p>
              {study?.coordinator?.profile?.firstName && study?.coordinator?.profile?.firstName }
              {study?.coordinator?.profile?.lastName && ` ${study?.coordinator?.profile?.lastName}` }
            </p>
          </div>
        }

        <div className={styles.description}>
          <p className={`text-grey ${styles.label}`}>Description</p>
          <p>{study.abstract}</p>
        </div>
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <ResumeModale study={study} isCro={isCro}/>}
      </Modale>
    </>
  )
}
