import React , { useState , useEffect , useRef } from 'react';
import { format } from 'date-fns';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import PatientsModale from './PatientsModale/PatientsModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Patients.module.scss';

export default function Patients({ study , id , setActiveSection , isPreview }) {

  const [ context , dispatch ] = useGlobalContext();
  const { isDoctor , userAuth } = context.auth;
  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ patients , setPatients ] = useState(study.patients ? study.patients : [])
  const [ editPatient , setEditPatient ] = useState();

  useEffect(() => {
    if (patients !== study.patients) {
      putStudyAction(dispatch , study._id , {patients: patients } );
    }
    // eslint-disable-next-line
  },[patients]);

  useEffect(() => {
    if (!modaleIsActive) setEditPatient();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editPatient) setModaleIsActive(true);
  }, [editPatient]);

  function deletePatient(id) {
    let patientsList = study.patients.filter(item => item._id !== id);
    putStudyAction(dispatch , study._id , { patients: patientsList } );
    setEditPatient();
    setModaleIsActive(false);
  }

  function updatePatient(data) {
    let updatedList = study.patients.filter(item => item._id !== data._id);
    updatedList.push(data);
    putStudyAction(dispatch , study._id , { patients: updatedList } );
  }

  const patientsList = study.patients?.filter( p => {
    if(!isDoctor) return p
    if(isDoctor && p.investigator === userAuth?._id) return p 
    return null
  })

  return (
    <>
      <section id={id}  onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
            
        <div className="header">
          <div className="title">
            <h3>{isDoctor ? "Liste de mes patients" : "Mes recrutements"} <span>({patientsList.length})</span></h3>
            {patientsList.length > 0 &&
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un patient
            </Button>
          }
          </div>
        </div>
        
        {patientsList.length > 0 &&
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.patients}>
              {patientsList.map((p, i) => 
                <div key={`patient${i}`}>
                  {isPreview ?
                    <div className={`grey-box ${styles.patient}`}>
                      <p>{p.id}</p>
                      <p className="text-grey">{format(new Date(p.inclusionDate) , "dd/MM/yyyy")}</p>
                    </div>
                    :
                    <button
                      type="button"
                      onClick={() => setEditPatient(p)}
                      className={`grey-box ${styles.patient} ${styles.btn}`}>
                      <p>{p.id}</p>
                      <p className="text-grey">{format(new Date(p.inclusionDate) , "dd/MM/yyyy")}</p>
                    </button>
                  }
                </div>
              )}
            </div>
          </div>
        }

      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <PatientsModale
            deletePatient={deletePatient}
            editPatient={editPatient}
            updatePatient={updatePatient}
            patients={patients}
            setPatients={setPatients}/>}
      </Modale>
    </>
  )
}
