import React from 'react';
import styles from './Indicator.module.scss';

export default function Indicator({ label , isTrue }) {
  return (
    <>
      <button className={`${styles.indicator} ${!isTrue ? styles['not-active']: ""}`}>
        <div className={`${styles.message} ${!isTrue ? styles['not-active']: ""}`}>
          <p>{label} <span className={styles.value}>{isTrue ? "OK": "NON"}</span></p>
        </div>
      </button>
    </>
  )
}
