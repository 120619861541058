import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { postStudyAction } from '../../../../../actions/studiesActions';

import Input from '../../../../partials/Form/Input/Input';
import Button from '../../../../partials/Button/Button';
import CustomSelect from "../../../../partials/Form/Select/CustomSelect";

import styles from './CreateModale.module.scss';

export default function CreateModale({ close }) {

  const [ context , dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const { optionsLists } = context.studies;

  useEffect(() => {
    register("type");
  }, [register])

  const submitForm = (values)  => {
    postStudyAction(dispatch , values );
    close();
  };

  return (
    <div className={`modale-content ${styles.container}`}>

      <h2>Créér une étude</h2>

      <form onSubmit={handleSubmit(submitForm)}>

        <Input
          label={"Titre"}
          placehloder={"titre de l'étude"}
          name={"name"}
          register={register({
            required: "Compléter le titre",
          })}
          withErrors={true}
          error={errors.name && errors.name.message}
        />

        <Controller
          name="type"
          rules={{ required: "le type est requis" }}
          control={control}
          render={(props) =>
            <CustomSelect
              label={"Type"}
              defaultValue={null}
              handleChange={(opt) => setValue('type' , opt.value)}
              options={optionsLists && optionsLists.studyTypes}
              withErrors={true}
              error={errors.type && errors.type.message}
              />
          }
          defaultValue={null}
        />

        <div className="modale-actions center">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
        </div>
  
      </form>
    </div>
  )
}
