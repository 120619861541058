import { postData , getData , putData , getFile , deleteData } from './index';
import { saveAs } from 'file-saver';

import {
  ERROR_STUDIES,
  POST_FILE , 
  ERROR_UPLOAD,
  PUT_STUDY,
  POST_STUDY ,
  GET_STUDIES_LIST,
  GET_STUDY,
  DELETE_STUDY,
  GET_OPTIONS_LIST,
  DELETE_FILE,
  SET_TOAST,
  PUT_FOLDER
} from './types';

export async function getOptionsListsAction(dispatch) {
  let url = "/study/list";
  var list;

  await getData(ERROR_STUDIES, url, dispatch , true ).then((response)=>{
    list = response.lists
  });

  list.phasesOctapharma.forEach(p => {
    if (p.label === "Recrutement") p.label = "Sélection"
  });

  list.planning.forEach(p => {
    if (p.label === "Phase de recrutement des patients") p.label = "Phase de sélection des patients"
  });

  dispatch({
    type : GET_OPTIONS_LIST,
    payload : list
  });

};

export async function getStudiesListsAction(dispatch) {
  let url = "/study";
  var list;

  await getData(ERROR_STUDIES, url, dispatch , true ).then((response)=>{
    list = response.studies
  });

  dispatch({
    type : GET_STUDIES_LIST,
    payload : list
  });

};

export async function getStudyAction(dispatch , id ) {
  let url = `/study/${id}`;
  var study;

  await getData(ERROR_STUDIES, url, dispatch , true ).then((response)=>{
    study = response.study
  });

  dispatch({
    type : GET_STUDY,
    payload : study
  });

};

export async function postStudyAction(dispatch , obj){
  let url = "/study";
  var studyCreated;

  await postData(POST_STUDY , ERROR_STUDIES , url, dispatch , obj, true ).then((response)=>{
    studyCreated = response
  });

  dispatch({
    type : POST_STUDY,
    payload : studyCreated
  });
};

export async function putStudyAction(dispatch , id , obj){
  let url = `/study/${id}`;
  var studyUpdated;

  await putData(PUT_STUDY , ERROR_STUDIES , url, dispatch , obj, true ).then((response)=>{
    studyUpdated = response
  });

  dispatch({
    type : PUT_STUDY,
    payload : studyUpdated
  });
};

export async function deleteStudyAction(dispatch , id ){
  let url = `/study/${id}`,
      deleteStudy;

  await deleteData(ERROR_STUDIES, url, dispatch  ).then((response)=>{
    deleteStudy = response.message
  });

  dispatch({
    type : DELETE_STUDY ,
    payload : {
      message: deleteStudy,
      _id: id
    }
  });

};

export async function postFileAction(dispatch , formData ){
  let url = "/file",
      file;

  await postData(POST_FILE , ERROR_UPLOAD , url, dispatch , formData , true ).then((response)=>{
    file = response
  });

  dispatch({
    type : POST_FILE ,
    payload : file.files[0]
  });

};

export async function deleteFileAction(dispatch , id ){
  let url = "/file/" + id,
      file;

  await deleteData(ERROR_UPLOAD, url, dispatch  ).then((response)=>{
    file = response
  });

  dispatch({
    type : DELETE_FILE ,
    payload : {
      file: null,
      message: `${file.message} ${id}`
    }
  });

};

export async function getFileAction(dispatch , id  , downloadName = false , fileType = null ) {
  let url = `/file/download/${id}`;

  await getFile(ERROR_UPLOAD , url, dispatch , true  ).then((response)=>{
    if (!downloadName && !fileType ) {
      var fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    } else {
      let blob = new Blob([response.data], {type: 'application/octet-stream'})
      saveAs(blob, downloadName  )
    }
  });

};

export async function sendNewsLetterAction(dispatch , studyId , newsletterId ) {
  let url = `/study/${studyId}/newsletter/${newsletterId}`;
  var news;

  await getData(ERROR_STUDIES, url, dispatch , true ).then((response)=>{
    news = response.message
  });
  if (news) {
    dispatch({
      type : SET_TOAST,
      payload : {
        type: 'success',
        message: 'La newsletter a été envoyée'
      }
    });
  }
};


export async function putFolderAction(dispatch, id, data) {
  let url = `/folder/${id}`;
  const res = await putData(PUT_FOLDER , ERROR_STUDIES , url, dispatch ,data, true );
  if (res.folder.study) {
    await getStudyAction(dispatch, res.folder.study)
  }
  return res.folder;
}

export async function postFolderAction(dispatch, data) {
  let url = '/folder';
  const res = await postData(PUT_FOLDER , ERROR_STUDIES , url, dispatch , data , true );
  if (res.folder.study) {
    await getStudyAction(dispatch, res.folder.study)
  }
  return res.folder;
}

export async function deleteFolderAction(dispatch, id , study) {
  let url = `/folder/${id}`;
  const res = await deleteData(ERROR_STUDIES, url, dispatch);
  if (res && study) {
    await getStudyAction(dispatch, study)
  }
}


