import React , { useState , useEffect } from 'react';
import { format } from 'date-fns';
import { useForm , Controller  } from "react-hook-form";

import Button from '../../../../../partials/Button/Button';
import DatePicker from '../../../../../partials/DatePicker/DatePicker';

import styles from './InclustionsModale.module.scss';

export default function InclusionsModale({ centers , submitCenters , editCenter  }) {

  const { handleSubmit, register, errors , setValue ,  control } = useForm();
  const [ inclusions , setInclusions ] = useState(editCenter.inclusion ? editCenter.inclusion : []);

  const [ dates , setDates ]= useState({});

  function updateCenter(values) {
    values.inclusion = inclusions;
    let updatedList = centers.filter(c => c._id !== editCenter._id );
    let updated = {...editCenter , ...values} 
    submitCenters([...updatedList , updated]);
  }

  useEffect(() => {
    if(dates.inclusionEndDate && dates.inclusionStartDate) {
      let prevByMonth = [];
      let currentMonth = new Date(dates.inclusionStartDate);
      let lastMonth = new Date(dates.inclusionEndDate);
      do {
        prevByMonth.push({ date: currentMonth , value: 0 });
        currentMonth = new Date(currentMonth.setMonth(currentMonth.getMonth()+1));
      } while (currentMonth < lastMonth );
      setInclusions(prevByMonth);
    }
  },[dates]);

  useEffect(() => {
    register("inclusionStartDate");
    register("inclusionEndDate");
  }, [register]);

  function handleChangeInclusion(index , val) {
    var updatedInclusions = [...inclusions];
    updatedInclusions[index] = {...updatedInclusions[index] , value : parseFloat(val) }
    setInclusions([...updatedInclusions]);
  }

  function handleChangeDate(key , val ) {
    setValue(key , val)

    let updatedDates = {...dates};
    updatedDates[key] = val

    setDates(updatedDates);
  }

  function renderEdit() {
    return (
      <>
        <h2>Inclusions prévisionnelles <br/>{editCenter.center.name}</h2>
        <form onSubmit={handleSubmit(updateCenter)}>

          <div className="row">
            <div className={styles['col-2']}>
              <Controller
                name="inclusionStartDate"
                rules={{ required: "la date de début des inclusions est requise" }}
                control={control}
                defaultValue={editCenter.inclusionStartDate ? new Date(editCenter.inclusionStartDate): new Date()}
                render={(props) =>
                  <DatePicker
                    label={"Date de début des inclusions"}
                    id={"inclusion-start-date"}
                    handleChange={(val) => handleChangeDate("inclusionStartDate" , val)}
                    withErrors={true}
                    date={editCenter.inclusionStartDate ? new Date(editCenter.inclusionStartDate): new Date()}
                    error={errors.inclusionStartDate && errors.inclusionStartDate.message}
                    />
                }
              />
            </div>
            <div className={styles['col-2']}>
              <Controller
                name="inclusionEndDate"
                rules={{ required: "la date de fin des inclusions est requise" }}
                control={control}
                defaultValue={editCenter.inclusionEndDate ? new Date(editCenter.inclusionEndDate): new Date()}
                render={(props) =>
                  <DatePicker
                    label={"Date de fin des inclusions"}
                    id={"inclusion-end-date"}
                    handleChange={(val) => handleChangeDate("inclusionEndDate" , val)}
                    withErrors={true}
                    date={editCenter.inclusionEndDate ? new Date(editCenter.inclusionEndDate): new Date()}
                    error={errors.inclusionEndDate && errors.inclusionEndDate.message}
                    />
                }
              />
            </div>
          </div>

          {inclusions &&
            <div className={styles.list}>
              {inclusions.map((item , i) => {
                return (
                  <div key={'inclusion' +  i} className={`row ${styles.inclusion}`}>
                    <p>{format(new Date(item.date) , "MM/yyyy")}</p>
                    <p>Nombre de patients </p>
                    <input
                      value={item.value}
                      id={"inclusion" +  i}
                      type="number"
                      onChange={(e) => handleChangeInclusion( i , e.target.value) }/>
                  </div>
                )
              })}
            </div>
          }

          <div className="modale-actions start">
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
          </div>
        </form>
      </>
    )
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      {renderEdit()}
    </div>
  )
}
