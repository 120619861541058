import React from 'react';
import { format } from 'date-fns';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { getStudyAction } from '../../../../../actions/studiesActions';

export default function StudyRow({ styles , study , activeStudy }) {

  const [ context, dispatch ] = useGlobalContext();

  const updatedAt = new Date(study.updatedAt);

  const { isDoctor , userAuth } = context.auth;

  const patientsList = study.patients?.filter( p => {
    if(!isDoctor) return p
    if(isDoctor && p.investigator === userAuth?._id) return p 
    return null
  })

  return (
    <div
      onClick={() => getStudyAction(dispatch , study._id)}
      className={`${styles.row} row-box ${activeStudy && activeStudy._id === study._id ? styles['is-active'] : ""}`}>

      <div className={`${styles.col} ${styles.name}`}>
        <p className="bold">{study.name}</p>
      </div>

      <div className={`${styles.col}`}>
        <p>{study.therapeuticArea.map((t) => <span key={t.name}>{t.name}<br/></span>)}</p>
      </div>

      <div className={`${styles.col} ${styles.date}`}>
        <p>{format(updatedAt , "dd/MM/yyyy' 'HH:mm")}</p>
      </div>

      <div className={`${styles.col}`}>
        <p>{patientsList.length} patient{patientsList.length !== 1 ? "s" : ""}</p>
      </div>

      <div className={`${styles.col} ${styles.status}`}>
        {study.activeStep.label && study.activeStep.color &&
          <>
            <div className={styles.puce} style={{ backgroundColor :  study.activeStep.color }}></div>
            <p>{study.activeStep.label}</p>
          </>
        }
      </div>
      
    </div>
  )
}
