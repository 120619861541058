import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import MeetModale from './MeetModale/MeetModale';
import MeetRow from './MeetRow/MeetRow'
import Modale from '../../../../partials/Modale/Modale';

import styles from './Meets.module.scss';

export default function Meets({ study , id , setActiveSection , isPreview }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { appointments } = study;
  const investigators = context?.studies?.optionsLists?.investigators || [];
  const cros = context?.studies?.optionsLists?.cros || [];
  const investigatorsList = [...investigators, ...cros];

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ editMeet , setEditMeet ] = useState();
  const [ height , setHeight ] = useState(0);
  const [ textOpen , setTextOpen ] = useState(false);

  useEffect(() => {
    if (!modaleIsActive) setEditMeet();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editMeet) setModaleIsActive(true);
  }, [editMeet]);

  function updatedPanel() {
    setTextOpen(!textOpen);
  }

  useEffect(() => {
    if (panelIsOpen && contentRef.current) {
      setHeight(contentRef.current.clientHeight);
    } else {
      setHeight(0);
    }
  },[panelIsOpen, textOpen , appointments ])

  function submitMeets(array) {
    putStudyAction(dispatch , study._id , { appointments: array } );
  }

  function deleteMeet(id) {
    let deleteList = appointments.filter(d => d._id !== id);
    setEditMeet();
    setModaleIsActive(false);
    submitMeets(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Historique de mes rendez-vous <span>({appointments.length})</span></h3>
            {appointments.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un rdv
            </Button>
          }
          </div>
        </div>

        {appointments.length > 0 && 
          <div className="panel" style={{ height: height }}>
            <div ref={contentRef} className={styles.meets}>

              <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Titre
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Date
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Type de rendez-vous
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Nombre de participants
                </div>
              </div>

              {appointments && appointments.map((rdv, i) => 
                <MeetRow 
                  key={`meet${i}`}
                  styles={styles} rdv={rdv}
                  isPreview={isPreview}
                  contributors={investigatorsList?.filter(c => rdv.contributors.find(item => c.value === item) ? c : null )}
                  updatedPanel={updatedPanel}
                  setEditMeet={setEditMeet}/> 
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <MeetModale
            editMeet={editMeet}
            deleteMeet={deleteMeet}
            contributorsOptions={investigatorsList}
            appointments={appointments}
            setModaleIsActive={setModaleIsActive}
            submitMeets={submitMeets}/>}
      </Modale>
    </>
  )
}
