import React, { useEffect , useState } from "react";
import { useGlobalContext } from '../../context/GlobalContext';
import { Switch , Route , useLocation , useHistory } from "react-router-dom";
import { useTransition, animated } from 'react-spring';
import { setVhUnit } from '../../utils/utils';
import icon from '../../assets/images/medocta-icon.svg';

//PARTIALS
import Toaster from "../partials/toaster/Toaster";
import Nav from "../partials/nav/Nav";
import Filters from '../partials/Filters/Filters';

//PAGES
import Account from "../pages/account/Account";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import SwitchPage from "../pages/auth/SwitchPage"

import StudiesPages from "../pages/studies/StudiesPages";
import EditSudyPage from "../pages/EditStudy/EditSudyPage";
import Status from "../pages/Status/Status";
import Centers from "../pages/Centers/Centers";

import DataPolicy from "../pages/Legal/DataPolicy";
import Legal from "../pages/Legal/Legal";
import Transparency from "../pages/Legal/Transparency";

export default function GlobalRouter() {

  const [ context, dispatch ] = useGlobalContext();

  const location = useLocation();
  const history = useHistory();

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(-100%, 0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(100%, 0 ,0)'},
  });

  const [ activePage , setActivePage ] = useState("login");

  useEffect(() => {
    setVhUnit();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(context.auth.error === 401) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);

  function logout() {
    history.push("/login");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <>
      <Nav
        logout={logout}
        activePage={activePage}
        />
      {activePage === "studies" || activePage === "status"|| activePage === "centers" ? <Filters /> : null }

      {transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props}>

          <Switch location={location}>

            <Route exact path="/" render={
              (props) => <SwitchPage
              {...props}
              />
            }/>
            
             <Route exact path="/login" render={
              (props) => <Login
              logout={logout}
              context={context}
              dispatch={dispatch}
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/reset-password/:token" render={
              (props) => <ResetPassword
              context={context}
              dispatch={dispatch}
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/account" render={
              (props) => <Account
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/legal" render={
              (props) => <Legal
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/data-policy" render={
              (props) => <DataPolicy
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/transparency" render={
              (props) => <Transparency
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/study" render={
              (props) => <StudiesPages
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route path="/edit-study/:studyId" render={
              (props) => <EditSudyPage
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/status" render={
              (props) => <Status
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/centers" render={
              (props) => <Centers
              setActivePage={setActivePage}
              {...props}
              />
            }/>    

          </Switch>
        </animated.div>
      ))}
      {activePage === "studies" || activePage === "status"|| activePage === "centers" 
        ? <a href='https://medocta.octapharma.bakasable.fr' target='_blank' rel="noreferrer"  className='medocta-link'>
            <img src={icon} alt='medocta-icon'/>
        </a>
        : null
      }
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
    </>
  )
}
