import React from 'react';
import { CgChevronDown , CgChevronUp } from 'react-icons/cg'
import styles from './SortButton.module.scss';

export default function SortButton({ name , action , state , className  }) {
  return (
    <button onClick={() => action()} className={`${styles['sort-item']} ${className}`}>
      <p>{name}</p> 
      <div className={styles['icon']}>
        {state ?  <CgChevronUp size={23} color={'#999eb0'}/> : <CgChevronDown size={23} color={'#999eb0'}/>}
      </div> 
    </button>
  )
}