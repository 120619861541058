import React from 'react';
import styles from './Textarea.module.scss';

export default function Textarea({ onChange , name , label , defaultValue , value , withErrors , error , register , placehloder , type , ...rest }) {
  return (
    <div className={styles.textarea}>
      <label htmlFor={name}>{label}</label>
      {!register ? 
        <>
          <textarea
            name={name}
            placeholder={placehloder}
            value={value ? value : ''}
            onChange={(e) => onChange(e.target.value)}
            />
        </>
        :
        <>
          <textarea
            name={name}
            className={error ? styles.error : ''}
            placeholder={placehloder}
            defaultValue={defaultValue ? defaultValue : ''}
            ref={register}
            {...rest}
            />
        </>
      }
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
