import React , { useState } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete } from 'react-icons/ai';

import Button from '../../../../../partials/Button/Button';
import Input from '../../../../../partials/Form/Input/Input';
import Textarea from '../../../../../partials/Form/Textarea/TextArea';
import DatePicker from '../../../../../partials/DatePicker/DatePicker';
import TimePicker from '../../../../../partials/DatePicker/TimePicker';
import styles from './NewsModale.module.scss';

export default function NewsModale({ newsletters ,  editLetter , deleteLetter , submitLetters }) {

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const [ date , setDate ] = useState(editLetter?.date ? new Date(editLetter.date): null );

  function submitForm(values) {
    if (!editLetter) {
      submitLetters([...newsletters , values])
    } else {
      let list = newsletters.filter(item => item._id !== editLetter._id );
      submitLetters([...list , values])
    }
  }

  function handleChange( key , val) {
    setValue(key , val);
    setDate(val)
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>{!editLetter ? "Ajouter une newsletter" : "Modifier une newsletter"}</h2>
      <form onSubmit={handleSubmit(submitForm)}>

        <div className="row">
          <div className={styles['col-2']}>
            <Controller
              name="date"
              rules={{ required: "la date est requise" }}
              control={control}
              defaultValue={date}
              render={(props) =>
                <DatePicker
                  label={"Date"}
                  handleChange={(val) => handleChange("date" , val)}
                  withErrors={true}
                  date={date}
                  error={errors.date && errors.date.message}
                  />
              }
            />
          </div>
          <div className={styles['col-2']}>
            {date &&
              <Controller
                name="date"
                rules={{ required: "la date est requise" }}
                control={control}
                defaultValue={date}
                render={(props) =>
                  <TimePicker
                    label={"Heure"}
                    handleChange={(val) => handleChange("date" , val)}
                    withErrors={true}
                    date={date}
                    error={errors.date && errors.date.message}
                    />
                }
              />
            }
          </div>
        </div>


        <Input
          label={"Titre de la newsletter"}
          name={"title"}
          defaultValue={editLetter?.title}
          register={register({
            required: "le titre est requis",
          })}
          withErrors={true}
          error={errors.title && errors.title.message}
        />

        <Textarea
          label={"Description"}
          placehloder={""}
          name={"description"}
          onChange={(val) => setValue('description' , val )}
          register={register({
            required: "la description est requise",
          })}
          defaultValue={editLetter?.description ? editLetter.description : '' }
          withErrors={true}
          error={errors.description && errors.description.message}
        />

        <div className="modale-actions start">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          <button
            type={"button"}
            onClick={() => deleteLetter(editLetter._id)}
            className={"btn-delete"}
            >
            <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
          </button>
        </div>

      </form>
    </div>
  )
}
