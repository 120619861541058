import React , { useCallback, useEffect, useState } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete, AiOutlineFileDone } from 'react-icons/ai';

import Button from '../../../../../partials/Button/Button';
import Input from '../../../../../partials/Form/Input/Input';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';
import Textarea from '../../../../../partials/Form/Textarea/TextArea';
import DatePicker from '../../../../../partials/DatePicker/DatePicker';
import TimePicker from '../../../../../partials/DatePicker/TimePicker';
import styles from './MeetModale.module.scss';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import InputFile from '../../../../../partials/Form/InputFile/InputFile';
import { deleteFileAction, postFileAction } from '../../../../../../actions/studiesActions';
import { API_URL } from '../../../../../../actions';

export default function MeetModale({ appointments , contributorsOptions , editMeet , deleteMeet , submitMeets , setModaleIsActive }) {
  const [ context, dispatch ] = useGlobalContext();
  const { file  } = context.studies;
  
  const { handleSubmit, register, watch, errors , setValue , control } = useForm({
    defaultValues: editMeet,
  });

  const [ date , setDate ] = useState(editMeet?.date ? new Date(editMeet.date): new Date());
  const index = appointments.findIndex((a) => a?.title === editMeet?.title )
  const files = index !== -1 ? appointments[index]?.files : [];
  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();

  const rdvOptions = [ 'Réunion' , 'Conférence'].map(opt =>  ({ value: opt , label: opt  }));

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  function submitForm(values) {
    let data = {...values}
    if (!values.files) {
      data.files = files.map((f) => f._id);
    }
    if (index === -1) {
      submitMeets([...appointments , data]);
      setModaleIsActive()
    } else {
      let list = appointments;
      list[index] = data;
      submitMeets(list)
    }
  }

  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    const deletedList = files.filter((f) => f._id !== id ? f._id : null);
    submitForm({ ...watch(), files: deletedList })
  }

  function handleChange( key , val) {
    setValue(key , val);
    setDate(val)
  }

  function handleChangeFile(fileData ) {
    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });
    setUploadedFile(fileData);
    setFileError();
    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `fileData.name`);
    formData.append("alt", `${fileData.name}`);
    postFileAction(dispatch , formData )

  }

  useEffect(() => {
    if (context?.studies?.errors?.file?.message) {
      setFileError(context?.studies?.errors?.file?.message);
    }
  },[context?.studies?.errors?.file?.message]);

  useEffect(()=> {
    if (file && file._id) {
      submitForm({ ...watch(), files: [...files.map((f) => f._id), file._id ]})
      if (uploadedFile) document.getElementById('meet-file').value = "";
      clearFile();
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>{!editMeet ? "Ajouter un rendez-vous" : "Modifier un rendez-vous"}</h2>
      <form onSubmit={handleSubmit(submitForm)}>

        <div className="row">
          <div className={styles['col-2']}>
            <Controller
              name="date"
              rules={{ required: "la date est requise" }}
              control={control}
              defaultValue={date}
              render={(props) =>
                <DatePicker
                  label={"Date"}
                  handleChange={(val) => handleChange("date" , val)}
                  withErrors={true}
                  date={date}
                  error={errors.date && errors.date.message}
                  />
              }
            />
          </div>
          <div className={styles['col-2']}>
            <Controller
              name="date"
              rules={{ required: "la date est requise" }}
              control={control}
              defaultValue={date}
              render={(props) =>
                <TimePicker
                  label={"Heure"}
                  handleChange={(val) => handleChange("date" , val)}
                  withErrors={true}
                  date={date}
                  error={errors.date && errors.date.message}
                  />
              }
            />
          </div>
        </div>

        <Controller
          name="type"
          rules={{ required: "le type est requis" }}
          control={control}
          render={(props) =>
            <CustomSelect
              label={"Type de rendez-vous"}
              defaultValue={editMeet?.type && rdvOptions.find(opt => opt.value === editMeet.type) ? rdvOptions.find(opt => opt.value === editMeet.type) : null}
              handleChange={(opt) => setValue('type' , opt.value)}
              options={rdvOptions}
              withErrors={true}
              error={errors.type && errors.type.message}
              />
          }
          defaultValue={editMeet?.type}
        />

        <Input
          label={"Titre du rendez-vous"}
          placehloder={"title"}
          name={"title"}
          defaultValue={editMeet?.title}
          register={register({
            required: "le titre est requis",
          })}
          withErrors={true}
          error={errors.title && errors.title.message}
        />

        <Controller
          name="contributors"
          rules={{ required: "les contributeurs est requis" }}
          control={control}
          render={(props) =>
            <CustomSelect
              label={"Contributeurs"}
              defaultValue={editMeet?.contributors && contributorsOptions.filter(opt => editMeet.contributors.find(c => c === opt.value) ? opt : null ) ? contributorsOptions.filter(opt => editMeet.contributors.find(c => c === opt.value) ? opt : null ) : null}
              isMulti={true}
              handleChange={(val) => setValue('contributors' , val.map(opt => opt.value))}
              options={contributorsOptions}
              withErrors={true}
              error={errors.contributors && errors.contributors.message}
              />
          }
          defaultValue={editMeet?.contributors}
        />

        <Textarea
          label={"Compte-rendu"}
          placehloder={""}
          name={"report"}
          onChange={(val) => setValue('report' , val )}
          register={register}
          defaultValue={editMeet?.report ? editMeet.report : '' }
        />

        {watch('type') && watch('contributors') && watch('title') &&
          <div className={styles.files}>
            <label>Fichiers</label>
            {files?.map((file) => 
              <div key={file._id} className={styles.file}>
                <a href={`${API_URL}/${file?.path}`} rel="noreferrer" target="_blank">
                  <div className={styles.link}>
                    <AiOutlineFileDone size={20} color={'#FFFFFF'} />
                    <p>{file?.name}</p>
                  </div>
                </a>
                <button
                type="button"
                onClick={() => deleteFile(file?._id)}
                className={`${styles.delete}`}>
                <AiFillDelete size={20} color={'red'} />
                <p>supprimer</p>
              </button>
              </div>
            )}
            <InputFile
              name="meet-file"
              isLoading={uploadedFile}
              withErrors={true}
              defaultValue={null}
              onChange={(files) => handleChangeFile(files[0])}
              error={fileError}
              />
          </div>
        }

        <div className="modale-actions start">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          <button
            type={"button"}
            onClick={() => deleteMeet(editMeet._id)}
            className={"btn-delete"}
            >
            <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
          </button>
        </div>

      </form>
    </div>
  )
}
