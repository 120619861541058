import React , { useState } from 'react';
import styles from './ListSelect.module.scss';

export default function ListSelect({ onChange , name , label , defaultValue , options , withErrors , error  }) {
  
  const [ selectedList , setSelectedList ] = useState(defaultValue ? defaultValue : []);

  function handleChange(opt) {
    let list = [...selectedList];
    if (!list.find(d => d.value === opt.value)) {
      list.push(opt);
    } else {
      list = list.filter(d => d.value !== opt.value);
    }
    setSelectedList(list);
    onChange(list);
  }
  return (
    <div className={styles.container}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.list}>
        {options.map((opt, i) =>
          <button
            key={`right${i}`}
            type="button"
            onClick={() => handleChange(opt)}
            className={`${styles.btn} ${selectedList.find(d => d.value === opt.value ) ? styles['is-active'] : ""}`}
            >
            {opt.label}
          </button>
        )}
      </div>
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
