import React from 'react';

import Resume from '../../../EditStudy/sections/Resume/Resume';
import Patients from '../../../EditStudy/sections/Patients/Patients';
import Centers from '../../../EditStudy/sections/Centers/Centers';
import Doctors from '../../../EditStudy/sections/Doctors/Doctors';
import Council from '../../../EditStudy/sections/Council/Council';
import Tmf from '../../../EditStudy/sections/Documents/Tmf';
import Inclusions from '../../../EditStudy/sections/Inclusions/Inclusions';
import Planning from '../../../EditStudy/sections/Planning/Planning';
import Meets from '../../../EditStudy/sections/Meets/Meets';
import Amendements from '../../../EditStudy/sections/Documents/Amendement/Amendements';
import NewsLetters from '../../../EditStudy/sections/NewsLetters/NewsLetters';
import Cros from '../../../EditStudy/sections/Cros/Cros';

import Card from './Card/Card'

import styles from './StudyView.module.scss';
import TherapeuticAreas from '../../../EditStudy/sections/TherapeuticArea/TherapeuticArea';
import Drugs from '../../../EditStudy/sections/Drugs/Drugs';


export default function StudyView({  study , isDoctor , isAdmin , isCro }) {
  return (
    <div className={styles.view}>

      <Resume study={study} isPreview={true} isPanel={true}/>

      {!isDoctor && 
        <>
          <Planning study={study} isPreview={true}/>
          <Centers study={study} isPreview={true}/>
          {isAdmin &&
            <Council
              study={study}
              isPreview={true}
              />
            }
          {isAdmin && !isCro &&
            <Cros
              study={study}
              isPreview={true}
              />
          }
          <Doctors study={study} isPreview={true}/>
        </>
      }
      

      {!isDoctor && <Tmf study={study} isPreview={true}/> }
  
      <Amendements study={study} isDoctor={isDoctor} isPreview={true}/>
      <Patients study={study} isDoctor={isDoctor} isPreview={true}/>
      <Inclusions study={study} isDoctor={isDoctor} isPreview={true}/>
      <TherapeuticAreas study={study} isDoctor={isDoctor} isPreview={true}/>
      <Drugs study={study} isDoctor={isDoctor} isPreview={true}/>

      {!isDoctor && 
        <>
          <Meets study={study} isPreview={true}/> 
          <NewsLetters study={study} isPreview={true}/>
        </>
      }

      {isDoctor &&
        <div className={styles['doctor-card']}>
          <Card type={"cv"} study={study}/>
          <Card type={"bpc"} study={study}/>
        </div>
      }
      
    </div>
  )
}
