import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useGlobalContext } from '../../../../../../context/GlobalContext';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';

import styles from './EditFolder.module.scss';
import { postFolderAction, putFolderAction } from '../../../../../../actions/studiesActions';


export default function EditFolder({ parent , folder , backToFolder, setActiveFolder }) {

  const [ context , dispatch ] = useGlobalContext();

  const { study } = context.studies;

  const { handleSubmit, register, errors, reset } = useForm();


  async function submitForm(values) {
    if (folder?._id) {
      const updatedFolder = await putFolderAction(dispatch, folder._id, {...values})
      setActiveFolder(updatedFolder);
    } else {
      const data = {...values, parent, study: study._id }
      const updatedFolder = await postFolderAction(dispatch, data);
      setActiveFolder(updatedFolder);
    }
    backToFolder();
  }

  useEffect(() => {
    if (folder) {
      reset(folder)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[folder])

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(submitForm)}>
        <h2>{folder?._id ? "Modifier le dossier" : "Ajouter un dossier"}</h2>


        <Input
          label={"Nom du dossier"}
          placehloder={"nom"}
          name={"name"}
          register={register({
            required: "Le nom du dossier est requis",
          })}
          withErrors={true}
          error={errors.name && errors.name.message}
        />

        <div className="modale-actions center">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          <Button
            type={"button"}
            primary
            outline
            onClick={() => backToFolder(false)}
            >
            Annuler
          </Button>
        </div>
      </form>
    </div>
  )
}
