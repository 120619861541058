import React , { useState } from 'react';
import { format } from 'date-fns';
import { AiOutlineMinus , AiOutlinePlus } from "react-icons/ai";

export default function MeetRow({ styles , rdv , isPreview , setEditMeet , contributors , updatedPanel }) {

  const [ textIsOpen , setTextIsOpen ] = useState(false);

  function textToggle() {
    setTextIsOpen(!textIsOpen);
    updatedPanel();
  }

  return (
    <>
    <div className={styles.row}>
      <div className={`${styles.col}`}>

        {isPreview ?
          <span className="bold">
            {rdv.title}
          </span>
          :
          <>
            <button
              className={styles.icon}
              onClick={() => textToggle()}
              >
              { textIsOpen ?  <AiOutlineMinus size={22} color={"#192243"}/> : <AiOutlinePlus size={22} color={"#192243"}/>}
            </button>
            <button
              onClick={() => setEditMeet(rdv)}
              className={`btn-edit-row ${textIsOpen ? styles.isActive : ''}`}>
              {rdv.title}
            </button>
          </>
        }                
      </div>
      <div className={`${styles.col} text-grey"`}>
        {format(new Date(rdv.date) , "dd/MM/yyyy HH:mm")}
      </div>
      <div className={`${styles.col} text-grey`}>
        {rdv.type}
      </div>
      <div className={`${styles.col} text-grey`}>
        {contributors && <>{contributors.length} contributeur{contributors.length !== 1 ? "s" : ""}</>}
      </div>
    </div>
    {textIsOpen && 
      <div className={styles.text}>
        <h4>Contributeurs</h4>
        <div className={styles.contributors}>
          {contributors.map((c,i) => <p key={'contrib' +i}>{c.label}</p>)}
        </div>

        {rdv.report &&
          <>
            <h4>Compte-rendu</h4>
            <p>
              {rdv.report}
            </p>
          </>
        }
      </div>
    }
    </>
  )
}
