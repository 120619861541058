import React , { useEffect , useState , useRef , useCallback } from 'react';
import { Link, useParams , useHistory } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri"
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../context/GlobalContext';

import { getStudyAction , getOptionsListsAction , deleteStudyAction } from '../../../actions/studiesActions';

import styles from './EditStudyPage.module.scss';

import Resume from './sections/Resume/Resume';
import Patients from './sections/Patients/Patients';
import Centers from './sections/Centers/Centers';
import Doctors from './sections/Doctors/Doctors';
import Tmf from './sections/Documents/Tmf';
import Amendements from './sections/Documents/Amendement/Amendements';
import Inclusions from './sections/Inclusions/Inclusions';
import Planning from './sections/Planning/Planning';
import Meets from './sections/Meets/Meets';
import NewsLetters from './sections/NewsLetters/NewsLetters';
import Cros from './sections/Cros/Cros';
import Council from './sections/Council/Council';
import TherapeuticArea from './sections/TherapeuticArea/TherapeuticArea';
import Drugs from './sections/Drugs/Drugs';
import Modale from '../../partials/Modale/Modale';
import { TOOL_ID } from '../../../actions';

export default function EditSudyPage({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();

  const { study , message } = context.studies;
  const { isDoctor  , userAuth } = context.auth;
  const isAdmin = userAuth?.tools.find(p => p.tool === TOOL_ID && p.role === "Admin") ? true : false ;
  const isCro = userAuth?.division.find(d => d === "CRO") ? true : false ;

  const history = useHistory();

  const studyRef = useRef(null);

  let links = [
    {
      name: "Résumé",
      id: "resume"
    },
    {
      name: "Planning",
      id: "steps"
    },
    {
      name: "Liste des centres",
      id: "centers"
    },
    {
      name: "Conseil scientifique",
      id: "council"
    },
    {
      name: "liste des Cros",
      id: "cros"
    },
    {
      name: "Liste des investigateurs",
      id: "investigators"
    },

    {
      name: "TMF",
      id: "files"
    },
    {
      name: "Liste des documents",
      id: "amendements"
    },
    {
      name: "MES RECRUTEMENTS",
      id: "patients"
    },
    {
      name: "Courbes d’inclusion",
      id: "inclusions"
    },
    {
      name: "Aires thérapeutiques",
      id: "therapeuticArea"
    },
    {
      name: "Produits",
      id: "drugs"
    },
    {
      name: "Historique des rendez-vous",
      id: "appointments"
    },
    {
      name: "Newsletters",
      id: "news"
    },
  ];

  if (!isAdmin) {
    links = links.filter(opt => opt.id !== "cros" )
  }

  if (isCro) {
    links = links.filter(opt => opt.id !== "cros" )
  }


  const params = useParams();

  const [ activeLink , setActiveLink ] = useState('resume');
  const [ modaleIsActive , setModaleIsActive ] = useState(false);

  const initData = useCallback(() => {

    setActivePage('study');
    getStudyAction(dispatch , params.studyId)
    getOptionsListsAction(dispatch);

  }, [setActivePage ,  dispatch , params.studyId ]);

  useEffect(() => {
    initData();
  },[initData]);

  useEffect(() => {
    if (message === 'Study updated') {
      dispatch({
        type:'SET_TOAST',
        payload: {
          message: "L'étude a été mise à jour",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_STUDIES',
        payload: null
      })
    }
    if (message === "Study removed") {
      history.push('/');
      dispatch({
        type:'SET_TOAST',
        payload: {
          message: "L'étude a été supprimée",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_STUDIES',
        payload: null
      })
    }
    // eslint-disable-next-line
  },[message])

  function srollToSection(id) {
    setActiveLink(id)
    studyRef.current.scrollTop = document.getElementById(id).offsetTop;
  }

  function setActiveSection(isVisible , id ) {
    if (isVisible) {
      setActiveLink(id)
    }
  }

  function deleteStudy() {
    deleteStudyAction(dispatch , params.studyId)
  }

  return (
    <>
      <div ref={studyRef} className={`page-container ${styles.container}`}>
        {study &&

          <div className={`content`}>

          <header>
            <Link to="/">
              <RiArrowLeftSLine size={26} color={"#4F21B1"}/>
              Retour à la liste des études
            </Link>
          </header>

          <aside>
            <ul>
              {links.map((link, i ) => 
                <li
                  key={`link${i}`}
                  className={activeLink === link.id ? styles['is-active'] : ''}
                  onClick={() => srollToSection(link.id)}>
                  <div className={`${styles.puce} ${activeLink === link.id ? styles['is-active'] : ''}`}>
                  </div>
                  {link.name}
                </li>
              )}
            </ul>
          </aside>

          <main>
            <div className={styles['main-content']}>
            <Resume
              study={study}
              id={"resume"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Planning
              study={study}
              id={"steps"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Centers
              study={study}
              id={"centers"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            {isAdmin &&
              <Council
                study={study}
                id={"council"}
                isPreview={isDoctor}
                setActiveSection={setActiveSection}
                />
            }
            {isAdmin && !isCro  &&
              <Cros
                study={study}
                id={"cros"}
                isPreview={isDoctor}
                setActiveSection={setActiveSection}
                />
            }
            <Doctors
              study={study}
              id={"investigators"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Tmf
              study={study}
              id={"files"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Amendements
              study={study}
              id={"amendements"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Patients
              study={study}
              id={"patients"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Inclusions
              study={study}
              id={"inclusions"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <TherapeuticArea
              study={study}
              id={"therapeuticArea"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Drugs
              study={study}
              id={"drugs"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <Meets
              study={study}
              id={"appointments"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            <NewsLetters
              study={study}
              id={"news"}
              isPreview={isDoctor}
              setActiveSection={setActiveSection}/>
            </div>
            <div className={styles['delete-container']}>
            {!isDoctor && study.files.length === 0 &&
              <button
                type={"button"}
                onClick={() => setModaleIsActive(true)}
                className={styles["btn-delete"]}
                >
                <AiFillDelete size={20} color={'red'}/> <p>Supprimer cette étude</p>
              </button>
            }
            </div>
          </main>

          </div>
        }
      </div>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        className={styles['modale']}
        >
        {modaleIsActive &&  
          <div className={`modale-content ${styles['modale-content']}`}>
            <h2>Souhaitez-vous supprimer cette étude ?</h2>
            <div className="modale-actions center">
              <button
                type={"button"}
                onClick={() => deleteStudy()}
                className={styles["btn-delete"]}
                >
                <AiFillDelete size={20} color={'red'}/> <p>Confirmer la suppression de cette étude</p>
              </button>
            </div>
          </div>
        }
      </Modale>
    </>
  )
}
