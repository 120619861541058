import React , { useEffect , useState ,useRef , useMemo } from 'react';
import * as d3 from 'd3';

import { useGlobalContext } from '../../../../context/GlobalContext';
import {getStudyAction } from '../../../../actions/studiesActions';
import arrowBack from '../../../../assets/images/arrow-back.svg';

import Tooltip from '../../../partials/Tooltip/Tooltip';
import styles from './Map.module.scss';

export default function Map({ data , geojson , mapWidth , mapHeight , setDisplayLegend ,  mapScale , closePanel , mapKey }) {

  const dispatch = useGlobalContext()[1];
  const mapRef = useRef(null);

  const [ tooltipData , setTooltipData ] = useState({ x: -200 , y: -200 });

  useEffect(() => {
    drawMap(geojson);
    window.addEventListener('resize', drawMap(geojson) );
    return () => {
      window.removeEventListener('resize', drawMap(geojson) );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function drawMap(geojsonData) {
    var tempData = [];
    data.forEach((d) => {
      let index = tempData.findIndex((c) => c.name === d.city)
      if (index !== -1) {
        tempData[index].centers = [...tempData[index].centers, d];
        tempData[index].size += 1
      } else {
        tempData.push({
          id: d.city,
          name: d.city,
          size: 1,
          color: '#192243',
          lat: d.lat,
          lon: d.lon,
          type: 'city',
          centers: [d]
        })
      }
    });

    var cityScale = d3.scaleLinear()
      .domain([0, d3.max(tempData, function(d) { return +d.size} )])
      .range([3, 20]);

    var simulation = d3.forceSimulation();
    var centersSimulation = d3.forceSimulation();

    setTooltipData({ x: mapWidth / 2 , y: mapHeight / 2 })

    var height = mapHeight ;
    var width = mapWidth;
    var transform = {k: 1, x: 0, y: 0};

    // var closeNode;
    // var nodePos = [width/2, height/2];
    var selectedCity = null;
    var selectedCenter = null;
    var selectedService = null;

    if (d3.select(`#svg-${mapKey}`)) {
      d3.select(`#svg-${mapKey}`).remove();
    }

    if (d3.select(`#${mapKey}`).select('#cities')) {
      d3.select(`#${mapKey}`).select('#cities').remove();
    }

    if (d3.select(`#${mapKey}`).select('#city')) {
      d3.select(`#${mapKey}`).select('#city').remove();
    }

    var center = d3.geoCentroid(geojsonData);
    var centroid = center;
    var scale  = mapScale
    var offset = [width/2,height/2 ];

    var projection = d3.geoMercator()
      .scale(scale)
      .center(center)
      .translate(offset);

    var geoGenerator = d3.geoPath()
      .projection(projection)

    var svg = d3.select(`#${mapKey}`)
      .append('svg')
      .attr('id' , `svg-${mapKey}` )
      .attr('width' , width + 'px' )
      .attr('height' , height + 'px');

    svg.append("g")
      .attr("id", "area")
      .selectAll("path")
      .data(geojsonData.features)
      .enter().append("path")
      .attr("d", d => geoGenerator(d))
      .attr("fill" , "#E3E3E3")
      .on("click" , clickedCity );

    simulation.nodes(tempData)
      .force("charge", d3.forceManyBody().strength(1))
      .force('collision', d3.forceCollide().radius(function(d) {
        return ( cityScale(d.size) + 1) / transform.k;
      }))
      .force("x", d3.forceX(d => {
        var pos = projection([d.lon, d.lat]);
        return pos[0]
      }))
      .force("y", d3.forceY(d => {
        var pos = projection([d.lon, d.lat]);
        return pos[1]
      }));
    
    simulation.alpha(1).stop();

    var numberOfTicks = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()));
    for (var i = 0, n = numberOfTicks ; i < n; ++i) {
      simulation.tick();
    }

    svg.append("g")
      .attr("id", "markers").selectAll("city")
      .data(tempData , function(d) { return d.id; })
      .enter()
      .append("circle")
        .attr("id" ,  d => d.id)
        .attr("cx", d => d.x )
        .attr("cy", d => d.y )
        .attr("r", d => cityScale(d.size))
        .attr("fill", d => d.color )
        .attr("fill-opacity", 1 )
        .on("mouseover" ,(e, d) => {
          let city = document.getElementById(d.id).getBoundingClientRect();
          setTooltipData({...d , x: city.left - 60 , y: city.top - 40 });
          d3.selectAll('circle')
            .transition()
            .duration(250)
            .attr("fill-opacity", c => {
              if (c && d && c.id === d.id) {
                return selectedCity ? 0  : 1;
              } else {
                return selectedCity ? 0  : .2;
              }
          })
        })
        .on("mouseout" ,(e , d) => {
          let city = document.getElementById(d.id).getBoundingClientRect();
          setTooltipData({ x: city.left - 60 , y: city.top - 40 });
          d3.selectAll('circle')
            .transition()
            .duration(250)
            .attr("fill-opacity", selectedCity ? 0  : 1 )
        })
        .on("click" , clickedCity );

    // function foundNode(e) {
    //   var cursor = {
    //     x: (e.offsetX - transform.x ) / transform.k,
    //     y: (e.offsetY - transform.y) / transform.k 
    //   };
    //   if (simulation.find( cursor.x, cursor.y , 10 / transform.k )) {
    //     return simulation.find( cursor.x, cursor.y , 10 / transform.k );
    //   }
    // }

    function clickedCity(e , d) {
      var x, y, k ;
      d3.select('#city').remove();
      setTooltipData({ x: mapWidth / 2 , y: mapHeight / 2 });
      if (d && d.id ) {
        centroid = projection([d.lon, d.lat]);
        selectedCity =  d ;

        x = centroid[0];
        y = centroid[1];
        k = 5;
      } else {
        x = width / 2;
        y = height / 2;
        k = 1;
        selectedCity = null
      } 

      d3.selectAll('circle')
        .transition()
        .duration(750)
        .attr("style", !selectedCity ? '' : 'pointer-events: none;' )
        .attr("fill-opacity", !selectedCity ? .4 : 0 );

      if (selectedCity) {
        d3.select('#city').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr("id" , "city")
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class' , `${styles.city}` )
          .append('p')
          .attr('class' , `${styles.label}` )
          .on("click" , (e) => clickedCity(e , null))
          .text(`${selectedCity.name}`);

        d3.select(`#city`)
          .append('div')
          .attr('class' , `${styles.back}` )
          .on("click" , (e) => clickedCity(e , null))
          .text(`Retour`)
          .append("img")
          .attr('src' , arrowBack);
        
        const centers = [...selectedCity.centers];
        centersSimulation.nodes(centers, function(d) { return d.id; })
          .force('charge', d3.forceManyBody().strength(5))
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius(function(d) {
            return 45;
          }))
          .tick(300);
          
        d3.select('#city').selectAll(`.${styles.center}`)
        .data(selectedCity.centers , function(d) { return d.id; })
        .enter()
        .append("div")
        .attr("id" ,  d => d.id)
        .attr('class' , styles.center)
        .style('background-color' , d => d.color)
        .style("transform", d => "translate(" + (d.x + 190) + "px," + (d.y + 170 ) + "px)" )
        // .on("mouseover" ,(e, d) => {
        //   mapRef.current.style.cursor = "pointer";
        //   let city = document.getElementById(d.id).getBoundingClientRect();
        //   setTooltipData({...d, x: city.left - 55 , y: city.top - 20 });
        // })
        // .on("mouseout" ,(e, d) => {
        //   mapRef.current.style.cursor = "zoom-out"
        //   let city = document.getElementById(d.id).getBoundingClientRect();
        //   setTooltipData({...d, x: city.left - 55 , y: city.top - 20 });
        // })
        .on('click' , clickedCenter)
        .append('p')
        .text(d => d.name);


      } else {
        d3.select('#city').remove();
        d3.select('#center').remove();
        closePanel();
        setDisplayLegend(false);
      }
      
      d3.select('#city')
        .transition()
        .delay(selectedCity ? 250  : 10 )
        .duration(selectedCity ? 750  : 10 )
        .attr('class' , `${styles.city} ${selectedCity ? styles.isActive : ""}` )

      d3.select(`#${mapKey}`).selectAll('g')
        .transition()
        .duration(750)
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")scale(" + k + ")translate(" + -x + "," + -y + ")")
    }

    function clickedCenter(e , d) {
      var x, y, k ;
      d3.select('#center').remove();
      d3.select('#service').remove();
      d3.select('#city').remove();
      if (d && d.id ) {
        centroid = projection([selectedCity.lon, selectedCity.lat]);
        selectedCenter =  d ;
        x = centroid[0];
        y = centroid[1];
        k = 12;
      } else {
        x = width / 2;
        y = height / 2;
        k = 1;
        selectedCenter = null
      } 

      if (selectedCenter) {
        setDisplayLegend(true);
        d3.select('#service').remove();
        d3.select('#center').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr("id" , "center")
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class' , `${styles['selected-center']}` )
          .append('p')
          .attr('class' , `${styles.label}` )
          .text(`${selectedCenter.name}`);

        d3.select(`#center`)
          .append('div')
          .attr('class' , `${styles.back}` )
          .on("click" , (e) => {
            clickedCenter(e , null)
            clickedCity(e , selectedCity)
          })
          .text(`${selectedCity.name}`)
          .append("img")
          .attr('src' , arrowBack);

        const center = [...selectedCenter.studies];
        centersSimulation.nodes(center, function(d) { return d.id; })
          .force('charge', d3.forceManyBody().strength(5))
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius(function(d) {
            return 45;
          }))
          .tick(300);
          
        d3.select('#center').selectAll(`.${styles.study}`)
        .data(selectedCenter.studies , function(d) { return d.id; })
        .enter()
        .append("div")
        .attr("id" ,  d => d.id)
        .attr('class' , styles.study)
        .style('background-color' , d => d.color)
        .style("transform", d => "translate(" + (d.x + 190) + "px," + (d.y + 190 ) + "px)" )
        .on('click' , (e , d) => {
          getStudyAction(dispatch , d.id);
        })
        .append('p')
        .text(d => d.name);

        const radius = 450 / 2;

        selectedCenter.services.forEach(function(n, i) {
          let length = selectedCenter.services.length > 1 ? selectedCenter.services.length - 1 : selectedCenter.services.length
          let angle = (i / length) * Math.PI;
          n.x = ((radius * Math.cos(angle)) + (radius)) - 45;
          n.y = ((radius * Math.sin(angle)) + (radius)) - 45;
        });


        d3.select('#center').selectAll(`.${styles.service}`)
        .data(selectedCenter.services , function(d) { return d.id; })
        .enter()
        .append("div")
        .attr("id" ,  d => d.id)
        .attr('class' , styles.service)
        .style('background-color' , d => d.color)
        .style("transform", d => {
          return "translate(" + (d.x) + "px," + (d.y) + "px)";
        })
        .on('click' , (e , d) => {
          clickedService(e, d);
        })
        .append('p')
        .text(d => d.name);

        d3.select('#center').selectAll(`.${styles.service}`)
          .append('p')
          .attr('class' , styles.count)
          .text(d => d.studies.length);

      } else {
        d3.select('#center').remove();
        d3.select('#service').remove();
        closePanel();
      }
      
      d3.select('#center')
        .transition()
        .delay(selectedCity ? 250  : 10 )
        .duration(selectedCity ? 750  : 10 )
        .attr('class' , `${styles['selected-center']} ${selectedCenter ? styles.isActive : ""}` )

      d3.select(`#${mapKey}`).selectAll('g')
        .transition()
        .duration(750)
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")scale(" + k + ")translate(" + -x + "," + -y + ")")
    }

    function clickedService(e , d) {
      d3.select('#service').remove();
      d3.select('#center').remove();
      d3.select('#city').remove();
      console.log('service' , d);
      if (d && d.id ) {
        centroid = projection([selectedCity.lon, selectedCity.lat]);
        selectedService =  d;
      } else {
        selectedService = null
      } 

      if (selectedService) {
        setDisplayLegend(true);
        d3.select('#center').remove();
        d3.select(`#${mapKey}`)
          .append('div')
          .attr("id" , "service")
          .style('top', `calc(${50}% - 250px)`)
          .style('left', `calc(${50}% - 225px)`)
          .attr('class' , `${styles['selected-service']}` )
          .append('p')
          .attr('class' , `${styles.label}` )
          .text(`${selectedService.name}`);

        d3.select(`#service`)
          .append('div')
          .attr('class' , `${styles.back}` )
          .on("click" , (e) => {
            clickedCenter(e , selectedCenter);
          })
          .text(`${selectedCenter.name}`)
          .append("img")
          .attr('src' , arrowBack);

        const center = [...selectedService.studies];
        centersSimulation.nodes(center, function(d) { return d.id; })
          .force('charge', d3.forceManyBody().strength(5))
          .force('center', d3.forceCenter())
          .force('collision', d3.forceCollide().radius(function(d) {
            return 45;
          }))
          .tick(300);
          
        d3.select('#service').selectAll(`.${styles.study}`)
        .data(selectedService.studies , function(d) { return d.id; })
        .enter()
        .append("div")
        .attr("id" ,  d => d.id)
        .attr('class' , styles.study)
        .style('background-color' , d => d.color)
        .style("transform", d => "translate(" + (d.x + 190) + "px," + (d.y + 190 ) + "px)" )
        .on('click' , (e , d) => {
          getStudyAction(dispatch , d.id);
        })
        .append('p')
        .text(d => d.name);

      } else {
        d3.select('#service').remove();
        closePanel();
      }
      
      d3.select('#service')
        .transition()
        .delay(selectedService ? 250  : 10 )
        .duration(selectedService ? 750  : 10 )
        .attr('class' , `${styles['selected-service']} ${selectedService ? styles.isActive : ""}` )

    }

    // function mouseMoveEvent(event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   closeNode = foundNode(event) ? foundNode(event) : null;
    //   if (closeNode) {
    //     nodePos = projection([closeNode.lon, closeNode.lat]);
    //     mapRef.current.style.cursor = "pointer";
    //     setTooltipData({...closeNode, x: nodePos[0] - 80 , y: nodePos[1] - 70 })
    //   } else {
    //     if (mapRef.current) mapRef.current.style.cursor = selectedCity ? "zoom-out" : "auto";
    //     closeNode = null
    //     setTooltipData({ x: nodePos[0] - 80 , y: nodePos[1] - 70 })
    //   }

    // }
  
  }


  return useMemo(() => (
    <div ref={mapRef} className={styles.map} id={mapKey} style={{ height: mapHeight , width: mapWidth }}>
      <Tooltip data={tooltipData} type={"geography"}/>
    </div>
  ), [tooltipData , mapHeight , mapWidth , mapKey ]);
}
