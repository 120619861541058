import React, { useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useForm } from "react-hook-form";

import { useGlobalContext } from '../../../../context/GlobalContext';

import { postCenterAction , putCenterAction } from '../../../../actions/centersActions';


import Input from '../../../partials/Form/Input/Input';
import Button from '../../../partials/Button/Button';

import styles from './CenterPanel.module.scss';

export default function CenterPanel() {

  const [ context , dispatch ] = useGlobalContext();
  const { center } = context.centers

  const { handleSubmit, register, errors , setValue , getValues } = useForm();
  const [ newService , setNewService ] = useState();

  useEffect(() => {
    if (center.name) setValue('name', center.name );
    if (center.location?.city) setValue('location.city', center.location.city );
    if (center.location?.lat) setValue('location.lat', center.location.lat );
    if (center.location?.lon) setValue('location.lon', center.location.lon );
    // eslint-disable-next-line
  },[center])

  function updateCenter(values) {
    if (values?.location?.lat) values.location.lat = parseFloat(values.location.lat);
    if (values?.location?.lon) values.location.lon = parseFloat(values.location.lon);
    if(center?._id) {
      putCenterAction(dispatch , center._id , values)
    } else {
      postCenterAction(dispatch , values)
    }
  }

  function addService() {
    if (!newService) return;
    let services = center.services ? center.services.map(s => ({ name: s.name })) : [];
    updateCenter({ ...getValues() ,services: [...services , { name: newService }] });
    setNewService();
  };

  return (
    <div className={`${styles.container}`}>
      <form onSubmit={handleSubmit(updateCenter)}>
        <div className={styles.title}>
          <h2>{center ? center?.name : "Créer un centre"}</h2>
          <Button
            type={"submit"}
            primary
            >
            {center._id ? "Modifier le centre" : "Créer un centre"}
          </Button>
        </div>
        <Input
          label={"Nom du centre"}
          placehloder={"Nom du centre"}
          name={"name"}
          defaultValue={center?.name}
          register={register({
            required: "Compléter le nom du centre",
          })}
          withErrors={true}
          error={errors.name && errors.name.message}
        />
        <Input
          label={"Ville"}
          placehloder={"Ville"}
          name={"location.city"}
          defaultValue={center?.location?.city}
          register={register({
            required: "Compléter la ville",
          })}
          withErrors={true}
          error={errors.location && errors.location.city && errors.location.city.message}
        />

        <div className={styles.row}>

          <div className={styles['col-2']}>
            <Input
              label={"Latitude"}
              name={"location.lat"}
              onChange={(val) => setValue("location.lat", parseFloat(val.replace(',' , '.')))}
              defaultValue={center?.location?.lat}
              register={register({
                required: "Compléter la latitude",
              })}
              withErrors={true}
              error={errors.location && errors.location.lat && errors.location.lat.message}
            />
          </div>

          <div className={styles['col-2']}>
            <Input
              label={"Longitude"}
              name={"location.lon"}
              defaultValue={center?.location?.lon}
              onChange={(val) => setValue("location.lon", parseFloat(val.replace(',' , '.')))}
              register={register({
                required: "Compléter la longitude",
              })}
              withErrors={true}
              error={errors.location && errors.location.lon && errors.location.lon.message}
            />
          </div>

        </div>
        <div className={styles.services}>
          <h3>{center.services?.length} Services </h3>
            {center?.services?.map(s => 
              <div className={styles.service}>
                <p>{s.name}</p>
                <button
                  type='button'
                  onClick={() => updateCenter({ services: center.services.filter((d) => d._id !== s._id ) })}
                  >
                  <IoIosCloseCircleOutline size={30}/>
                </button>
              </div>  
            )}
          <h3>Ajouter un service</h3>
          <div className={styles.add}>
            <Input
              onChange={(val) => setNewService(val)}
              />
            <Button
              type='button'
              onClick={() => addService()}
              primary
              >
              Ajouter
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
