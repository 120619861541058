import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
import FilterList from '../Filters/partials/FilterList';

import './filters.scss';

export default function Filters({ isDisabled }) {

  const [ context, dispatch ] = useGlobalContext();

  const { optionsLists } = context.studies

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();
  const [ filters , setFilters ] = useState({
    search: "",
    drugs: [],
    centers: [],
    investigators: [],
    companies: [],
  });

  useEffect(() => {
    let list = context.studies.studiesList;
    if (list.length > 0 ) {
      if (filters.search) {
        list = list.filter(s => s.name.toLowerCase().includes(filters.search.toLowerCase()));
      }
      if (filters.drugs && filters.drugs.length) {
        list = list.filter(s => s.drugs.find((d) =>  filters.drugs.find(t => d.drug === t)));
      }
      if (filters.investigators && filters.investigators.length) {
        list = list.filter(s => s.investigators.find((d) =>  filters.investigators.find(t => d.investigator === t)));
      }
      if (filters.companies && filters.companies.length) {
        list = list.filter((s) => filters.companies.find(t => s.company === t));
      }
      if (filters.centers && filters.centers.length) {
        let studiesCentersFound = []
        list.forEach(s => {
          filters.centers.forEach(c => {
            if(s.centers.find(item =>  item.center?._id === c) && !studiesCentersFound.find(item => item._id === s._id)) {
              return studiesCentersFound.push(s);
            }
          })
        });
        list = studiesCentersFound;
      }
    }

    dispatch({
      type: 'FILTERED_CENTERS',
      payload: filters.centers ? filters.centers : []
    })

    dispatch({
      type: 'FILTERED_STUDIES',
      payload: list
    })
    // eslint-disable-next-line 
  },[filters , context.studies.studiesList])

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  function getClassNames() {
    let classNames = 'filters'
    if (isDisabled) {
      classNames += ' disabled';
      return classNames
    }
    if (filterIsOpen) {
      classNames += ' isOpen';
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className="btn-filters" onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>
          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className="btn-search"
              onClick={() => setFilters({...filters , search: search ? search : "" })}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <FilterList
              name={"Produits"}
              list={optionsLists?.drugs ? optionsLists.drugs.sort((a,b) => a.label.localeCompare(b.label)) : []}
              activeList={filters && filters.drugs ? filters.drugs : []}
              setActiveList={(val) => setFilters({...filters , drugs: val })}
              />
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <FilterList
              name={"Centres"}
              list={optionsLists?.centers ? optionsLists.centers : []}
              activeList={filters && filters.centers ? filters.centers : []}
              setActiveList={(val) => setFilters({...filters , centers: val })}
              isSearch={true}
              />
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <FilterList
              name={"Médecins"}
              list={optionsLists?.investigators ? optionsLists.investigators : []}
              activeList={filters && filters.investigators ? filters.investigators : []}
              setActiveList={(val) => setFilters({...filters , investigators: val })}
              isSearch={true}
            />
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <FilterList
              name={"Laboratoires"}
              list={optionsLists?.companies ? optionsLists.companies : []}
              activeList={filters && filters.companies ? filters.companies : []}
              setActiveList={(val) => setFilters({...filters , companies: val })}
            />
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen" : ""}`}>
          </div>
        </>
      }
    </div>
  )
}
