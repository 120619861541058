import React , { useEffect , useState , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiOutlineFileDone  , AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../../../../actions/index';

import { useGlobalContext } from '../../../../../../context/GlobalContext';

import { postFileAction , deleteFileAction , putStudyAction } from '../../../../../../actions/studiesActions';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './DocumentModale.module.scss';
import ListSelect from '../../../../../partials/Form/ListSelect/ListSelect';
import InputFile from '../../../../../partials/Form/InputFile/InputFile';

export default function DocumentModale({ files , studyId , setModaleIsActive  , shareWithDoctor }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control , watch } = useForm();

  const { shareWith , tmf } = context.studies.optionsLists;

  const { file , error , study } = context.studies;

  const [ categoriesOptions , setCategoriesOptions ] = useState([]);
  const [ typesOptions  , setTypesOptions ] = useState([]);
  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ fileId , setFileId ] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  useEffect(() => {
    clearFile();
    if (shareWithDoctor) setValue('shareWith' , ['Doctor'])
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    if (tmf && tmf.categories) {
      setFileError();
      setCategoriesOptions(tmf.categories.map(d => ({value: d , label: d.label })))
    }
  },[tmf]);

  useEffect(() => {
    register("category");
    register('shareWith');
    register('file');
  }, [register]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (file && file._id) {
      setValue('file' , file._id );
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = "";
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        setFileId();
        dispatch({
          type : 'ERROR_STUDIES',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });

    setUploadedFile(fileData);
    setFileError();
    setFileId();

    setValue('title' , fileData.name )

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `document ${study.name}`);
    formData.append("alt", `${study.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }


  function handleChangeCategory(opt) {
    setValue('category' , opt.label);
    setValue('type' , null );
    console.log(opt)
    if (opt.value.types) {
      setTypesOptions([...opt.value.types.map(d => ({value: d.label , label: d.label }))])
    }
  }

  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    setFileId();
  }

  function closeModale() {
    setModaleIsActive(false);
    if (fileId) deleteFile(fileId);
  }

  function submitForm(values) {
    let updatedList = files;
    if (file._id) {
      values.file = file._id ;
    }
    updatedList.push(values);

    submitFiles(updatedList);
  }

  function submitFiles(files) {
    putStudyAction(dispatch , studyId , { files: files } );
    setModaleIsActive(false);
  }

  function renderCreate() {
    return (
        <>
          <h2>Ajouter un document</h2>
          <form onSubmit={handleSubmit(submitForm)}>

            <Controller
              name="category"
              rules={{ required: "la categorie est requise" }}
              control={control}
              render={(props) =>
                <CustomSelect
                  label={"Catégorie du document"}
                  defaultValue={null}
                  handleChange={(opt) => handleChangeCategory(opt)}
                  options={categoriesOptions}
                  withErrors={true}
                  error={errors.category && errors.category.message}
                  />
              }
              defaultValue={null}
            />

            {typesOptions &&
              <Controller
                name="type"
                // rules={{ required: "le type est requis" }}
                control={control}
                render={(props) =>
                  <CustomSelect
                    label={"Type du document"}
                    value={typesOptions.find((t) => t.value === watch('type')) ? typesOptions.find((t) => t.value === watch('type')) : null }
                    handleChange={(opt) => setValue('type' , opt.value )}
                    options={typesOptions}
                    withErrors={true}
                    error={errors.type && errors.type.message}
                    />
                }
                defaultValue={null}
              />
            }

            <Input
              label={"Sous-type"}
              placehloder={"Sous-type"}
              name={"subtype"}
              register={register}
              withErrors={true}
            />

            <Input
              label={"Nom du document"}
              placehloder={"nom"}
              name={"title"}
              register={register({
                required: "Nom du document",
              })}
              withErrors={true}
              error={errors.title && errors.title.message}
            />

            <Controller
              name="shareWith"
              rules={{ required: "Les droits sont requis" }}
              control={control}
              defaultValue={shareWithDoctor ? shareWith.filter(d => d.value === 'Doctor') : null }
              render={(props) =>
                <ListSelect
                  label="Droits"
                  options={shareWith}
                  defaultValue={shareWithDoctor ? shareWith.filter(d => d.value === 'Doctor') : null }
                  withErrors={true}
                  onChange={(val) => setValue( 'shareWith' , val.map(d => d.value ))}
                  error={errors.shareWith && errors.shareWith.message}
                  />
              }
            />

            <label>Fichier</label>
            {file && file._id  &&
              <div className={styles.file}>
                <a href={`${API_URL}/${file.path}`} rel="noreferrer" target="_blank">
                  <div className={styles.link}>
                    <AiOutlineFileDone size={20} color={'#FFFFFF'} />
                    <p>{file.name}</p>
                  </div>
               </a>
               <button
                type="button"
                onClick={() => deleteFile(fileId)}
                className={`${styles.delete}`}>
                <AiFillDelete size={20} color={'red'} />
                <p>supprimer</p>
              </button>
              </div>
            }
            {!fileId &&
              <Controller
                name="file"
                rules={{ required: "Le ficher est requis" }}
                control={control}
                defaultValue={null}
                render={(props) =>
                  <InputFile
                    name="file"
                    isLoading={uploadedFile}
                    withErrors={true}
                    defaultValue={null}
                    onChange={(files) => handleChangeFile(files[0])}
                    error={fileError}
                    />}
              />
            }
      

            <div className="modale-actions center">
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
              <Button
                type={"button"}
                primary
                outline
                onClick={() => closeModale()}
                >
                Annuler
              </Button>
            </div>
          </form>
      </>
    )
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      {renderCreate()}
    </div>
  )
}
