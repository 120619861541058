import React , { useState } from 'react';
import { format } from 'date-fns';
import { AiOutlineMinus , AiOutlinePlus } from "react-icons/ai";

export default function NewsRow({ styles , letter , isPreview , setEditLetter , sendNewsLetter, updatedPanel }) {

  const [ textIsOpen , setTextIsOpen ] = useState(false);

  function textToggle() {
    setTextIsOpen(!textIsOpen);
    updatedPanel();
  }

  return (
    <>
    <div className={styles.row}>
      <div className={`${styles.col}`}>

        {isPreview ?
          <span className="bold">
            titre
          </span>
          :
          <>
            <button
              className={styles.icon}
              onClick={() => textToggle()}
              >
              { textIsOpen ?  <AiOutlineMinus size={22} color={"#192243"}/> : <AiOutlinePlus  size={22} color={"#192243"}/>}
            </button>
            <button
              onClick={() => setEditLetter(letter)}
              className={`btn-edit-row ${textIsOpen ? styles.isActive : ''}`}>
              {letter.title}
            </button>
          </>
        }                
      </div>
      <div className={`${styles.col} text-grey"`}>
        {format(new Date(letter.date) , "dd/MM/yyyy HH:mm")}
      </div>
      <div className={`${styles.col} ${styles.submit} text-grey"`}>
        {!isPreview
          && <button
          onClick={() => sendNewsLetter()}
          className={`btn-edit-row ${textIsOpen ? styles.isActive : ''}`}>
          Envoyer la newsletter
        </button>
        }
      </div>

    </div>
    {textIsOpen && 
      <div className={styles.text}>

        {letter.description &&
          <>
            <h4>Desciption</h4>
            <p>
              {letter.description}
            </p>
          </>
        }
      </div>
    }
    </>
  )
}
