import axios from  'axios';

export const API_URL = "https://api.octapharma.bakasable.io/api";
export const TOOL_ID = '5fd47fbd02f9ea3479cda316';
function authHeader() {
  let token = localStorage.getItem('token');
  return {
      headers: {
        Authorization: token
      }
  };
};

export function getData(errorType , url , dispatch , isAuthReq = false ) {
  return new Promise((resolve ,reject) => {

    const requestUrl = API_URL + url ;

    axios.get(requestUrl , isAuthReq ? authHeader() : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch(( error ) => {
        reject()
        errorHandler(dispatch , error , errorType );
      }); 

  });
};

export function deleteData(errorType, url, dispatch, isAuthReq = true ) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    axios.delete(requestUrl, isAuthReq ? authHeader() : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function putData(action, errorType,  url, dispatch, data , isAuthReq = true ) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    axios.put(requestUrl, data, isAuthReq ? authHeader() : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
}

export function postData(action, errorType,  url, dispatch, data , isAuthReq = false) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;

    var config = {
      timeout: 1000 * 60
    };

    let token = localStorage.getItem('token');

    if (isAuthReq) {
      config = {
        headers: {
           Authorization: token
         }
      }
    }

    axios.post(requestUrl, data, config)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })
};

export function getFile(errorType, url, dispatch, isAuthReq = false) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let token = localStorage.getItem('token');

    let config = {
      responseType: 'blob',
      headers: {
        Authorization: token
      }
    };

    axios.get(requestUrl, isAuthReq ? config : null )
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject()
      errorHandler(dispatch, error , errorType);
    });

  });

}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error , error.response)
  if (error.response  && error.response.status === 500 ) {
    // dispatch({
    //   type: 'SET_TOASTER',
    //   payload: {
    //     message: "une erreur du serveur interne s'est produite",
    //     type: "error"
    //   }
    // });
  } else if(error.response  && error.response.status === 401 ){

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token')
    }
    dispatch({
      type: 'IS_AUTH',
      payload: false,
    });
    dispatch({
      type: 'ERROR_AUTH',
      payload: error.response.status,
    });
    if (type === 'ERROR_LOGIN') {
      dispatch({
        type: type ,
        payload: error.response.status,
      });
    }
  } else {
    dispatch({
      type: type,
      payload: error.response,
    });
  }
}