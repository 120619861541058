import React from 'react';
import styles from './PageMobile.module.scss';
import logo from '../../../assets/images/logo.svg';

export default function PageMobile() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt="logo-clinocta"/>
        </div>

        <div className={styles.message}>
          {/* <AiOutlineDesktop size={30} color={'#999EB0'} />  */}
          <p>Application uniquement disponible sur desktop</p>
        </div>

      </div>
    </div>
  )
}
