import React , { useState , useEffect , useRef } from 'react';
import { format , differenceInCalendarDays } from 'date-fns';
import styles from './TimeLine.module.scss';
import Step from './Step/Step';

export default function TimeLine({ steps  , planningOptions , onClick }) {

  const months = [
    "jan",
    "fév",
    "mar",
    "avr",
    "mai",
    "juin",
    "juil",
    "aôut",
    "sept",
    "oct",
    "nov",
    "déc"
  ];
  
  const timelineRef = useRef();
  const [ grid , setGrid ] = useState();
  const [ dayWidth , setDayWidth ] = useState();
  const [ monthWidth  , setMonthWidth ] = useState(100);
  const [ initialDay , setInitialDay ] = useState();
  const stepHeight = 45 ;
  
  useEffect(() => {
    if (steps.length) {
      let dateList = [];
      let firstDate = new Date();
      let lastDate = null
      let init = new Date(steps[0].startDate).setDate(1);
      setInitialDay(init);

      steps.forEach(item => {
        if (new Date(item.startDate) < firstDate ) {
           firstDate = new Date(new Date(item.startDate).setDate(1))
        }
        if (!lastDate ) lastDate = new Date(item.endDate);
        if (lastDate && new Date(item.endDate) > lastDate ) lastDate = new Date(item.endDate);
      });

      do {
        dateList.push({ year: format(new Date(firstDate) , "yyyy") , month: months[new Date(firstDate).getMonth()] });
        firstDate = new Date(firstDate.setMonth(firstDate.getMonth()+1));
      } while (firstDate < lastDate );

      let totalDays = differenceInCalendarDays( firstDate , init );
      let monthSize = timelineRef.current.clientWidth / 6;
      setDayWidth(dateList.length * monthSize / totalDays );
      setMonthWidth(monthSize)
      if (dateList.length >= 1 ) setGrid(dateList);
    }
    // eslint-disable-next-line
  }, [steps])

  return (
    <div className={styles.container} ref={timelineRef}>
      <div className={styles.timeline}>
        {grid && 
          <div style={{ height: stepHeight * (steps.length + 2 ) , width: monthWidth * grid.length }}>
            {steps  && steps.map((step , i ) => 
              <Step
                key={'step' + i }
                dayWidth={dayWidth}
                stepHeight={stepHeight}
                initialDay={initialDay}
                onClick={onClick}
                color={planningOptions && planningOptions.find(s => s.value === step.name)? planningOptions.find(s => s.value === step.name).color : '#4F21B1' }
                step={step}
                index={i} />
              
            )}
            <ul className={styles.grid} style={{ height: stepHeight * (steps.length + 2 ) , transform: `translateY(-${stepHeight * (steps.length + 1 )}px)`}}>
              {grid.map((item , i ) => 
                <li key={'month' + i } style={{ height: '100%', maxWidth: monthWidth , width: monthWidth }}><p>{item.month} {item.year}</p></li>
              )}
            </ul>
          </div>
        }
      </div>

    </div>
  )
}
