import React, { useState , useEffect }  from 'react';
import styles from './Input.module.scss';

export default function Input({ onChange , name , label , defaultValue , value , step , withErrors , error , register , placehloder , type , ...rest }) {
  const [ inputValue , setInputValue ] = useState();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  function handleChange(val) {
    if (onChange) onChange(val);
    setInputValue(val);
  }

  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      {!register ? 
        <>
          <input
            name={name}
            type={type}
            placeholder={placehloder}
            value={inputValue ? inputValue  : ''}
            onChange={(e) => handleChange(e.target.value)}
            />
        </>
        :
        <>
          <input
            name={name}
            type={type}
            step={type === "number" ? step ? step : "0.01" : null}
            className={error ? styles.error : ''}
            placeholder={placehloder}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue ? defaultValue : ''}
            ref={register}
            {...rest}
            />
        </>
      }
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  )
}
