import React , { useEffect , useState } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../../../../context/GlobalContext';

import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';
import CheckBox from '../../../../../partials/Form/CheckBox/CheckBox';

import styles from './CentersModale.module.scss';

export default function CentersModale({ centers , submitCenters , editCenter , deleteCenter }) {


  const context = useGlobalContext()[0];

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const [ centersOptions , setCentersOptions ] = useState();
  const [ selectedCenter , setSelectedCenter ] = useState();

  const phasesOctapharma = context.studies.optionsLists?.phasesOctapharma

  useEffect(() => {
    if (context.centers.centersList.length) {
      let options = []
      context.centers?.centersList.forEach(c => {
        options.push({
          label: `${c.name} - ${c?.location?.city}`,
          value: { 
            center: c._id,
            service: null
          }
        });
        c.services?.forEach((s) => {
          options.push({
            label:`${c.name} - ${s.name} - ${c?.location?.city}`,
            value: { 
              center: c._id,
              service: s._id
            }
          });
        })
      })
      setCentersOptions(options);
    }
    // eslint-disable-next-line
  }, [context.centers.centersList])

  useEffect(() => {
    if(editCenter?.phase) {
      setValue('phase' , editCenter?.phase)
    }
    // eslint-disable-next-line
  }, [editCenter?.phase])

  useEffect(() => {
    if (editCenter) {
      register("phase");
      register("contrat");
      register("open");
      register("feasibility");
    }

    // eslint-disable-next-line
  }, [register]);

  function addCenter() {

    submitCenters([...centers , selectedCenter])
  }

  function updateCenter(values) {
    let updatedList = centers.filter(c => c._id !== editCenter._id );
    let updated = {...editCenter , ...values} 
    updated.center = editCenter.center
    updatedList.push(updated)
    submitCenters(updatedList)
  }


  function renderCreate() {
    return (
      <>
        <h2>Ajouter un centre</h2>
        <form onSubmit={handleSubmit(addCenter)}>
          <Controller
            name="center"
            control={control}
            render={(props) =>
              <CustomSelect
                label={"Nom du centre / service"}
                defaultValue={null}
                handleChange={(opt) => setSelectedCenter(opt.value)}
                options={centersOptions}
                withErrors={true}
                />
            }
            defaultValue={null}
          />
          <div className="modale-actions center">
            {selectedCenter &&
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
              }
          </div>
        </form>
      </>
  )
  }

  function renderEdit() {
    return (
      <>
        <h2>{editCenter?.center?.name}</h2>
        <form onSubmit={handleSubmit(updateCenter)}>
          <Controller
            name="phase"
            rules={{ required: "la phase est requise" }}
            control={control}
            defaultValue={editCenter?.phase ? editCenter.phase : null }
            render={(props) =>
              <CustomSelect
                label={"Phase"}
                defaultValue={editCenter.phase && phasesOctapharma.find(p => p.value === editCenter.phase)}
                handleChange={(opt) => setValue('phase' , opt.value)}
                options={phasesOctapharma}
                withErrors={true}
                error={errors.phase && errors.phase.message}
                />
            }
          />

          <div className={`row ${styles.check}`}>
            
            <Controller
              name="cpp"
              control={control}
              defaultValue={editCenter.cpp ? true : false}
              render={props => <CheckBox
                defaultValue={editCenter.cpp ? true : false}
                onChange={(val) => setValue('cpp' , val )}
                label={"CPP"}/> 
              }
            />
            <Controller
              name="feasibility"
              control={control}
              defaultValue={editCenter.feasibility ? true : false}
              render={props => <CheckBox
                defaultValue={editCenter.feasibility ? true : false}
                onChange={(val) => setValue('feasibility' , val )}
                label={"Faisabilité"}/> 
              }
            />
            <Controller
              name="contrat"
              control={control}
              defaultValue={editCenter.contrat ? true : false}
              render={props => <CheckBox
                defaultValue={editCenter.contrat ? true : false}
                onChange={(val) => setValue('contrat' , val )}
                label={"Contrat"}/> 
              }
            />
            <Controller
              name="open"
              control={control}
              defaultValue={editCenter.open ? true : false}
              render={props => <CheckBox
                defaultValue={editCenter.open ? true : false}
                onChange={(val) => setValue('open' , val )}
                label={"Mep"}/> 
              }
            />
            <Controller
              name="closed"
              control={control}
              defaultValue={editCenter.closed ? true : false}
              render={props => <CheckBox
                defaultValue={editCenter.closed ? true : false}
                onChange={(val) => setValue('closed' , val )}
                label={"Clôture"}/> 
              }
            />
          </div>

          <div className="modale-actions start">
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
            <button
              type={"button"}
              onClick={() => deleteCenter(editCenter._id)}
              className={"btn-delete"}
              >
              <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
            </button>
          </div>
        </form>
      </>
    )
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      {editCenter ? renderEdit() : renderCreate() }
    </div>
  )
}
