import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";

import { useGlobalContext } from '../../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../../actions/studiesActions';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';
import CustomSelect from "../../../../../partials/Form/Select/CustomSelect";
import Textarea from '../../../../../partials/Form/Textarea/TextArea';

import styles from './ResumeModale.module.scss';

export default function ResumeModale({ study , isCro }) {

  const [ context , dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const { optionsLists } = context.studies;

  useEffect(() => {
    register("type");
    register("coordinator");
  }, [register]);

  const submitForm = (values)  => {
    if (values.type.label) values.type = values.type.value;
    if (values.budget) values.budget = parseFloat(values.budget)
    putStudyAction(dispatch , study._id , values );
  };

  return (
    <div className={`modale-content ${styles.container}`}>

      <h2>Résumé</h2>

      <form onSubmit={handleSubmit(submitForm)}>

        <Input
          label={"Titre"}
          placehloder={"titre de l'étude"}
          name={"name"}
          register={register({
            required: "Compléter le titre",
          })}
          defaultValue={study.name}
          withErrors={true}
          error={errors.name && errors.name.message}
        />

        <Controller
          name="type"
          rules={{ required: "le type est requis" }}
          control={control}
          defaultValue={optionsLists.studyTypes.find(item => item.value === study.type)}
          render={(props) =>
            <CustomSelect
              label={"Type"}
              defaultValue={optionsLists.studyTypes.find(item => item.value === study.type)}
              handleChange={(opt) => setValue('type' , opt.value)}
              options={optionsLists && optionsLists.studyTypes}
              withErrors={true}
              error={errors.type && errors.type.message}
              />
          }
        />

        <Controller
          name="coordinator"
          rules={{ required: "le coordinateur est requis" }}
          control={control}
          defaultValue={optionsLists?.coordinators?.find(item => item.value === study?.coordinator?._id)?.value}
          render={(props) =>
            <CustomSelect
              label={"Coordinateur"}
              defaultValue={optionsLists.coordinators.find(item => item.value === study?.coordinator?._id)}
              handleChange={(opt) => setValue('coordinator' , opt.value)}
              options={optionsLists && optionsLists.coordinators}
              withErrors={true}
              error={errors.coordinator && errors.coordinator.message}
              />
          }
        />

        <Controller
          name="company"
          rules={{ required: "le laboratoire est requis" }}
          control={control}
          defaultValue={optionsLists?.companies?.find(item => item.value === study?.company)?.value}
          render={(props) =>
            <CustomSelect
              label={"Laboratoire"}
              defaultValue={optionsLists.companies.find(item => item.value === study?.company)}
              handleChange={(opt) => setValue('company' , opt.value)}
              options={optionsLists && optionsLists.companies.filter((c) => c.label)}
              withErrors={true}
              error={errors.company && errors.company.message}
              />
          }
        />

       {!isCro && 
          <div className="row">

            <div className={styles['col-2']}>
              <Input
                label={"Budget"}
                placehloder={"ex 10 000"}
                type="number"
                name={"budget"}
                onChange={(val) => setValue('budget' , parseFloat(val))}
                register={register({
                  required: "Compléter le budget",
                })}
                defaultValue={study.budget ? study.budget : null }
                withErrors={true}
                error={errors.budget && errors.budget.message}
              />
            </div>
            
            <div className={styles['col-2']}>
              <Input
                label={"Devise"}
                placehloder={"EUR"}
                name={"budjectCurrency"}
                register={register}
                defaultValue={study.budjectCurrency ? study.budjectCurrency  : "EUR" }
                withErrors={true}
              />
            </div>
            
          </div>
        }

        <Textarea
          label={"Description"}
          placehloder={"description"}
          name={"abstract"}
          onChange={(val) => setValue('abstract' , parseFloat(val))}
          register={register}
          defaultValue={study.abstract ? study.abstract : '' }
        />


        <div className="modale-actions center">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
        </div>
  
      </form>
    </div>
  )
}
