import React , { useEffect , useState , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiOutlineFileDone  , AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../../../../actions/index';

import { useGlobalContext } from '../../../../../../context/GlobalContext';

import { postFileAction , deleteFileAction, putFolderAction } from '../../../../../../actions/studiesActions';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';

import styles from './AddFile.module.scss';
import ListSelect from '../../../../../partials/Form/ListSelect/ListSelect';
import InputFile from '../../../../../partials/Form/InputFile/InputFile';

export default function AddFile({ folder , backToFolder , setActiveFolder, shareWithDoctor }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const { shareWith } = context.studies.optionsLists;

  const { file , error , study } = context.studies;

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ fileId , setFileId ] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  useEffect(() => {
    clearFile();
    if (shareWithDoctor) setValue('shareWith' , ['Doctor'])
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    register('shareWith');
    register('file');
  }, [register]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (file && file._id) {
      setValue('file' , file._id );
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = "";
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        setFileId();
        dispatch({
          type : 'ERROR_STUDIES',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });

    setUploadedFile(fileData);
    setFileError();
    setFileId();

    setValue('title' , fileData.name )

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", fileData.name );
    formData.append("description", `document ${study.name}`);
    formData.append("alt", `${study.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }

  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    setFileId();
  }

  function back() {
    backToFolder(false);
    if (fileId) deleteFile(fileId);
  }

  async function submitForm(values) {
    let files = [...folder.files];
    if (file._id) {
      values.file = file._id ;
    }
    files.push(values);
    const updatedFolder = await putFolderAction(dispatch, folder._id, {...folder, files})
    setActiveFolder(updatedFolder);
    backToFolder(false);
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(submitForm)}>
        <h2>Ajouter un document</h2>

        <label>Fichier</label>
        {file && file._id  &&
          <div className={styles.file}>
            <a href={`${API_URL}/${file.path}`} rel="noreferrer" target="_blank">
              <div className={styles.link}>
                <AiOutlineFileDone size={20} color={'#FFFFFF'} />
                <p>{file.name}</p>
              </div>
            </a>
            <button
            type="button"
            onClick={() => deleteFile(fileId)}
            className={`${styles.delete}`}>
            <AiFillDelete size={20} color={'red'} />
            <p>supprimer</p>
          </button>
          </div>
        }
        {!fileId &&
          <Controller
            name="file"
            rules={{ required: "Le ficher est requis" }}
            control={control}
            defaultValue={null}
            render={(props) =>
              <InputFile
                name="file"
                isLoading={uploadedFile}
                withErrors={true}
                defaultValue={null}
                onChange={(files) => handleChangeFile(files[0])}
                error={fileError}
                />}
          />
        }
        <Input
          label={"Nom du document"}
          placehloder={"nom"}
          name={"title"}
          register={register({
            required: "Nom du document",
          })}
          withErrors={true}
          error={errors.title && errors.title.message}
        />

        <Controller
          name="shareWith"
          rules={{ required: "Les droits sont requis" }}
          control={control}
          defaultValue={shareWithDoctor ? shareWith.filter(d => d.value === 'Doctor') : null }
          render={(props) =>
            <ListSelect
              label="Droits"
              options={shareWith}
              defaultValue={shareWithDoctor ? shareWith.filter(d => d.value === 'Doctor') : null }
              withErrors={true}
              onChange={(val) => setValue( 'shareWith' , val.map(d => d.value ))}
              error={errors.shareWith && errors.shareWith.message}
              />
          }
        />

        <div className="modale-actions center">
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          <Button
            type={"button"}
            primary
            outline
            onClick={() => back()}
            >
            Annuler
          </Button>
        </div>
      </form>
    </div>
  )
}
