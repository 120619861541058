import React , { useCallback , useEffect } from 'react';

import styles from './Legal.module.scss';

export default function DataPolicy({ setActivePage }) {

  const initData = useCallback(() => {
    setActivePage();
  }, [setActivePage]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <div className={`page-container ${styles.container}`}>
      <div className={styles.content}>
        <h1>Politique de confidentialité des données</h1>

        <p className={styles.intro}>
          La Société Octapharma France SAS (dénommée ci-après « Octapharma France» ou « Nous ») respecte le droit des individus concernant leurs données personnelles.
          La présente politique de confidentialité des données personnelles (« Politique ») décrit la manière dont nous traitons vos informations personnelles lorsque vous accédez à notre site web (ci-après le Site).
        </p>

        <h3>1) Données personnelles</h3>

        <p>
          Il s’agit de toute information se rapportant à une personne physique, identifiée ou identifiable par Octapharma France et ce directement ou indirectement.
        </p>
        
        <p>Ces données peuvent être :</p>

        <ul>
          <li>Nom</li>
          <li>Adresse électronique</li>
          <li>Pays de résidence</li>
          <li>Adresse postale</li>
          <li>Numéro de téléphone</li>
          <li>Profession (par exemple, si vous êtes un professionnel de la santé à la recherche d’informations sur nos produits)</li>
          <li>
            Informations d’identification techniques de votre appareil de connexion électronique (par exemple adresse IP, type d’appareil, type et version du navigateur, et autres informations standard techniques)
            Autres données personnelles que vous choisissez de nous fournir
          </li>
        </ul>

        <p>
          Pour une meilleure gestion de notre Site, nous pourrions enregistrer des données et classer les visites par élément tels que le nom du domaine, le type et la version du navigateur, les pages visitées dans le site d’Octapharma France. Cela nous permet d’améliorer de manière continue notre site. Ces informations sont uniquement traitées de manière collective et les données personnelles sont donc pseudonymisées.
        </p>

        <h3>2) Finalités et bases légales de la collecte des données personnelles</h3>

        <p>
          Octapharma France met en œuvre des traitements de données à caractère personnel sur la base légale de son intérêt légitime et des obligations légales de pharmacovigilance.
        </p>

        <p>
          Octapharma France pourra traiter les données personnelles afin de réaliser nos activités opérationnelles :
        </p>

        <ul>
          <li>Notamment exécuter nos contrats avec nos clients et fournisseurs,</li>
          <li>Dans le cadre d’activités marketing et de communication,</li>
          <li>Répondre à vos demandes d’information médicale ou de pharmacovigilance.</li>
          <li>Assurer nos missions administratives : gestion du personnel, recouvrement client et procéder aux paiements que nous pourrions avoir à émettre.</li>
          <li>Et toute autre finalité imposée par nos politiques internes ainsi que la loi et les autorités, tel que les obligations de pharmacovigilance.</li>
        </ul>

        <table>
          <thead>
            <tr>
              <th>Utilisation de vos données à caractère personnel</th>
              <th>Base juridique sur laquelle repose le traitement de vos données à caractère personnel</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Exécuter nos contrats avec nos clients et nos fournisseurs</td>
              <td>Intérêt légitime</td>
            </tr>
            <tr>
              <td>Activités marketing et de communication</td>
              <td>Intérêt légitime / consentement</td>
            </tr>
            <tr>
              <td>Répondre à vos demandes d’informations notamment médicales ou de pharmacovigilance</td>
              <td>Intérêt légitime / Obligations légales</td>
            </tr>
            <tr>
              <td>Assurer nos missions administratives : gestion du personnel, recouvrement client, procéder aux paiements</td>
              <td>Exécution d’un contrat</td>
            </tr>
            <tr>
              <td>Finalités imposées par nos politiques internes ainsi que la loi et les autorités</td>
              <td>Obligations légales</td>
            </tr>
          </tbody>
        </table>

        <p>
          Octapharma France obtient les données à caractère personnel uniquement par des moyens légaux et loyaux, le cas échéant, à la connaissance et avec le consentement de la personne concernée. Lorsqu’il est nécessaire de demander et d’obtenir le consentement d’un individu avant de procéder à la collecte, à l’utilisation ou à la divulgation de ses données à caractère personnel, Octapharma France s’engage à obtenir ledit consentement.
        </p>

        <p>
          Octapharma France traite les données à caractère personnel conformément au droit et aux obligations contractuelles applicables. Les données traitées sont destinées aux personnels habilités d’Octapharma ainsi qu’à ses prestataires.
        </p>

        <p>
          Les informations ne seront divulguées aux tierces parties que lorsqu’il s’agit d’un fournisseur ou d’un prestataire impliqué dans la réponse à une demande d’information faite par un individu. Les informations seront également divulguées lorsque le fournisseur ou l’entrepreneur est impliqué dans l’amélioration et la gestion du site web d’Octapharma France.
        </p>

        <h3>3) Droits des personnes concernées</h3>

        <p>
          Vous pouvez accéder à toutes vos données personnelles que nous recueillons en ligne sur simple demande. Vous pouvez également exiger la suppression de vos données personnelles à moins que la loi et les règlements en vigueur nous obligent à les garder.
        </p>

        <p>
          Vous pouvez nous demander de vous fournir des informations sur vos données que nous traitons. De plus, vous disposez d’un droit d’opposition ou d’effacement de toute information personnelle ainsi que de limitation du traitement de vos données, de rectification de vos données erronées et le droit à la portabilité de vos données à une partie tierce.
        </p>

        <p>
          Vous avez également le droit d’introduire une réclamation auprès de l’autorité compétente (CNIL)
        </p>

        <h3>4) Durées de conservation des données personnelles</h3>

        <p>
          Octapharma France conserve vos données personnelles pour la durée nécessaire à la réalisation des finalités décrites dans la présente politique. Par dérogation, Octapharma France peut être amenée à conserver vos données personnelles pour une durée supérieure, conformément à ce qui est autorisé ou prescrit par le droit applicable ou dans la mesure où ce serait nécessaire à la protection de ses droits et intérêts.
        </p>

        <h3>5) Mesures de sécurité quant aux données personnelles</h3>

        <p>
          Octapharma France met en œuvre un ensemble de procédures et de mesures organisationnelles et techniques pour assurer l’intégrité et la confidentialité de vos données personnelles et les protéger contre les accès, utilisations et divulgations non-autorisées.
        </p>

        <p>
          Ces mesures prennent en compte l’état de l’art, les coûts de mise en œuvre ainsi que la nature, le périmètre, le contexte et les finalités du traitement de données ainsi que le risque et le danger pour les droits et libertés des individus concernés.
        </p>

        <p>
          Nous sommes amenés à anonymiser (voire pseudonymiser pour certaines d’entre elles), agréger vos données personnelles afin de nous assurer qu’aucune information permettant de vous identifier n’est communiquée à des tiers.
        </p>

        <p>
          Les serveurs sur lesquels sont conservés vos données personnelles / données sont protégés par divers types de contrôle d’accès techniques ou physiques.
        </p>

        <h3>6) Transferts de données personnelles</h3>

        <p>
          Par ailleurs, Octapharma France peut interagir avec des sociétés basées partout dans le monde. Ainsi, Octapharma France peut être amenée à transférer certaines de vos données personnelles en dehors de l’Union européenne.
        </p>

        <p>
          Au sein du groupe Octapharma : tout importateur de données protège ces données par tous les moyens et respecte la législation sur la confidentialité des données du pays dont elles sont originaires.
        </p>

        <p>
          À des tiers : nos accords contractuels obligent nos partenaires :
        </p>

        <ul>
          <li>
            à protéger les données à caractère personnel contre toute divulgation ultérieure et à ne les traiter que conformément aux instructions d’Octapharma et aux règles légales en vigueur
          </li>
          <li>
            à mettre en place les mesures techniques et opérationnelles appropriées pour protéger les données à caractère personnel ainsi que des mesures destinées à notifier toute violation des données à caractère personnel.
          </li>
        </ul>

        <h3>7) Contact</h3>

        <p>
        Octapharma France sera heureux de répondre à vos questions s’agissant de sa politique et/ou de sa mise en œuvre. Vos questions ou commentaires doivent être adressés à notre Délégué à la protection des données à l’adresse suivante :
        <a href="mailto:fr2DPO@octapharma.com" >fr2DPO@octapharma.com</a>.
        </p>

        <p>
          Cette politique peut être mise à jour ou amendée à tout moment. La politique modifiée sera publiée sur le site.
        </p>

        <div className={styles.end}>

        </div>

      </div>
    </div>
  )
}
