import React from 'react';
import { MdClose } from 'react-icons/md';

import styles from './Modale.module.scss';

export default function Modale( { isOpen , modaleToggle , className , hideClose , children, isPreview } ) {
  return (
    <div className={`${styles['modale-container']} ${isOpen? styles['is-active'] : ""} ${isPreview ? styles.preview : ''}`} >
      <div className={`${className ? className :''} ${styles['modale']} ${isOpen? styles['is-active'] : ""}`}>
        {!hideClose && <button
            onClick={() => modaleToggle(false)}
            className={styles['close']}>
            <MdClose size={20} color={'#656565'} /> 
          </button>
        }
        {children}
      </div>
    </div>
  )
}