import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { format } from 'date-fns';

import Button from '../../../../../partials/Button/Button';
import Modale from '../../../../../partials/Modale/Modale';
import DocumentModale from '../DocumentModale/DocumentModale';

import { getFileAction } from '../../../../../../actions/studiesActions';
import { useGlobalContext } from '../../../../../../context/GlobalContext';

import styles from './Amendements.module.scss';

export default function Amendements({ study , id , setActiveSection , isDoctor , isPreview }) {

  const dispatch = useGlobalContext()[1];

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ sharedFiles , setSharedFiles ] = useState([]);

  useEffect(() => {
    if (study && study.files) {
      let shared  = study.files.filter(d => d.shareWith.find(a => a === "Doctor"));
      setSharedFiles(shared)
    }
  },[study]);

  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
        
        <div className="header">
          <div className="title">
            <h3>{isDoctor ? "Documents accessibles " : "Liste de documents partagés avec les medecins " }<span>({sharedFiles.length})</span></h3>
            {sharedFiles && sharedFiles.length > 0 &&
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un document
            </Button>
          }
          </div>
        </div>

        {sharedFiles && sharedFiles.length > 0 &&
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef}>
              <div className={styles.list}>
              {sharedFiles && sharedFiles.map((doc , i ) => 
                <div key={`shared-${i}`} className={styles.row}>
                  <button
                    onClick={() => getFileAction(dispatch , doc.file._id)} 
                    className={`${styles.col} ${isPreview ? styles['is-preview'] : ''} btn-edit-row`}>
                    {doc.title && doc.title}
                  </button>
                  <div className={`${styles.col} ${styles.date}`}>
                    {doc.file.updatedAt && format(new Date(doc.file.updatedAt) , "dd/MM/yyyy hh:mm")}
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        }
      </section>
        <Modale
          isOpen={modaleIsActive}
          modaleToggle={setModaleIsActive}
          hideClose={true}
          >
          {modaleIsActive && <DocumentModale
            setModaleIsActive={setModaleIsActive}
            shareWithDoctor={true}
            files={study.files}
            studyId={study._id}
            />}
        </Modale>
    </>
  )
}
