import React, { useEffect, useState } from 'react';
import { AiOutlineFolder, AiOutlineFile, AiFillEdit } from 'react-icons/ai';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi"; 
import { deleteFileAction, deleteFolderAction, getFileAction, putFolderAction } from '../../../../../../actions/studiesActions';
import { useGlobalContext } from '../../../../../../context/GlobalContext';
import AddFile from '../AddFile/AddFile';
import EditFolder from '../EditFolder/EditFolder';
import pointSvg from './points.svg';

import styles from './FolderModale.module.scss';

const FolderRow = ({ setActiveFolder, folder, handleEditFolder, folders , isPreview }) => {
  const dispatch = useGlobalContext()[1];
  const [ isMoveTo, setIsMoveTo ] = useState(false);

  async function handleChangeParent(parent) {
    const data = {...folder, parent}
    await putFolderAction(dispatch, folder._id , data);
  }

  return (
    <div
      className={styles.row}
      >
      <div
        className={styles.name}
        onClick={() => setActiveFolder(folder)}
        >
          <AiOutlineFolder />{folder.name}
      </div>
      {isMoveTo && <div className={styles.move}
        onMouseLeave={() => setIsMoveTo(false)}
      >
        <div className={styles.label}>
          Déplacer vers
        </div>
        <div className={styles.folders}>
          {folders?.filter((f) => f.parent ===  folder.parent || f._id !== folder._id)
            .map((f) => (
            <button
              onClick={() => handleChangeParent(f._id)} 
            >
              <AiOutlineFolder />{f.name}
            </button>
          ))}
        </div>
      </div>}
      {!isPreview &&
        <div className={styles.options}>
          <img src={pointSvg} alt="option" />
          <div className={styles.select}>
            <button
              onClick={() => setIsMoveTo(true)}
            >
              Déplacer vers
            </button>
            <button
              onClick={() => handleEditFolder()}
            >
              Editer
            </button>
            <button
              onClick={() => deleteFolderAction(dispatch, folder._id, folder.study)}
            >
              Supprimer
            </button>
          </div>
        </div>
      }   
    </div>
  );
};

const FileRow = ({ file , folder, handleChangeFolder, folders, isPreview }) => {
  const dispatch = useGlobalContext()[1];

  async function deleteFile() {
    await deleteFileAction(dispatch, file.file);
    await handleChangeFolder({...folder, files: folder.files.filter((f) => f._id !== file._id)});
  };
  const [ isMoveTo, setIsMoveTo ] = useState(false);
  async function handleChangeParent(newFolder) {
    await handleChangeFolder({...folder, files: folder.files.filter((f) => f._id !== file._id)});
    const fileData = { ...file };
    delete fileData._id; 
    await putFolderAction(dispatch, newFolder._id , {...newFolder, files: [...newFolder.files, fileData]});
  }
  return (
    <div
      className={`${styles.row} ${styles.file}`}
      >
        <div className={styles.name}
          onClick={() => getFileAction(dispatch , file.file)} 
        >
          <AiOutlineFile />{file.title}
        </div>
        {isMoveTo && <div className={styles.move}
          onMouseLeave={() => setIsMoveTo(false)}
        >
        <div className={styles.label}>
          Déplacer vers
        </div>
        <div className={styles.folders}>
          {folders?.filter((f) => f.parent ===  folder.parent || f._id !== folder._id)
            .map((f) => (
            <button
              onClick={() => handleChangeParent(f)} 
            >
              <AiOutlineFolder />{f.name}
            </button>
          ))}
        </div>
      </div>}
      {!isPreview &&
        <div className={styles.options}>
          <img src={pointSvg} alt="option" />
          <div className={styles.select}>
            <button
              onClick={() => setIsMoveTo(true)}
            >
              Déplacer vers
            </button>
            <button
              onClick={() => deleteFile()}
            >
              Supprimer
            </button>
          </div>
      </div>
      }
    </div>
)};

export default function FolderModale({ folders , activeModale, isPreview }) {
  const dispatch = useGlobalContext()[1];
  const [activeFolder, setActiveFolder] = useState();
  const [back, setBack] = useState();
  const [ editFolder, setEditFolder ] = useState();
  const [isNew, setIsNew] = useState(null);
  const [newIsOpen, setNewIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const selectedFolders = folders?.filter((f) => f.parent === activeFolder?._id);
  const files = activeFolder?.files;

  async function handleChangeFolder(folder) {
    const updatedFolder = await putFolderAction(dispatch, activeFolder._id, folder)
    setActiveFolder(updatedFolder);
  }

  function addElement(type) {
    setIsNew(type);
    if (type === "folder") {
      setEditFolder({});
    }
    setNewIsOpen(false);
  }

  function goBack() {
    setSearch('');
    setNewIsOpen(false);
    setActiveFolder(back.value);
  }

  useEffect(() => {
    if (activeModale?.folder) {
      setActiveFolder(activeModale.folder);
    }
  },[activeModale]);

  useEffect(() => {
    if (folders && activeFolder?._id) {
      const link = {
        label: activeFolder.name,
        value: activeFolder,
      }
      let activeParent = activeFolder?.parent;
      let parentFolder = folders.find((f) => f._id === activeParent);
      if (parentFolder) {
        activeParent = parentFolder?.parent;
        link.label = parentFolder?.name + ' / ' + link.label;
        parentFolder = folders.find((f) => f._id === activeParent);
        if (parentFolder) {
          link.label = parentFolder.name + ' / ' + link.label
          activeParent = parentFolder?.parent;
          parentFolder = folders.find((f) => f._id === activeParent);
          if (parentFolder) {
            link.label = parentFolder.name + ' / ' + link.label;
          }
        }
        link.value = folders.find((f) => f._id === activeFolder?.parent);
      }
      setBack(link);
    }
  },[folders, activeFolder, activeModale]);

  useEffect(() => {
    if (search) {
      let found = null;
      folders.forEach((f) => {
        if(f.files.find((fi) => fi?.title?.toLowerCase().includes(search.toLowerCase()))) {
          found = f;
        }
        if(f.name.toLowerCase().includes(search.toLowerCase())) {
          found = f;
        }
      });
      if (found) {
        setActiveFolder(found);
      } else {

      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[search]);

  return (
    <div className={`modale-content ${styles.container} ${isPreview ? styles.preview : ''}`}>
      {!isPreview && isNew === "file" &&
        <AddFile
          backToFolder={() => setIsNew()}
          setActiveFolder={setActiveFolder}
          folder={activeFolder}
        />
      }
      {isNew === "folder" && editFolder &&
        <EditFolder
          backToFolder={() => {
            setIsNew();
            setEditFolder();
          }}
          setActiveFolder={setActiveFolder}
          folder={editFolder}
          parent={activeFolder?._id}
        />
      }
      {!isNew &&
        <>
          <div className={styles.header}>
            {!isPreview &&
              <div className={styles.add}
                onMouseLeave={() => setNewIsOpen(false)}
              >
                <button
                  type='button'
                  className={styles.new}
                  onMouseOver={() => setNewIsOpen(true)}
                >
                  Nouveau
                  {newIsOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </button>
                {newIsOpen
                  && <div className={styles.select}
                  >
                    <button
                    type='button'
                    className={styles.new}
                    onClick={() => addElement("folder")}
                  >
                    Dossier
                  </button>
                  <button
                    type='button'
                    className={styles.new}
                    onClick={() => addElement("file")}
                  >
                    Fichier
                  </button>
                </div>}
              </div>
            }
            <div className={styles.search}>
              <input
                type="text"
                value={search}
                placeholder="Rechercher"
                onChange={(e) => setSearch(e.target.value)}
              />
              <FiSearch />
            </div>
          </div>
          <div className={styles.folders}>
            <div className={styles.actions}>
            {back &&
              <button
                type='button'
                className={styles.back}
                onClick={() => goBack()}
              >
                {back.label}
              </button>}
              {!isPreview &&
                <button
                  type='button'
                  className={styles.edit}
                  onClick={() => {
                    setIsNew("folder");
                    setEditFolder(activeFolder);
                  }}
                >
                  <AiFillEdit />
                </button>
              }
            </div>
            <div className={styles.list}>
              {selectedFolders?.map((f) => <FolderRow
                key={f._id}
                folder={f}
                isPreview={isPreview}
                folders={folders}
                handleEditFolder={() => {
                  setIsNew("folder");
                  setEditFolder(f);  
                }}
                setActiveFolder={setActiveFolder} />)}
              {files?.map((f) => <FileRow
                key={f._id}
                folder={activeFolder}
                folders={folders}
                isPreview={isPreview}
                handleChangeFolder={handleChangeFolder}
                file={f} />)}
            </div>
          </div>
        </>  
      }
    </div>
  )
}
