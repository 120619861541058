import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiFillDelete } from 'react-icons/ai';
import { useGlobalContext } from '../../../../../../context/GlobalContext';

import Input from '../../../../../partials/Form/Input/Input';
import Button from '../../../../../partials/Button/Button';
import DatePicker from '../../../../../partials/DatePicker/DatePicker';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './PatientsModale.module.scss';

export default function PatientsModale({ patients , setPatients , deletePatient , updatePatient , editPatient }) {

  const context = useGlobalContext()[0];

  const { handleSubmit, register, errors , setValue , control } = useForm();

  const centers = context.studies?.study?.centers? context.studies?.study?.centers?.map(c => ({ value: c.center._id , label: c.center.name })) : [];
  const investigators = context.studies?.study?.investigators? context.studies?.study?.investigators?.map(c => ({ value: c.investigator._id , label: `${c.investigator.profile.firstName} ${c.investigator.profile.lastName}`})) : [];

  useEffect(() => {
    register("inclusionDate");
    register("center");
    register("investigator");
  }, [register]);

  function addPatient(values) {
    setPatients([...patients , values])
  }

  function submitPatient(values) {
    let updated = {...editPatient , ...values }
    updatePatient(updated);
  }

  return (
    <div className={`modale-content ${styles.container}`}>

      {editPatient ?
        <>
          <h2>Modifier le patient</h2>

          <form onSubmit={handleSubmit(submitPatient)}>

            <div className="row">

              <div className={styles['col-2']}>
                <Input
                  label={"Code patient"}
                  placehloder={"Code"}
                  name={"id"}
                  register={register({
                    required: "Compléter le code du patient",
                  })}
                  withErrors={true}
                  defaultValue={editPatient.id}
                  error={errors.id && errors.id.message}
                />
              </div>
              
              <div className={styles['col-2']}>
                <Controller
                  name="inclusionDate"
                  rules={{ required: "la date inclusion est requise" }}
                  control={control}
                  defaultValue={editPatient.inclusionDate}
                  render={(props) =>
                    <DatePicker
                      label={"Date d'inclusion"}
                      handleChange={(val) => setValue("inclusionDate" , val)}
                      withErrors={true}
                      date={new Date(editPatient.inclusionDate)}
                      error={errors.inclusionDate && errors.inclusionDate.message}
                      />
                  }
                />
              </div>

            </div>

            <Controller
              name="center"
              rules={{ required: "le centre est requis" }}
              control={control}
              defaultValue={centers?.find( c => c.value === editPatient.center)?.value}
              render={(props) =>
                <CustomSelect
                  label={"Nom du centre"}
                  defaultValue={centers?.find( c => c.value === editPatient.center)}
                  handleChange={(opt) => setValue('center' , opt.value)}
                  options={centers}
                  withErrors={true}
                  error={errors.center && errors.center.message}
                  />
              }
            />

            <Controller
              name="investigator"
              // rules={{ required: "l'investigateur est requis" }}
              control={control}
              defaultValue={investigators.find( c => c.value === editPatient.investigator)?.value}
              render={(props) =>
                <CustomSelect
                  label={"Nom du médecin"}
                  defaultValue={investigators.find( c => c.value === editPatient.investigator)}
                  handleChange={(opt) => setValue('investigator' , opt.value)}
                  options={investigators}
                  withErrors={true}
                  error={errors.investigator && errors.investigator.message}
                  />
              }
            />


            <div className="modale-actions start">
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
              <button
                type={"button"}
                onClick={() => deletePatient(editPatient._id)}
                className={"btn-delete"}
                >
                <AiFillDelete size={20} color={'red'}/> <p>Supprimer</p>
              </button>
            </div>
      
          </form>
        </>
        :
        <>
          <h2>Ajouter un patient</h2>

          <form onSubmit={handleSubmit(addPatient)}>

            <div className="row">

              <div className={styles['col-2']}>
                <Input
                  label={"Code patient"}
                  placehloder={"Code"}
                  name={"id"}
                  register={register({
                    required: "Compléter le code du patient",
                  })}
                  withErrors={true}
                  error={errors.id && errors.id.message}
                />
              </div>
              
              <div className={styles['col-2']}>
                <Controller
                  name="inclusionDate"
                  rules={{ required: "la date inclusion est requise" }}
                  control={control}
                  defaultValue={null}
                  render={(props) =>
                    <DatePicker
                      label={"Date d'inclusion"}
                      handleChange={(val) => setValue("inclusionDate" , val)}
                      withErrors={true}
                      error={errors.inclusionDate && errors.inclusionDate.message}
                      />
                  }
                />
              </div>

            </div>

            <Controller
              name="center"
              rules={{ required: "le centre est requis" }}
              control={control}
              render={(props) =>
                <CustomSelect
                  label={"Nom du centre"}
                  defaultValue={null}
                  handleChange={(opt) => setValue('center' , opt.value)}
                  options={centers}
                  withErrors={true}
                  error={errors.center && errors.center.message}
                  />
              }
              defaultValue={null}
            />

            <Controller
              name="investigator"
              rules={{ required: "l'investigateur est requis" }}
              control={control}
              render={(props) =>
                <CustomSelect
                  label={"Nom du médecin"}
                  defaultValue={null}
                  handleChange={(opt) => setValue('investigator' , opt.value)}
                  options={investigators}
                  withErrors={true}
                  error={errors.investigator && errors.investigator.message}
                  />
              }
              defaultValue={null}
            />


            <div className="modale-actions center">
              <Button
                type={"submit"}
                primary
                >
                Valider
              </Button>
            </div>
      
          </form>
        </>
      }
    </div>
  )
}
