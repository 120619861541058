import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction , sendNewsLetterAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import NewsModale from './NewsModale/NewsModale';
import NewsRow from './NewsRow/NewsRow'
import Modale from '../../../../partials/Modale/Modale';

import styles from './NewsLetters.module.scss';

export default function NewsLetters({ study , id , setActiveSection , isPreview }) {

  const dispatch = useGlobalContext()[1];

  const { newsletters } = study;

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ editLetter , setEditLetter ] = useState();
  const [ height , setHeight ] = useState(0);
  const [ textOpen , setTextOpen ] = useState(false);

  useEffect(() => {
    if (!modaleIsActive) setEditLetter();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editLetter) setModaleIsActive(true);
  }, [editLetter]);

  function updatedPanel() {
    setTextOpen(!textOpen);
  }

  useEffect(() => {
    if (panelIsOpen && contentRef.current) {
      setHeight(contentRef.current.clientHeight);
    } else {
      setHeight(0);
    }
  },[panelIsOpen, textOpen , newsletters ])

  function submitLetters(array) {
    putStudyAction(dispatch , study._id , { newsletters: array } );
  }

  function deleteLetter(id) {
    let deleteList = newsletters.filter(d => d._id !== id);
    setEditLetter();
    setModaleIsActive(false);
    submitLetters(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Newsletters <span>({newsletters.length})</span></h3>
            {newsletters.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Nouvelle newsletter
            </Button>
          }
          </div>
        </div>

        {newsletters.length > 0 && 
          <div className="panel" style={{ height: height }}>
            <div ref={contentRef} className={styles.meets}>

              <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Titre
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Date
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                </div>
              </div>

              {newsletters && newsletters.map((letter, i) => 
                <NewsRow 
                  key={`letter ${i}`}
                  styles={styles}
                  letter={letter}
                  isPreview={isPreview}
                  updatedPanel={updatedPanel}
                  sendNewsLetter={() => sendNewsLetterAction(dispatch , study._id , letter._id )}
                  setEditLetter={setEditLetter}/> 
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <NewsModale
            editLetter={editLetter}
            deleteLetter={deleteLetter}
            newsletters={newsletters}
            submitLetters={submitLetters}/>}
      </Modale>
    </>
  )
}
