import React , { useEffect , useState , useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useGlobalContext } from '../../../context/GlobalContext';

import { getOptionsListsAction , getStudiesListsAction , putStudyAction } from '../../../actions/studiesActions';

import styles from './Status.module.scss';
import CenterCard from './CenterCard/CenterCard';

export default function StausPage({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();

  const { filteredStudies , studiesList } = context.studies;
  const { isDoctor } = context.auth;
  const { filteredCenters } = context.centers;
  const phasesOctapharma = context.studies.optionsLists?.phasesOctapharma ;

  const [ filtredList , setFiltredList ] = useState();
  const [ centersList , setCentersList ] = useState();

  const phasesValues = [
    { 
      value: 'Recrutement',
      label: 'Phase de Sélection'
    },
    {
      value: 'Faisabilité/qualification',
      label: 'Faisabilité/qualification',
    },
    {
      value: 'Contractuelle',
      label: 'Phase Contractuelle'
    }, 
    {
      value: 'Ouvert',
      label: 'Centres Ouvert'
    },
    {
      value: 'Cloturé',
      label:'Centres Cloturé'
    },
    {
      value: 'Refusé',
      label:'Centres refusé'
    },
    
  ];

  const initData = useCallback(() => {
    setActivePage('status');
    getStudiesListsAction(dispatch);
    getOptionsListsAction(dispatch);
    dispatch({
      type : 'GET_STUDY',
      payload : null
    });
  }, [setActivePage ,  dispatch ]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (filteredStudies) {
      setFiltredList(filteredStudies)
    }
  },[filteredStudies]);

  useEffect(() => {
    if (filtredList && phasesOctapharma) {

      let centers = {

      };

      filtredList.forEach(study => {
        study.centers.forEach(center => {
          
          const centerData = {
            type: study.type,
            studyId: study._id,
            studyName: study.name,
            centerName: center.center?.name,
            phase: center.phase,
            id: center._id,
            centerId: center.center?._id,
            color: phasesOctapharma?.find(p => p.value === center.phase )?.color
          };

          if (center.phase) {
            if (!centers[center.phase]) centers[center.phase] = []
            centers[center.phase].push(centerData);
          }
        })
      });

      let centerArray = [];
      for (const property in centers) {
        centerArray.push(centers[property]);
      }
      setCentersList(setColsPositions(centerArray));
    }
     // eslint-disable-next-line
  },[filtredList , phasesOctapharma ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    removed.phase = phasesValues.find(p  => p.value === destClone[0].phase).value ;

    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    let studiesCenters = studiesList.find(study => study._id === removed.studyId ).centers ;
    studiesCenters[studiesCenters.findIndex(c => c._id === removed.id)].phase = phasesValues.find(p  => p.value === destClone[0].phase).value
    
    putStudyAction(dispatch , removed.studyId , { centers: studiesCenters } );

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(centersList[sInd], source.index, destination.index);
      const newCenters = [...centersList];
      newCenters[sInd] = items;
      setCentersList(setColsPositions(newCenters));
    } else {
      const result = move(centersList[sInd], centersList[dInd], source, destination);
      const newCenters = [...centersList];
      newCenters[sInd] = result[sInd];
      newCenters[dInd] = result[dInd];
      setCentersList(setColsPositions(newCenters.filter(group => group.length)));
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    padding: 20,
  });

  function setColsPositions(centers) {
    let centersByStatus = []
    phasesOctapharma.forEach(p => {
      let listFound = centers.find(( list ) => p.value === list[0].phase );
      centersByStatus.push(listFound ? listFound : [{ phase: p.value }])
    })
    return centersByStatus;
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.row}>
            {centersList && centersList.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className={styles.col}
                    {...provided.droppableProps}
                  >
                    <h1 style={{ display: 'inline-block' , borderBottom: `2px solid ${phasesOctapharma[ind].color}`}}>{phasesValues[ind].label}</h1>
                    {el.map((item, index) => (
                      <div key={'list' + item.id}>
                        {item.id &&
                            <Draggable
                              key={item.id}
                              isDragDisabled={isDoctor}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <CenterCard center={item} isDoctor={isDoctor} isActive={filteredCenters.length === 0 || filteredCenters.find(c => c === item.centerId ) ? true : false }/>
                                </div>
                              )}
                            </Draggable>
                          }
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}
