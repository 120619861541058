import React from 'react';
import styles from './Button.module.scss';

export default function Button({ onClick , type,  children , className , primary , danger , outline , radius }) {
  return (
    <button
      type={type ? type : "button"}
      onClick={onClick? () => onClick() : null }
      className={`${styles.btn} ${primary ? styles.primary : ''} ${ danger ? styles.danger : ''} ${outline ? styles.outline : ''} ${radius ? styles.radius : ''} ${className ? className : ''}`}
      >
      {children}
    </button>
  )
}
