import React , { useEffect , useState , useCallback } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { FaEye , FaEyeSlash } from "react-icons/fa";
import { useGlobalContext } from '../../../context/GlobalContext';
import { loginAction , forgotPasswordAction , getUserAuthAction } from '../../../actions/authActions';

import './auth.scss';
import { TOOL_ID } from '../../../actions';

export default function Login({ setActivePage }) {

  const { handleSubmit, register, errors } = useForm();

  const history = useHistory();

  const [ context, dispatch ] = useGlobalContext();

  const [ displayLogin , setDisplayLogin ] = useState(true);
  const [ errorForgot , setErrorForgot ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    setActivePage("login");
  }, [setActivePage]);

  useEffect(() => {
    if (context.auth.error && context.auth.error.status === 422) {
      setErrorForgot("Votre demande n'a pas pu être traitée telle qu'elle a été saisie. Veuillez réessayer.")
    }
  }, [context.auth.error]);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
    }
  // eslint-disable-next-line 
  }, [context.auth.isAuth]);

  useEffect(() => {
    if(context.auth.userAuth) {
      if (context.auth.userAuth.tools.find(item => item.tool=== TOOL_ID)) {
        history.push("/study")
      }
    }
  // eslint-disable-next-line 
  }, [context.auth.userAuth]);


  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };


  function renderLoginForm() {
    return (
      <form className="form-login" onSubmit={handleSubmit(submitLogin)}>

        <label htmlFor="email">Email</label>
        <input
          name="email"
          className="primary"
          ref={register({
            required: "Compléter votre email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Adresse mail invalide"
            }
          })}
        />

        <p className="text-error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Mot de passe</label>
        <input
          className="primary"
          name="password"
          type={showPassword? "text" : "password"}
          ref={register({
            required: "Compléter votre mot de passe",
          })}
        />
        <p className="text-error">{errors.password && errors.password.message}</p>
        <div
          className="eye"
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <FaEye size={20} color={"#192243"} /> : <FaEyeSlash size={20} color={"#192243"} /> }
        </div>
        
        <div className="btn-container">
          <button className="btn primary" type="submit">Connexion</button>
          <button onClick={() => setDisplayLogin(false)} className="btn-reset">Mot de passe oublié ?</button>
        </div>


        <p className="text-error text-center">{context.auth.errorLogin && context.auth.errorLogin === 401 && <span> Adresse e-mail ou mot de passe invalide.</span>}</p>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className="form-reset message">

          <p className="text-center">Veuillez vérifier votre adresse e-mail pour connaître<br/>le lien permettant de réinitialiser votre mot de passe.</p>

          <button onClick={() =>  backFromReset()} className="btn primary">Retour au login</button>
        </div>
      )

    } else {
      return (
        <form className="form-reset" onSubmit={handleSubmit(submitReset)}>
        
          <label htmlFor="email">Email</label>
          <input
            className="primary"
            name="email"
            ref={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
          />

          {errorForgot? 
            <p className="text-error text-center" style={{ margin: 5}}>{errorForgot}</p>
            :
            <p className="text-error text-center">{errors.email && errors.email.message}</p>
          }
          
          <div className="btn-container">
            <button className="btn primary" type="submit">Réinitialiser le mot de passe</button>
            <button onClick={() => setDisplayLogin(true)} className="btn-reset">Retour au login</button>
          </div>
      
        </form>
      )
    }
  }
  
  return (
    <div className="login">

      <div className="col-img"></div>

      <div className="col-form">

        <p className="title bold">
          <span className="name">Clinocta</span><br/>
          <span className="regular">BY </span><span>octapharma</span>
        </p>

        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }

      </div>

    </div>
  )
}
