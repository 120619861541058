import React , { createContext , useContext , useReducer } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ initialState , reducers , children }) => {
  return (
    <GlobalContext.Provider value={useReducer( reducers , initialState )}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(GlobalContext);
}
