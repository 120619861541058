export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              ERROR_LOGIN = 'ERROR_LOGIN',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              SET_TOAST = 'SET_TOAST',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              //STUDIES ACTIONS
              ERROR_STUDIES = 'ERROR_STUDIES',
              MESSAGE_STUDIES = 'MESSAGE_STUDIES',
              GET_OPTIONS_LIST = 'GET_OPTIONS_LIST',
              GET_STUDIES_LIST = 'GET_STUDIES_LIST',
              POST_STUDY = 'POST_STUDY',
              GET_STUDY = 'GET_STUDY',
              PUT_STUDY = 'PUT_STUDY',
              POST_FILE = 'POST_FILE', 
              ERROR_UPLOAD = 'ERROR_UPLOAD',
              DELETE_FILE = 'DELETE_FILE',
              FILTERED_STUDIES = 'FILTERED_STUDIES',
              DELETE_STUDY = 'DELETE_STUDY',
              //CENTERS ACTIONS
              ERROR_CENTERS = 'ERROR_CENTERS',
              MESSAGE_CENTERS = 'MESSAGE_CENTERS',
              PUT_CENTER ='PUT_CENTER',
              POST_CENTER = 'POST_CENTER' ,
              GET_CENTERS_LIST = 'GET_CENTERS_LIST',
              GET_CENTER = 'GET_CENTER',
              DELETE_CENTER ='DELETE_CENTER',
              FILTERED_CENTERS = 'FILTERED_CENTERS',
              // DRUGS ACTIONS
              ERROR_DRUGS = 'ERROR_DRUGS',
              GET_DRUGS_LIST = 'GET_DRUGS_LIST',
              //FOLDER ACTION
              PUT_FOLDER = 'PUT_FOLDER';
