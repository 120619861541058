import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction , getFileAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import DoctorModale from './DoctorModale/DoctorModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Doctors.module.scss';

export default function Doctors({ study , id , setActiveSection , isPreview }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { investigators } = study;
  const investigatorsList = context.studies?.optionsLists?.investigators ;

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ editDoctor , setEditDoctor ] = useState();

  useEffect(() => {
    if (!modaleIsActive) setEditDoctor();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editDoctor) {
      setModaleIsActive(true);
    }
  }, [editDoctor]);

  useEffect(() => {
    let foundDoctor = investigators.find(d => d._id === editDoctor?._id)
    if(!foundDoctor) return null;
    return setEditDoctor(foundDoctor);
  },[investigators , editDoctor?._id]);

  function submitDoctors(doctors) {
    putStudyAction(dispatch , study._id , { investigators: doctors } );
  }

  function deleteDoctor(id) {
    let deleteList = investigators.filter(d => d.investigator._id !== id);
    setEditDoctor();
    setModaleIsActive(false);
    submitDoctors(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Liste des investigateurs <span>({investigators.length})</span></h3>
            {investigators.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un médecin
            </Button>
          }
          </div>
        </div>

        {investigators.length > 0 && 
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.centers}>

              <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Nom du médecin
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Etablissement
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  CV
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Formation BPC
                </div>
                <div className={`${styles.col}  ${styles.label} ${styles.patients} text-grey`}>
                  Patients inclus
                </div>
              </div>

              {investigators && investigators.map((doctor, i) => 
                <div key={`doctor${i}`} className={styles.row}>
                  <div className={`${styles.col} `}>
                    {isPreview ?
                      <span className="bold">
                        {doctor?.investigator?.profile?.firstName} {doctor?.investigator?.profile?.lastName}
                      </span>
                      :
                      <button
                        onClick={() => setEditDoctor(doctor)}
                        className="btn-edit-row">
                        {doctor?.investigator?.profile?.firstName} {doctor?.investigator?.profile?.lastName}
                      </button>
                    }                
                  </div>
                  <div className={`${styles.col} text-grey`}>
                  {doctor.investigator?.center?.name} 
                  </div>
                  <div className={`${styles.col}${doctor.cv ? " text-success": " text-grey"}`}>
                    {doctor.cv ?
                      <button
                        onClick={() => getFileAction(dispatch , doctor.cv )}
                        className="btn-edit-row">
                        Voir le cv
                      </button>
                      :
                      <>
                      {isPreview ?
                        "NON"
                        :
                        <button className={styles['add-file']} onClick={() => setEditDoctor(doctor)}>Ajouter</button>
                      }
                      </>
                    }
                  </div>
                  <div className={`${styles.col} ${doctor.bpc ? " text-success": " text-grey"}`}>
                    {doctor.bpc ?
                      <button
                        onClick={() => getFileAction(dispatch , doctor.bpc )}
                        className="btn-edit-row">
                        Voir le fichier
                      </button>
                      :
                      <>
                      {isPreview ?
                        "NON"
                        :
                        <button className={styles['add-file']} onClick={() => setEditDoctor(doctor)}>Ajouter</button>
                      }
                      </>
                    }
                  </div>
                  <div className={`${styles.col} ${styles.patients} ${doctor.patientsCount? "": " text-grey"}`}>
                    {doctor.patientsCount}
                  </div>
              </div>
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <DoctorModale
            editDoctor={editDoctor}
            deleteDoctor={deleteDoctor}
            doctorsOptions={investigatorsList}
            doctors={investigators}
            setModaleIsActive={setModaleIsActive}
            submitDoctors={submitDoctors}/>}
      </Modale>
    </>
  )
}
