import React , { useState , useEffect , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { format } from 'date-fns';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import LineChart from '../../../../partials/Chart/LineChart/LineChart';
import CustomSelect from '../../../../partials/Form/Select/CustomSelect';

import styles from './Inclusions.module.scss';

export default function Inclusions({ study , id , isDoctor , setActiveSection , isPreview }) {

  const context  = useGlobalContext()[0];

  const contentRef = useRef(null);

  const allCentersOpt = { value: "all" , label: "Tous les centres" }
  const { userAuth } = context.auth;

  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ selectedCenter , setSelectedCenter ] = useState();
  const [ centersOptions , setCentersOptions ] = useState();
  const [ userPatientsList , setUserPatientsList ] = useState();

  const [ data , setData ] = useState();


  useEffect(() => {
    if (study.centers && study.patients) {

      let allPatientsList = study.patients?.filter( p => {
        if(!isDoctor) return p
        if(isDoctor && p.investigator === userAuth?._id) return p 
        return null
      });
      setUserPatientsList(allPatientsList)

      setData();
      let options = study.centers.filter(c => c.inclusion.length > 0 ? true : false ).map(item => {
        if(item.center && item.center._id) {
          return { value: item.center._id , label: item.center.name }
        } else {
          return null;
        }
      });
      options.unshift(allCentersOpt);
      setCentersOptions(options);
      if (!selectedCenter) {
        if(!isDoctor) return setSelectedCenter(allCentersOpt);
        if(isDoctor) return setSelectedCenter(allCentersOpt);
      }
    }
    // eslint-disable-next-line
  }, [study.centers , study.patients])

  useEffect(() => {
    if (selectedCenter && userPatientsList) {
      let dateList = [];

      const closingDate = study.steps?.find(s => s.name === "Visite de cloture administrative")?.startDate;

      let firstDate = new Date();
      let lastDate = closingDate ? new Date(closingDate) : new Date();

      study.centers.forEach(center => {
        if (center.inclusion) {
          center.inclusion.forEach(item => {
              if (new Date(item.date) > lastDate ) lastDate = new Date(item.date);
              if (new Date(item.date) < firstDate ) firstDate = new Date(item.date);
          });
        }
      });

      userPatientsList.forEach(item => {
          if (new Date(item.inclusionDate) > lastDate ) lastDate = new Date(item.inclusionDate);
          if (new Date(item.inclusionDate) < firstDate ) firstDate = new Date(item.inclusionDate);
      });

      do {
        dateList.push({ x: format(new Date(firstDate) , "MM/yy") , y: 0 });
        firstDate = new Date(firstDate.setMonth(firstDate.getMonth()+1));
      } while (firstDate < lastDate );

      let centersList = [];

      study.centers.forEach(center => {
        if (center.inclusion) {
          center.inclusion.forEach(item => {
            if (selectedCenter.value === "all" ) {
               return centersList.push({ value: item.value , date: format(new Date(item.date) , "MM/yy") })
            }
            if (selectedCenter.value === center.center._id ) {
              return centersList.push({ value: item.value , date: format(new Date(item.date) , "MM/yy") })
            }
            return null
          })
        }
      });

      let prevData = [];

      dateList.forEach((data , i ) => {
        let sameMonth = centersList.filter( item => item.date === data.x );
        let totalPatients = 0;
        sameMonth.forEach(d => {
          totalPatients += d.value
        });
        let lastValue = i !== 0 ? prevData[i - 1].y : 0
        let y = totalPatients + lastValue
        prevData.push({ x: data.x , y: y })
      });

      let patientsData = [];
      dateList.forEach((data , i ) => {
        let patientList = userPatientsList.filter( p => {
          if (selectedCenter.value === "all" ) {
            return p
          }
          if (selectedCenter.value === p.center ) {
            return p
          }
          return null
        })
        let sameMonthPatients = patientList.filter( item => format(new Date(item.inclusionDate) , "MM/yy") === data.x );
        let lastPatientValue = i !== 0 ? patientsData[i - 1].y : 0
        let patientValue = sameMonthPatients.length + lastPatientValue
        patientsData.push({ x: data.x , y: patientValue });
      });

      const newDataSet = [
        {
          id: "Prévision",
          color: "#192243",
          data: prevData
        },
        {
          id: "Nombre réel de patients",
          color: "#5B44EB",
          data: patientsData
        },
      ]

      
      setData(newDataSet);
    }
    // eslint-disable-next-line
  },[userPatientsList , selectedCenter])

  return (
    <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
    
      <div className="header">
        <div className="title">
          <h3>Courbes d'inclusions</h3>
          <button
            onClick={() => setPanelIsOpen(!panelIsOpen)}
            className="panel-toggle">
            { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
          </button>
        </div>
        {!isPreview && panelIsOpen &&
          <div className={styles.select}>
            {selectedCenter?.label &&
              <CustomSelect
                value={centersOptions.find(opt => opt.label === selectedCenter.label)}
                handleChange={(val) => setSelectedCenter(val)}
                options={centersOptions}
                />
            }
          </div>
        }
      </div>

      <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
        <div ref={contentRef} className={styles.chart}>
          <LineChart data={data ? data :[]} />
        </div>
      </div>

    </section>
  )
}
