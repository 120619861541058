import React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import styles from './Step.module.scss';

export default function Step({ step , color , dayWidth , stepHeight , onClick , initialDay }) {

  function getStyles() {
    let offsetLeft = differenceInCalendarDays( new Date(step.endDate) , new Date(initialDay) ) ;
    return {
      width: differenceInCalendarDays( new Date(step.endDate) , new Date(step.startDate) ) * dayWidth,
      minWidth: dayWidth,
      height: stepHeight,
      transform: `translate(${((offsetLeft - differenceInCalendarDays( new Date(step.endDate) , new Date(step.startDate) ) ) * dayWidth)}px , 0px)`
    }
  }

  return (
    <div style={getStyles()}
      onClick={() => onClick(step)}
      className={styles.container}>
      <p>{step.name}</p>
      <div className={styles.start} style={{ backgroundColor: color }}>
      </div>
      <div className={styles.bar} style={{ backgroundColor: color }}></div>
      <div className={styles.end} style={{ backgroundColor: color }}>
      </div>
    </div>
  )
}
