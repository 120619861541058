import React from 'react';
import useDimensions from "react-use-dimensions";
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalProvider } from './context/GlobalContext';

import authReducer from './reducers/authReducer';
import studiesReducer from './reducers/studiesReducer';
import centersReducer from './reducers/centersReducer';

import PageMobile from './components/pages/Mobile/PageMobile';
import Routes from './components/routes/GlobalRouter';
import drugsReducer from './reducers/drugsReducer';

function App() {

  const [ref, { width }] = useDimensions();

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    studies: studiesReducer,
    centers: centersReducer,
    drugs: drugsReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
      reducers={reducers}
      initialState={initialState}
      >
      <div ref={ref} className="app-container">
        {width >= 1024 ?
          <Router>
            <Routes />
          </Router>
          :
          <PageMobile />
        }
      </div>
    </GlobalProvider>
  );
}

export default App;
