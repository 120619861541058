import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";

import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './DrugModale.module.scss';

export default function DrugModale({ drugsOptions , drugs , therapeuticAreaOptions, submit }) {

  const { handleSubmit, register, errors , setValue , control } = useForm();

  useEffect(() => {
    register("drug");
    register("therapeuticArea");
  }, [register]);

  function adddrugs(values) {
    console.log(values)
    submit([...drugs , values ])
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>Ajouter un produit</h2>
        <form onSubmit={handleSubmit(adddrugs)}>
          <Controller
            name="drug"
            rules={{ required: "le produit est requis" }}
            control={control}
            render={() =>
              <CustomSelect
                label={"Produit"}
                defaultValue={null}
                handleChange={(opt) => setValue('drug' , opt.value)}
                options={drugsOptions?.map(d => ({ label: d.name , value: d._id}))}
                withErrors={true}
                error={errors.drug && errors.drug.message}
                />
            }
            defaultValue={null}
          />

          <Controller
            name="therapeuticArea"
            rules={{ required: "l'aire thérapeutique est requis" }}
            control={control}
            render={() =>
              <CustomSelect
                label={"Aire thérapeutique"}
                defaultValue={null}
                handleChange={(opt) => setValue('therapeuticArea' , opt.value)}
                options={therapeuticAreaOptions}
                withErrors={true}
                error={errors.therapeuticArea && errors.therapeuticArea.message}
                />
            }
            defaultValue={null}
          />

          <div className="modale-actions center">
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
          </div>
        </form>
    </div>
  )
}
