import React from 'react';
import styles from './CenterCard.module.scss';

export default function CenterCard({ center  , isActive , isDoctor }) {
  return (
    <>
      {isActive &&
        <div className={`${styles.container} ${isDoctor ? styles.doctor :''}`}>
          <div className={styles.circle} style={{ backgroundColor: center.color }}></div>
          <div className={styles.row}>
            <p className={styles.label}>Type</p>
            <p >{center.type}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>Nom de l'étude</p>
            <p className="bold">{center.studyName}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>Nom du centre</p>
            <p >{center.centerName}</p>
          </div>
        </div>
      }
    </>
  );
}
