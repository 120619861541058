import { getData } from './index';

import {
  ERROR_DRUGS,
  GET_DRUGS_LIST,
} from './types';

export async function getDrugsAction(dispatch) {
  let url = "/drug";
  var drugs;

  await getData(ERROR_DRUGS, url, dispatch , true ).then((response)=>{
    drugs = response.drugs
  });

  dispatch({
    type : GET_DRUGS_LIST,
    payload : drugs
  });

};
