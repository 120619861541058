import React , { useEffect } from 'react';
import { useForm  , Controller } from "react-hook-form";

import Button from '../../../../../partials/Button/Button';
import CustomSelect from '../../../../../partials/Form/Select/CustomSelect';

import styles from './CrosModale.module.scss';

export default function CrosModale({ doctors , doctorsOptions , submitCros }) {

  const { handleSubmit, register, errors , setValue , control } = useForm();

  useEffect(() => {
    register("cro");
  }, [register]);

  function addDoctor(values) {
    submitCros([...doctors.map(d => ({ cro: d.cro._id })) , values ])
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>Ajouter un CRO</h2>
        <form onSubmit={handleSubmit(addDoctor)}>
          <Controller
            name="cro"
            rules={{ required: "le fournisseur est requis" }}
            control={control}
            render={(props) =>
              <CustomSelect
                label={"Nom du fournisseur"}
                defaultValue={null}
                handleChange={(opt) => setValue('cro' , opt.value)}
                options={doctorsOptions}
                withErrors={true}
                error={errors.cro && errors.cro.message}
                />
            }
            defaultValue={null}
          />

          <div className="modale-actions center">
            <Button
              type={"submit"}
              primary
              >
              Valider
            </Button>
          </div>
        </form>
    </div>
  )
}
