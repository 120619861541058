import React , { useState , useCallback , useEffect , useRef } from 'react';

import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';
import { getCentersListAction } from '../../../../../actions/centersActions';

import Button from '../../../../partials/Button/Button';
import CentersModale from './CentersModale/CentersModale';
import InclusionsModale from './InclusionsModale/InclustionsModale';
import Modale from '../../../../partials/Modale/Modale';
import Indicator from './Indicator/Indicator';

import styles from './Centers.module.scss';

export default function Centers({ study , id , setActiveSection , isPreview }) {

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);
  const [ editCenter , setEditCenter ] = useState();

  const [ editCenterInclusions , setEditCenterInclusions ] = useState();
  const [ inclusionsModaleIsActive , setInclusionsModaleIsActive ] = useState(false);

  const  [ context , dispatch ] = useGlobalContext();
  const phasesOctapharma = context.studies.optionsLists?.phasesOctapharma ;


  const { centers } = study;
  const { centersList } = context.centers;

  const contentRef = useRef(null);

  const getCenters = useCallback(() => {
    getCentersListAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getCenters();
  }, [getCenters]);

  useEffect(() => {
    if (!modaleIsActive) setEditCenter();
  }, [modaleIsActive]);

  useEffect(() => {
    if (editCenter) setModaleIsActive(true);
  }, [editCenter]);

  useEffect(() => {
    if (!inclusionsModaleIsActive) setEditCenterInclusions();
  }, [inclusionsModaleIsActive]);

  useEffect(() => {
    if (editCenterInclusions) setInclusionsModaleIsActive(true);
  }, [editCenterInclusions]);


  function submitCenters(data) {
    putStudyAction(dispatch , study._id , { centers: data } );
  }

  function deleteCenter(id) {
    let deleteList = centers.filter(c => c._id !== id);
    submitCenters(deleteList)
  }

  function renderInclusionsTotal(inclusions) {
    let total = 0
    inclusions.forEach(d => {
      total += d.value
    });
    return total;
  }

  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) }  className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Liste des centres et services <span>({study.centers.length})</span></h3>
            {study.centers.length > 0 &&
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un centre
            </Button>
          }
          </div>
        </div>
        {study.centers.length > 0 &&
        <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight + 20 : 0 }}>
          <div ref={contentRef} className={styles.centers}>

            <div className={`${styles.row}`}>
              <div className={`${styles.col} ${styles.label}  ${styles.name} text-grey`}>
                Nom du centre / service
              </div>
              <div className={`${styles.col} ${styles.label} text-grey`}>
                Phase
              </div>
              <div className={`${styles.col} ${styles.label} text-grey`}>
                Indicateurs
              </div>
              <div className={`${styles.col} ${styles.inclusions} ${styles.label} text-grey`}>
                Inclusions
              </div>
            </div>

            {centers.map((c, i) => 
              <div key={`center${i}`} className={styles.row}>
                <div className={`${styles.col} ${styles.name}`}>
                  {isPreview ?
                    <p className={`bold ${c?.service ? styles['border-service'] : styles['border']}`}>{c?.center.name}
                    <br/>{centersList?.find((cr) => cr._id === c?.center._id)?.location.city }
                    <br/>
                    {c?.center.services?.find(s => s._id === c.service)?.name && <span className={styles.service}>{c?.center.services?.find(s => s._id === c.service)?.name}</span>}</p>
                    :
                    <button
                      onClick={() => setEditCenter(c)}
                      className={`btn-edit-row ${styles.center} ${c?.service ? styles['border-service'] : styles['border']}`}>
                      {c?.center.name}
                      <br/>{centersList?.find((cr) => cr._id === c?.center._id)?.location.city }
                      <br/>
                      {c?.center.services?.find(s => s._id === c.service)?.name && <span className={styles.service}>{`-  ${c?.center.services?.find(s => s._id === c.service)?.name}`}</span>}
                    </button>
                  }
                </div>
                <div className={`${styles.col} text-grey`}>
                  <div className="puce" style={{ backgroundColor: phasesOctapharma?.find(p => p.value === c.phase )?.color }}></div> {phasesOctapharma?.find(p => p.value === c.phase )?.label}
                </div>
                <div className={`${styles.col}`}>
                  <Indicator
                    label={"CPP"}
                    isTrue={c?.cpp}
                    />
                  <Indicator
                    label={"Faisabilité"}
                    isTrue={c.feasibility}
                    />
                  <Indicator
                    label={"Contrat"}
                    isTrue={c.contrat}
                    />
                  <Indicator
                    label={"Mep"}
                    isTrue={c.open}
                    />
                  <Indicator
                    label={"Cloturé"}
                    isTrue={c.closed}
                    />
                </div>
                <div className={`${styles.col} ${styles.inclusions} text-grey`}>
                  <p>{study?.patients?.filter(p => c.center && p.center === c.center._id).length} / </p> 
                  {isPreview ?
                    <>
                      {c.inclusion && renderInclusionsTotal(c.inclusion)} prévus
                    </>
                    :
                    <button
                      onClick={() => setEditCenterInclusions(c)}
                      className={`${styles.patients} btn-edit-row`}>
                      {c.inclusion && renderInclusionsTotal(c.inclusion)} prévus
                    </button>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      }

      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive && <CentersModale
          centers={centers}
          editCenter={editCenter}
          phasesOctapharma={phasesOctapharma}
          deleteCenter={deleteCenter}
          submitCenters={submitCenters}
        />}
      </Modale>
      <Modale
        isOpen={inclusionsModaleIsActive}
        modaleToggle={setInclusionsModaleIsActive}
        >
        {inclusionsModaleIsActive && <InclusionsModale
          centers={centers}
          editCenter={editCenterInclusions}
          submitCenters={submitCenters}
        />}
      </Modale>
    </>
  )
}
