import React from 'react';
import { MdClose } from 'react-icons/md';

import styles from './Panel.module.scss';

export default function Panel({ isOpen , setIsOpen , children , hideClose , closeClassName }) {
  
  return (
    <div className={`${styles.panel} ${isOpen ? styles['is-open'] : "" }`}>
      {!hideClose && <button
          onClick={() => setIsOpen(false)}
          className={`${styles['close']} ${closeClassName ? closeClassName :''}`}>
          <MdClose size={32} color={'#656565'} /> 
        </button>
      }
      {children}
    </div>
  )
}
