import React , { useState , useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
// import { putStudyAction , deleteFileAction } from '../../../../../actions/studiesActions';
import { AiOutlineFolder } from 'react-icons/ai';
// import { useGlobalContext } from '../../../../../context/GlobalContext';

import Button from '../../../../partials/Button/Button';
import Modale from '../../../../partials/Modale/Modale';
import FolderModale from './FolderModale/FolderModale';

import styles from './Tmf.module.scss';
import ChangesModale from './ChangesModale/ChangesModale';
import { MdUpdate } from 'react-icons/md';

export default function Tmf({ study , id , setActiveSection , isPreview }) {

  const { folders , changes } = study;
  const contentRef = useRef(null);

  const [ activeModale , setActiveModale ] = useState(false);
  const [ activeChangeModale , setActiveChangeModale ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);

  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>TMF  <span>({folders?.filter((f) => !f.parent )?.length})</span></h3>
            {folders?.length > 0 &&
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div className={styles.actions}>
            <button
              onClick={() => setActiveChangeModale(true)}
              className={styles.changes}
            >
              <p>
                Voir les modifications
              </p>
              <MdUpdate />
            </button>
          {!isPreview && 
            <Button
              primary
              onClick={() => setActiveModale({folder:{ }})}
              >
              Nouveau
            </Button>
          }
          </div>
        </div>

        {folders?.length > 0 &&
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? 'max-content' : 0 }}>
            <div ref={contentRef}>
              <div className={styles.lists}>
                {folders.filter((f) => !f.parent ).map((f) => (
                  <button
                    onClick={() =>setActiveModale({ folder: f })}
                  >
                    <AiOutlineFolder /><p>{f.name}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        }

      </section>
      <Modale
        isOpen={activeModale}
        isPreview={isPreview}
        modaleToggle={() => setActiveModale()}
        >
        {activeModale && <FolderModale
          setActiveModale={setActiveModale}
          activeModale={activeModale}
          folders={folders}
          isPreview={isPreview}
          studyId={study._id}
          />}
      </Modale>
      <Modale
        isOpen={activeChangeModale}
        isPreview={isPreview}
        modaleToggle={() => setActiveChangeModale(false)}
        >
        {activeChangeModale && <ChangesModale
            changes={changes}
            isPreview={isPreview}
          />}
      </Modale>
    </>
  )
}
