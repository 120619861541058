import React , { useState, useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import TherapeuticAreaModale from './TherapeuticAreaModale/TherapeuticAreaModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './TherapeuticArea.module.scss';

export default function TherapeuticAreas({ study , id , setActiveSection , isPreview }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { therapeuticArea } = study;
  const therapeuticAreaOptions = context.studies?.optionsLists?.therapeuticArea

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);

  function submitTherapeuticArea(values) {
    putStudyAction(dispatch , study._id , { therapeuticArea: values } );
  }

  function deleteTherapeuticArea(id) {
    let deleteList = therapeuticArea.filter(d => d._id !== id);
    submitTherapeuticArea(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Aires thérapeutiques <span>({therapeuticArea.length})</span></h3>
            {therapeuticArea.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter une aire thérapeutique
            </Button>
          }
          </div>
        </div>

        {therapeuticArea.length > 0 && 
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.centers}>

              <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Nom de l’aire
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
        
                </div>
              </div>

              {therapeuticArea && therapeuticArea.map((area, i) => 
                <div key={`c${i}`} className={styles.row}>
                  <div className={`${styles.col} `}>
                    <span className="bold">
                      {area.name}
                    </span>             
                  </div>
                  <div className={`${styles.col} ${styles.actions} text-grey`}>
                    {!isPreview &&
                      <button
                        onClick={() => deleteTherapeuticArea(area._id)}  
                        className={styles.delete}
                        >
                        supprimer <AiFillDelete size={18} />
                      </button>
                    } 
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive &&
           <TherapeuticAreaModale
            therapeuticAreaOptions={therapeuticAreaOptions}
            therapeuticArea={therapeuticArea}
            setModaleIsActive={setModaleIsActive}
            submit={submitTherapeuticArea}
            />
        }
      </Modale>
    </>
  )
}
