import React from 'react';
import { MdUpdate } from 'react-icons/md';
import styles from './ChangesModale.module.scss';

export default function ChangesModale({ isPreview, changes }) {
  const sortChanges = changes ? [...changes].reverse() : [];
  console.log(sortChanges)
  return (
    <div className={`modale-content ${styles.container} ${isPreview ? styles.preview : ''}`}>
      <h2>Modifictions</h2>
      <div className={styles.changes}>
        {sortChanges?.map((c,i) => <div key={`changes-${i}`}
          className={styles.change}
        >

          <MdUpdate />
          <p>{c}</p>
        </div>)}
      </div>
    </div>
  )
}
