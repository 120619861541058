import React , { useEffect , useState , useCallback } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';

import { getOptionsListsAction , getStudiesListsAction } from '../../../actions/studiesActions';

import Modale from '../../partials/Modale/Modale';
import CreateModale from './partials/CreateModale/CreateModale';
import StudyRow from './partials/StudyRow/StudyRow';
import SortButton from '../../partials/SortButton/SortButton';

import styles from './Studies.module.scss';
import Panel from '../../partials/Panel/Panel';
import StudyView from './partials/StudyView/StudyView';
import { TOOL_ID } from '../../../actions';

export default function StudiesPages({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();

  const { filteredStudies , study } = context.studies;
  const { isDoctor  , userAuth } = context.auth;
  const isAdmin = userAuth?.tools.find(p => p.tool === TOOL_ID && p.role === "Admin") ? true : false ;
  const isCro = userAuth?.division.find(d => d === "CRO") ? true : false ;

  const [createIsOpen , setCreateIsOpen ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(false);

  const [ filtredList , setFiltredList ] = useState();

  const initSortState = {
    name: true,
    therapeuticArea: true,
    phase: true,
    updatedAt: true,
    patients: true,
  }
  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const initData = useCallback(() => {
    setActivePage('studies');
    getStudiesListsAction(dispatch);
    getOptionsListsAction(dispatch);
    dispatch({
      type : 'GET_STUDY',
      payload : null
    });
  }, [setActivePage ,  dispatch ]);

  useEffect(() => {
    if (filteredStudies) {
      let newList = [...filteredStudies]
      newList.forEach( study => {
        const planningStep = study.steps?.find(step => new Date(step.startDate) <= new Date() && new Date(step.endDate) >= new Date()) ;
        const stepColor = planningStep && context.studies?.optionsLists?.planning?.find(step => step.value === planningStep?.name )?.color;
        let foundLabel = context.studies?.optionsLists?.planning?.find(step => step.value === planningStep?.name )?.label
        study.activeStep = {
          label: foundLabel ? foundLabel : "",
          color: stepColor ? stepColor : "transparent"
        }
      })
      setFiltredList(newList)
    }
  },[filteredStudies , context.studies?.optionsLists]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (study) {
      setPanelIsOpen(true);
    } else {
      setPanelIsOpen(false);
    }
  },[study]);

  function sortBy(type , isReverse ) {
    let list = filtredList;
    switch (type) {

      case 'name':
        list = list.sort((a , b ) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , name: isReverse })
        break;

      case 'therapeuticArea':
        list = list.sort((a , b ) => {
          if (a.therapeuticArea.length < b.therapeuticArea.length) return -1;
          if (a.therapeuticArea.length > b.therapeuticArea.length) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , therapeuticArea: isReverse })
        break;

      case 'phase':
        list = list.sort((a , b ) => {
          if (a.activeStep.label < b.activeStep.label) return -1;
          if (a.activeStep.label > b.activeStep.label) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , phase: isReverse })
        break;

      case 'updatedAt':
        list = list.sort((a , b ) => {
          let dateA = new Date(a.updatedAt);
          let dateB =  new Date(b.updatedAt);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , updatedAt: isReverse })
        break;
        
      case 'patients':
        list = list.sort((a , b ) => {
          if (a.patients.length < b.patients.length) return -1;
          if (a.patients.length > b.patients.length) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , patients: isReverse })
        break;
      
      default:
        break;
    }
  }

  function closePanel() {
    dispatch({
      type : 'GET_STUDY',
      payload : null
    });
  }

  return (
    <>
      <div className={`page-container ${styles.studies}`}>
        <div className={`content ${styles.filters}`}>
          {!isDoctor &&
            <button
              onClick={() => setCreateIsOpen(true)}
              className={styles['btn-create']}>
              Créer une étude
            </button>
          }
          <div className={`${styles.row} ${styles.sort}`} >
            <SortButton name={"Nom de l’étude"} action={() => sortBy('name' , !sortByReverse.name )} state={sortByReverse.name} className={`${styles.col} ${styles.name}`}/>
            <SortButton name={"Aires thérapeutiques"} action={() => sortBy('therapeuticArea' , !sortByReverse.therapeuticArea )} state={sortByReverse.therapeuticArea} className={styles.col}/>
            <SortButton name={"Dernière modification"} action={() => sortBy('updatedAt' , !sortByReverse.updatedAt )} state={sortByReverse.updatedAt} className={`${styles.col} ${styles.date}`}/>
            <SortButton name={"Recrutement"} action={() => sortBy('patients' , !sortByReverse.patients )} state={sortByReverse.patients} className={styles.col}/>
            <SortButton name={"Étape en cours"} action={() => sortBy('phase' , !sortByReverse.phase )} state={sortByReverse.phase} className={`${styles.col} ${styles.status}`}/>
          </div>
        </div>

        <div className={`${styles.list} ${isDoctor ? styles['is-doctor'] : ""}`}>
          <div className="content">
            {filtredList && filtredList.map((item) => <StudyRow key={item._id} study={item} activeStudy={study} styles={styles} />)}
          </div>
        </div>

        <Modale
          isOpen={createIsOpen}
          modaleToggle={setCreateIsOpen}
          >
          {createIsOpen && <CreateModale close={() => setCreateIsOpen(false)}/>}
        </Modale>

      </div>
      <Panel
        isOpen={panelIsOpen}
        setIsOpen={closePanel}
        closeClassName={styles['close-panel-button']}
        >
        {study && <StudyView isDoctor={isDoctor} isAdmin={isAdmin} study={study} isCro={isCro}/>}
      </Panel>
    </>
  )
}
