import React, { useState } from 'react';
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

export default function FilterList({ name , list , activeList , setActiveList , isSearch = false }) {

  const [listIsOpen, setListIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  function handleChangeActiveList(id) {
    setSearch('');
    let newList = [...activeList];
    if (activeList.find(d => d === id )) {
      newList = activeList.filter(item => item !== id);
    } else {
      newList.push(id)
    }
    setActiveList(newList);
  }

  return (
    <>
      <p>{name} {activeList.length > 0 && <span>({activeList.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={"#FFFFFF"}/> : <BsPlus size={20} color={"#FFFFFF"}/>}
      </button>
      {listIsOpen &&
        <div className="list">
        {isSearch &&
          <div className="search">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        }
        {list.filter((item) => item.label?.toLowerCase().includes(search.toLowerCase())).map((item , i) => {
          let isActive = activeList.find(d => d === item.value );
          return (
            <div key={i}
              onClick={() => handleChangeActiveList(item.value)}
              className={`item ${isActive ? "isActive" : ""}`}>
              <div className="option">
                {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
                <p>{item.label}</p> 
                <div className="color-circle" style={{ backgroundColor: item.color }}></div>
              </div>
            </div>
          );
        })}
      </div>
      }
    </>
  )
}
