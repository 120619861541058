import {
  ERROR_CENTERS,
  MESSAGE_CENTERS,
  GET_OPTIONS_LIST,
  POST_CENTER ,
  GET_CENTERS_LIST,
  GET_CENTER,
  PUT_CENTER,
  DELETE_CENTER,
  FILTERED_CENTERS,
} from "../actions/types";

const initialState = {
  centersList: [],
  filteredCenters: [],
  error: null,
  message: null,
  center: null
};

export default function centersReducer(state = initialState, action) {
  var list = [...state.centersList];
  switch (action.type) {

    case FILTERED_CENTERS: 
      return {...state , filteredCenters: action.payload}
    case DELETE_CENTER :
      return {...state ,
        message: action.payload.message,
        centersList: list.filter(s => s._id !== action.payload._id)
      }
    case PUT_CENTER: 
      let index = list.findIndex(center => center._id === action.payload.center._id );
      list[index] = action.payload.center;
      return {...state , centersList: list , center: action.payload.center , message: action.payload.message }

    case POST_CENTER:
      list.unshift(action.payload.center);
      return {...state , centersList: list , center: action.payload.center }

    case GET_CENTER:
      return {...state , center: action.payload}
      
    case GET_CENTERS_LIST:
      return {...state , centersList: action.payload}

    case GET_OPTIONS_LIST:
      return {...state , optionsLists: action.payload}

    case ERROR_CENTERS:
      return {...state , error: action.payload}

    case MESSAGE_CENTERS:
      return {...state , message: action.payload}
    default:
      return {...state};
  }
}