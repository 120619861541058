import React , { useState, useRef } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';

import Button from '../../../../partials/Button/Button';
import CouncilModale from './CouncilModale/CouncilModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Council.module.scss';

export default function Council({ study , id , setActiveSection , isPreview }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { scientificCouncil } = study;
  const doctorList = context.studies?.optionsLists?.investigators ;

  const contentRef = useRef(null);

  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);

  function submitsCouncil(council) {
    putStudyAction(dispatch , study._id , { scientificCouncil: council } );
  }

  function deleteCros(id) {
    let deleteList = scientificCouncil.filter(d => d._id !== id);
    submitsCouncil(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Conseil scientifique <span>({scientificCouncil.length})</span></h3>
            {scientificCouncil.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un médecin
            </Button>
          }
          </div>
        </div>

        {scientificCouncil.length > 0 && 
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.centers}>

              <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Nom du médecin
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
                  Etablissement
                </div>
                <div className={`${styles.col} ${styles.label} text-grey`}>
        
                </div>
              </div>

              {scientificCouncil && scientificCouncil.map((c, i) => 
                <div key={`c${i}`} className={styles.row}>
                  <div className={`${styles.col} `}>
                    <span className="bold">
                      {c?.doctor?.profile?.firstName} {c?.doctor?.profile?.lastName}
                    </span>             
                  </div>
                  <div className={`${styles.col} text-grey`}>
                    {c?.doctor?.profile?.structure} 
                  </div>
                  <div className={`${styles.col} ${styles.actions} text-grey`}>
                    {!isPreview &&
                      <button
                        onClick={() => deleteCros(c._id)}  
                        className={styles.delete}
                        >
                        supprimer <AiFillDelete size={18} />
                      </button>
                    } 
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive &&
           <CouncilModale
            doctorsOptions={doctorList}
            doctors={scientificCouncil}
            setModaleIsActive={setModaleIsActive}
            submitCros={submitsCouncil}
            />
        }
      </Modale>
    </>
  )
}
