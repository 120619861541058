import React , { useState, useRef, useEffect, useCallback } from 'react';
import { RiArrowUpSLine , RiArrowDownSLine } from "react-icons/ri";
import { AiFillDelete } from 'react-icons/ai';

import { useGlobalContext } from '../../../../../context/GlobalContext';

import { putStudyAction } from '../../../../../actions/studiesActions';
import { getDrugsAction } from '../../../../../actions/drugsActions';

import Button from '../../../../partials/Button/Button';
import DrugModale from './DrugModale/DrugModale';
import Modale from '../../../../partials/Modale/Modale';

import styles from './Drugs.module.scss';

export default function Drugs({ study , id , setActiveSection , isPreview }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { drugs , therapeuticArea } = study;
  const drugsOptions = context.drugs?.drugsList
  const contentRef = useRef(null);
 
  const [ drugsByAera , setDrugsByArea ] = useState();
  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ panelIsOpen , setPanelIsOpen ] = useState(isPreview? false : true);

  const getDrugs = useCallback(() => {
    getDrugsAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getDrugs();
  },[getDrugs]);

  useEffect(() => {
    if (!drugs && !therapeuticArea) return;
    let arealist = [];
    therapeuticArea.forEach(t => {
      let drugsFound = drugs.filter(d => d.therapeuticArea === t._id);
      if (drugsFound) {
        arealist.push({ name: t.name , drugs: drugsFound})
      }
    });

    const others = drugs.filter((d) => !therapeuticArea.find((t) => t._id === d.therapeuticArea));
    if (others) {
      arealist.push({ name: 'Autres' , drugs: others });
    }
    
    setDrugsByArea(arealist);

  }, [drugs , therapeuticArea]);

  function submitDrugs(values) {
    putStudyAction(dispatch , study._id , { drugs: values } );
  }

  function deleteDrug(id) {
    let deleteList = drugs.filter(d => d._id !== id);
    submitDrugs(deleteList);
  }
  
  return (
    <>
      <section id={id} onMouseOver={() => isPreview ? null : setActiveSection(true , id ) } className={`edit-study-section ${styles.container}`}>
      
        <div className="header">
          <div className="title">
            <h3>Produits <span>({drugs.length})</span></h3>
            {drugs.length > 0 && 
              <button
                onClick={() => setPanelIsOpen(!panelIsOpen)}
                className="panel-toggle">
                { panelIsOpen ?  <RiArrowUpSLine size={28} color={"#192243"}/> : <RiArrowDownSLine size={28} color={"#192243"}/>}
              </button>
            }
          </div>
          <div>
          {!isPreview && 
            <Button
              primary
              onClick={() => setModaleIsActive(true)}
              >
              Ajouter un produit
            </Button>
          }
          </div>
        </div>

        {drugsByAera?.length > 0 && 
          <div className="panel" style={{ height: panelIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
            <div ref={contentRef} className={styles.centers}>
              {drugsByAera.map(( area , i) => 
                <div key={`area-${i}`}>
                <div className={`${styles.row}`}>
                  <div className={`${styles.col} ${styles.label} bold text-grey`}>
                   {area.name}
                  </div>
                  <div className={`${styles.col} ${styles.label} text-grey`}>
                  </div>
                </div>

                {area.drugs.map((d, i) => 
                  <div key={`c${i}`} className={styles.row}>
                    <div className={`${styles.col} `}>
                      <span className="bold">
                        {d?.drug.name}
                      </span>             
                    </div>
                    <div className={`${styles.col} ${styles.actions} text-grey`}>
                      {!isPreview &&
                        <button
                          onClick={() => deleteDrug(d._id)}  
                          className={styles.delete}
                          >
                          supprimer <AiFillDelete size={18} />
                        </button>
                      } 
                    </div>
                  </div>
                )}
                </div>
              )}
            </div>
          </div>
        }
      </section>
      <Modale
        isOpen={modaleIsActive}
        modaleToggle={setModaleIsActive}
        >
        {modaleIsActive &&
           <DrugModale
            drugsOptions={drugsOptions}
            drugs={drugs}
            therapeuticAreaOptions={therapeuticArea.map(a =>({ value: a._id , label: a.name }))}
            setModaleIsActive={setModaleIsActive}
            submit={submitDrugs}
            />
        }
      </Modale>
    </>
  )
}
